import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-move-to-send',
  templateUrl: './move-to-send.component.html',
  styleUrls: ['./move-to-send.component.scss']
})
export class MoveToSendComponent implements OnInit {

  listToMove = [];
  @Input() data: any;
  text: {title: string; singularText: string; pluralText: string} =
      {title: 'Подпись/перемещение документов', singularText: 'подписан/перемещен', pluralText: 'подписаны/перемещены'};
  flatDataWithId = [];

  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    for (const [key, value] of Object.entries(this.data.response)) {
      this.flatDataWithId.push(Object.assign({id: key}, value));
    }
  }

  getFlkErrorsData() {
    return this.flatDataWithId.filter(item => (item as any)?.message === 'Файл не прошел ФЛК');
  }

  getDateErrorsData() {
    return this.flatDataWithId.filter(item => (item as any)?.message === 'Документ не может быть перемещен, так как Дата сообщения не равна текущей дате');
  }

  getSuccessData() {
    return this.flatDataWithId.filter(item => (item as any)?.message === 'success');
  }

  getRestData() {
    const errorsMsgs = ['success', 'Документ не может быть перемещен, так как Дата сообщения не равна текущей дате', 'Файл не прошел ФЛК'];
    return this.flatDataWithId.filter(item => !errorsMsgs.includes((item as any)?.message));
  }

  getDocName(id: string) {
    return this.listToMove.find(item => item.id === id).documentType.name ?? this.listToMove.find(item => item.id === id).documentType;
  }

  getDocDate(id: string) {
    return this.listToMove.find(item => item.id === id).messageDate;
  }

  open(param: any) {
    this.listToMove = param.item;
    if (param?.text && Object.keys(param.text).length) {
      this.text = param.text;
    }
  }

  showDateErrorMessage(errorText) {
    const errors = ['Документ не может быть перемещен, так как Дата сообщения не равна текущей дате', 'Файл не прошел ФЛК']
    return errors.includes(errorText);
  }

}
