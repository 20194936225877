import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {ITableItem} from "@amlCore/components";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {DocumentTypeEnum} from "../../../../../../../../documentForm";

@Component({
  selector: 'app-view-sved-oper-v1',
  templateUrl: './view-sved-oper-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewSvedOperV1Component extends DocumentComponentBaseSub implements ITableItem, OnInit, OnDestroy {
  formGroupApp: FormGroup;
  isNeedStranaNaimenovanie = true;
  documentData: any;
  showInfoOper = true;
  showTransfer = false;
  titlePanel = `Сведения об операции`;
  icons = {
    info: ['fas', 'info-circle']
  };

  private destroy$ = new Subject();

  constructor(readonly docSrv: DocumentFmV1Service) {
    super();
  }

  ngOnInit(): void {
    this.field('naznacheniePlatezha').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!value && value !== '') {
        this.field('naznacheniePlatezha').setValue('');
      }
    });
    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(this.documentType)) {
      this.isNeedStranaNaimenovanie = false;
    }
  }

  currentGetForm = (data) => this.docSrv.getOperatsiiaFmV1(data, null, this.documentType);

  isValidItem(): boolean {
    return this.isFormGroupValidExcludingControls(['uchastnikOp', 'osnovanieOp']);
  }

  genIdentify(data) {
    this.field('nomerZapisi').setValue(data);
    this.field('nomerZapisi').markAsDirty();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  get isOperation1_2() {
    return this.documentType === DocumentTypeEnum.OPERATIONS_1_2;
  }
}
