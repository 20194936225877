import { Component, Input, OnInit } from "@angular/core";
import {FormGroup, Validators} from "@angular/forms";
import { ITableItem } from "@amlCore/components";
import * as selectItems from "../../../../control6670/json/selectItems.json";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentTypeEnum} from "../../../enums";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
    templateUrl: "./view-sved-tsp.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewSvedTspComponent extends DocumentComponentBaseSub implements OnInit, ITableItem {
    @Input() documentType = DocumentTypeEnum.SD_KONFO_ECONOMIC_MEASURE;
    formGroupApp: FormGroup;
    documentData: any;
    titlePanel = "Сведения о цифровых правах, в отношении которых применены меры по замораживанию (блокированию)";
    selectVidTsP = selectItems.vidTsPItems;
    DocumentTypeEnum = DocumentTypeEnum;
    constructor(
        private readonly docSrv: DocumentBaseService
    ) {
        super();
    }

    ngOnInit(): void {
    }

    isValidItem(): boolean {
        return this.formGroupApp.valid;
    }

    currentGetForm = (param) => this.docSrv.getSvedTsP(param);
}
