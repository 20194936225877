import { Component, OnDestroy } from "@angular/core";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../../../services";
import { IconsService } from '@amlCore/services';
import { FormArray, FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {CorrectReplaceMessageComponent} from "../correct-replace-message";

@Component({
  selector: 'app-correct-replace-remove-modal',
  templateUrl: 'correctReplaceRemove.component.html',
  styleUrls: ['correctReplaceRemove.component.scss']
})

export class CreateCorrectReplaceRemoveDocumentsComponent implements OnDestroy {
  constructor(private commonService: CommonService,
              private iconsService: IconsService,
              private activeModal: NgbActiveModal,
              private fb: FormBuilder,
              protected modalDialog: NgbModal,
              ) {}

  selectedList: Array<any>;
  group: string;
  icons = this.iconsService.getIcons();
  formArray: FormArray;
  isLoading = false;
  destroy$ = new Subject();
  records = [];
  isServerError = false;
  successResult = false;
  disableButton = false;

  checkedArr = [];

  correctionChecked = [];
  replacementChecked = [];
  removementChecked = [];
  nulledId = [];

  protected readonly _MODAL_CONFIG = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  open(param: any) {
    this.selectedList = param.list;
    this.group = param.group;
    this.formArray = this.fb.array([]);
    if (this.selectedList.length) {
      this.selectedList.forEach((item) => {
        this.formArray.push(this.fb.group({
          id: item.id,
          documentName: item.documentName,
          documentStatus: item.documentStatus.name,
          fixResult: null,
          records: this.getRecords(item),
        }));
      });
    }
  }

  /**
   * Получаем записи по документу
   */
  getRecords(item): FormArray {
    this.isLoading = true;
    const arr = this.fb.array([]);
    let obj = this.fb.group({});
    this.commonService.getRecordsByDocId(item.id, this.group).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.records.push(data);
      for (const [key, value] of Object.entries(data.recordsMap)) {
        obj = this.fb.group({
          newStatus: null,
          recordKey: key,
          status: value['acceptedState'] === undefined ? {id: 0, name : 'Статус отсутствует'} : value['acceptedState'],
        });
        arr.push(obj);
      }
      this.isLoading = false;
    },
    (err) => {
      obj = this.fb.group({
        error: err.error
      });
      arr.push(obj);
      this.isLoading = false;
    });
    return arr;
  }

  onClose(): void {
    this.activeModal.close();
  }

  /**
   * Операции замены\испр\удаления
   */
  sendFix() {
    const obj = {};
    this.getFormArray.value.filter(item => item.records[0]?.error === undefined).forEach((item) => {
      const filteredRecords = item.records.filter((item2) => item2.newStatus !== null);
      const recordMap = {};
      filteredRecords.forEach(element => {
        recordMap[element.recordKey] = element.newStatus;
        obj[item.id] = recordMap;
      });
    });
    this.isLoading = true;
    const recordsUpdateMap = {};
    recordsUpdateMap['recordsUpdateMap'] = obj;

    // добавляем docId:null для записей с needRecordsSelect:false
    this.nulledId.forEach((item) => {
      obj[item] = null;
    });
    this.changeRecords(recordsUpdateMap);
  }

  changeRecords(recordsUpdateMap) {
    this.commonService.changeRecords(recordsUpdateMap, this.group).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.isLoading = false;
      this.getFormArray.controls.forEach((elem, i) => {
        // проверка успешных id
        for (const entry of Object.entries(response.success)) {
          if (elem.value.id === entry[0]) {
            elem.patchValue({fixResult: {success: true, new_id: entry[1]}});
          }
        }
        // проверка ошибочных id
        for (const entry of Object.entries(response.errors)) {
          if (elem.value.id === entry[0]) {
            elem.patchValue({fixResult: {success: false, error: entry[1]}});
          }
        }
      });
      if (Object.entries(response.success).length || Object.entries(response.errors).length) {
        this.activeModal.close();
        const modalRef = this.modalDialog.open(CorrectReplaceMessageComponent, this._MODAL_CONFIG);
        modalRef.componentInstance.open({
          docResult: this.getFormArray?.value,
          groupName: this.group,
          success: !Object.entries(response.errors).length ? true : false
        });
      }
      this.resetRadioButtonStatus();
      this.successResult = true;
    },
    (err) => {
      console.log(err);
      this.isServerError = true;
      this.isLoading = false;
      this.resetRadioButtonStatus();
    });
  }

  /**
   * Для выделения всех чекбоксов в документах со статусом "Отвергнут"
   * @param event получение checked
   * @param index индекс для работы с определенной группой чекбоксов
   */
  selectAll(event, index) {
    const recordsFormArray = this.formArray.at(index).get('records') as FormArray;
    recordsFormArray.controls.forEach(control => {
      control.get('newStatus').setValue(event.target.checked ? 2 : null)
      control.updateValueAndValidity();
    })

    // проверка для включения или отключения кнопки "Ок"
    // громозодко, но работает
    if(!event.target.checked) {
      const result = this.formArray.value?.filter(element => {
        return element?.records.some(record => record.newStatus !== null)
      })
      result?.length === 0 ? this.disableButton = true : this.disableButton = false;
    } else {
      this.disableButton = false;
    };

    this.formArray.markAsDirty();
  }

  get getFormArray(): FormArray {
    return this.formArray as FormArray;
  }

  isCorrectStatusDoc(records) {
    return records[0]?.error === undefined;
  }
  resetRadioButtonStatus() {
    this.getFormArray?.controls?.forEach((controlGroup: FormGroup) => {
      const records = controlGroup?.get('records') as FormArray
      records?.controls?.forEach((recordsGroup: FormGroup) => {
        const newStatus = recordsGroup?.get('newStatus');
        newStatus?.setValue(null);
      });
    });
  }
  /**
   * Логика общих чекбоксов
   * @param event
   * @param index
   */
  clickCheckbox(event, index?) {
    this.isAllChecked(event, index);
    (this.getFormArray?.controls[index].get('records') as FormArray).controls.forEach((control, j) => {
      if (event.target.checked) {
        let checkParam;
        if (event.target.value === 'correction') {
          checkParam = 2;
          if (this.checkedArr.indexOf('correction' + index + j) === -1) {
            this.checkedArr.push('correction' + index + j);
          }
          if (this.checkedArr.indexOf('replacement' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('replacement' + index + j), 1);
          }
          if (this.checkedArr.indexOf('removement' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('removement' + index + j), 1);
          }
        }
        if (event.target.value === 'replacement') {
          checkParam = 3;
          if (this.checkedArr.indexOf('replacement' + index + j) === -1) {
            this.checkedArr.push('replacement' + index + j);
          }
          if (this.checkedArr.indexOf('correction' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('correction' + index + j), 1);
          }
          if (this.checkedArr.indexOf('removement' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('removement' + index + j), 1);
          }
        }
        if (event.target.value === 'removement') {
          checkParam = 4;
          if (this.checkedArr.indexOf('removement' + index + j) === -1) {
            this.checkedArr.push('removement' + index + j);
          }
          if (this.checkedArr.indexOf('correction' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('correction' + index + j), 1);
          }
          if (this.checkedArr.indexOf('replacement' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('replacement' + index + j), 1);
          }
        }
        control.get('newStatus').setValue(event.target.checked ? checkParam : null)
      } else {
        if (event.target.value === 'correction') {
          if (this.checkedArr.indexOf('correction' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('correction' + index + j), 1);
            control.get('newStatus').setValue(null);
          }
        }
        if (event.target.value === 'replacement') {
          if (this.checkedArr.indexOf('replacement' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('replacement' + index + j), 1);
            control.get('newStatus').setValue(null);
          }
        }
        if (event.target.value === 'removement') {
          if (this.checkedArr.indexOf('removement' + index + j) !== -1) {
            this.checkedArr.splice(this.checkedArr.indexOf('removement' + index + j), 1);
            control.get('newStatus').setValue(null);
          }
        }

      }
      control.markAsDirty();
    });
  }

  clickChecked(event, i, j) {
    if (event.target.checked === true && !this.checkedArr.includes(event.target.id)) {
      if (this.checkedArr.indexOf('correction' + i + j) !== -1)
        {this.checkedArr.splice(this.checkedArr.indexOf('correction' + i + j), 1); }
      if (this.checkedArr.indexOf('replacement' + i + j) !== -1)
        { this.checkedArr.splice(this.checkedArr.indexOf('replacement' + i + j), 1); }
      if (this.checkedArr.indexOf('removement' + i + j) !== -1)
        { this.checkedArr.splice(this.checkedArr.indexOf('removement' + i + j), 1); }
      this.checkedArr.push(event.target.id);
    }
    else {
      (this.getFormArray?.controls[i].get('records') as FormArray).controls.forEach((control, index) => {
        if (index === j && control.get('newStatus') !== null) {
          control.get('newStatus').patchValue(null);
          control.markAsDirty();
        }
      });
      this.checkedArr.splice(this.checkedArr.indexOf(event.target.id), 1);
    }
    this.correctionChecked[i] = false;
    this.replacementChecked[i] = false;
    this.removementChecked[i] = false;
    this.checkedArr.forEach(item => {
      if (item.indexOf('correction') !== -1) {
        this.correctionChecked[i] = true;
      }
      if (item.indexOf('replacement') !== -1) {
        this.replacementChecked[i] = true;
      }
      if (item.indexOf('removement') !== -1) {
        this.removementChecked[i] = true;
      }
    });
  }

  /**
   * Логика переключения общих чекбоксов
   */
  isAllChecked(event, index) {
    if (event.target.value === 'replacement' && event.target.checked) {
      this.replacementChecked[index] = event.target.checked;
      this.correctionChecked[index] = false;
      this.removementChecked[index] = false;
    }

    if (event.target.value === 'correction' && event.target.checked) {
      this.correctionChecked[index] = event.target.checked;
      this.replacementChecked[index] = false;
      this.removementChecked[index] = false;
    }

    if (event.target.value === 'removement' && event.target.checked) {
      this.removementChecked[index] = event.target.checked;
      this.correctionChecked[index] = false;
      this.replacementChecked[index] = false;
    }
  }

  isChecked(id) {
    return this.checkedArr.includes(id);
  }

  checkedForNullRecords(event, docId) {
    if (event.target.checked) {
      this.nulledId.indexOf(docId) === -1 ? this.nulledId.push(docId) : null;
    } else {
      this.nulledId.indexOf(docId) !== -1 ? this.nulledId.splice(this.nulledId.indexOf(docId), 1) : null;
    }
    this.getFormArray.markAsDirty();
  }
}
