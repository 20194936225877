import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {distinctUntilChanged, filter, map, switchMap, takeUntil, tap} from "rxjs/operators";
import * as selectItems from "../../../../../../json/selectItems.json";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentTypeEnum, FM_DOCS_1_X, REFUSE_CONTRACT_1_X, REFUSE_OPERATION_1_X, OPERATIONS_1_X} from '../../../enums';
import {DictionariesService} from "../../../../../dictionaries";

@Component({
    selector: 'app-arm-doc-view-info-fl',
    templateUrl: './view-info-fl.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewInfoFlComponent extends DocumentComponentBaseSub implements OnInit, OnChanges {
    @Input() readonly formGroupApp: FormGroup;
    @Input() readonly documentData: any;
    @Input() type : string;
    @Input() clientType: number;
    @Input() tipEIO: number;
    @Input() readonly documentType: DocumentTypeEnum;
    @Input() withFioStroka = true;
    @Input() isVersiiaFormata1x = false;
    @Input() isBirthPlace = false;
    // clientType либо прилетает через Input либо можем менять внутри компонента
    clientTypeControl = new FormControl(1);
    clientSelectItems = selectItems.clientInfo;
    kodOKSMValidators = [];
    secondKodOKSMValidators = [Validators.required, Validators.maxLength(3)];
    getDictionary: any = this.dictSrv.getQrefOkcmList().pipe(map(page => page.content));

    constructor(private dictSrv: DictionariesService) {
      super();
    }

  ngOnInit() {
    if (this.tipEIO) {
      this.handleEioChastnaiaPraktika(this.tipEIO);
      this.formGroupApp.parent?.get('tipEIO')?.valueChanges?.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
          this.handleEioChastnaiaPraktika(value);
        });
    }
    if (this.isVersiiaFormata1x) {
      this.getDictionary?.pipe(
        switchMap((dictionaries: any) => (this.field('kodOKSM') ?? this.field('kodOKSM'))?.valueChanges.pipe(
          map(dictionariesCode => dictionaries.find(dictionary => dictionary?.kokcm3 === dictionariesCode)),
          filter(currentDictionary => Boolean(currentDictionary?.ncm)),
          tap(currentDictionary => {
            (this.field('stranaNaimenovanie') ?? this.field('stranaNaimenovanie')).patchValue(currentDictionary?.ncm.slice(0, 251))
          }),
          takeUntil(this.componentDestroyed$)
        )),
      ).subscribe();
    }

    if (this.clientType) {

      this.updateClientType(this.clientType);

      setTimeout(() => this.handleTipFLChastnaiaPraktika(this.clientType))

      this.clientTypeControl.valueChanges
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((value) => {
          this.formGroupApp.markAsDirty();
          this.handleTipFLChastnaiaPraktika(value);
        });
      }

      // disable control на уровень выше
      this.formGroupApp.statusChanges
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(status => {
          const identifikatsiiaFL = this.identifikatsiiaFL;
          if (status === 'DISABLED') {
            if (identifikatsiiaFL?.enabled)
              identifikatsiiaFL.disable({ onlySelf: true, emitEvent: false });
          } else if (identifikatsiiaFL?.disabled)
              identifikatsiiaFL.enable({ onlySelf: true, emitEvent: false });
        });

      if (this.type === 'FLIP') {

        this.handleFieldsValidation(this.priznKlient == 1);

        this.formGroupApp.parent?.parent?.get('tipUchastnika')?.valueChanges?.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
          if (value == 2 || value == 3 || value == 4) {
            this.handleFieldsValidation(this.priznKlient == 1);
          }
        })

        this.formGroupApp.parent?.parent?.get('priznKlient')?.valueChanges?.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => this.handleFieldsValidation(value == 1));

        // Предписанные поля

        this.field('innflip')?.valueChanges?.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(value => {
          if (!value && value !== '') {
            this.field('innflip')?.setValue('');
          }
        });

        if (this.documentType === 'SUSPICIOUS_ACTIVITY' || this.documentType === 'REFUSE_OPERATION') {

          this.field('svedDokUdLichn')?.valueChanges?.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {

            if (!value.nomDok && value.nomDok !== '') {
              this.field('svedDokUdLichn.nomDok')?.setValue('');
            }
            if (!value.seriiaDok && value.seriiaDok !== '') {
              this.field('svedDokUdLichn.seriiaDok')?.setValue('');
            }
          });

          this.field('svedDokPreb')?.valueChanges?.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {

            if (!value.nomDokPreb && value.nomDokPreb !== '') {
              this.field('svedDokPreb.nomDokPreb')?.setValue('');
            }
            if (!value.seriiaDokPreb && value.seriiaDokPreb !== '') {
              this.field('svedDokPreb.seriiaDokPreb')?.setValue('');
            }
          });
        }

      }
  }

  handleTipFLChastnaiaPraktika(value) {
    if (value == 4) {
      this.isReadOnly ? this.field('tipFLChastnaiaPraktika')?.disable() : this.field('tipFLChastnaiaPraktika')?.enable();
    } else {
      this.field('tipFLChastnaiaPraktika')?.disable();
    }
  }

  handleEioChastnaiaPraktika(value) {
    if (value == 2) {
      this.field('tipFLChastnaiaPraktika')?.enable();
      this.field('tipFLChastnaiaPraktika')?.clearValidators();
      this.field('tipFLChastnaiaPraktika')?.setErrors(null);
      this.field('tipFLChastnaiaPraktika')?.setValidators(Validators.max(1));
    } else {
      this.field('tipFLChastnaiaPraktika')?.disable();
      this.field('tipFLChastnaiaPraktika')?.clearValidators();
      this.field('tipFLChastnaiaPraktika')?.setErrors(null);
    }
  }

  handleFieldsValidation(condition) {
      if (!REFUSE_OPERATION_1_X.includes(this.documentType)) {
        if (condition) {
          this.field('kodOKSM')?.setValidators(Validators.required);
          this.field('priznakPubLitso')?.setValidators(Validators.required);
        } else {
          this.field('kodOKSM')?.clearValidators();
          this.field('priznakPubLitso')?.clearValidators();
          this.field('kodOKSM')?.setErrors(null);
          this.field('priznakPubLitso')?.setErrors(null);
          this.field('kodOKSM')?.setValidators(Validators.maxLength(3));
          this.field('priznakPubLitso')?.setValidators(Validators.maxLength(1));
        }
        this.field('kodOKSM')?.updateValueAndValidity();
        this.field('priznakPubLitso')?.updateValueAndValidity();
      }
  }

    ngOnChanges(change: SimpleChanges) {
      const clientType = change?.clientType?.currentValue;
      if (clientType)
        this.updateClientType(clientType);
    }

    get priznKlient() {
      return this.formGroupApp.parent?.parent?.get('priznKlient')?.value;
    }

    get priznUchastnika() {
      return this.formGroupApp.parent?.parent?.get('priznUchastnika')?.value;
    }

    get identifikatsiiaFL(): FormControl {
      return this.formGroupApp.parent.get('identifikatsiiaFL') as FormControl;
    }

    get isRefuseContract1x() {
      return REFUSE_CONTRACT_1_X.includes(this.documentType);
    }

    get isRefuseOperation1x() {
      return REFUSE_OPERATION_1_X.includes(this.documentType);
    }

    get isOperation1x() {
      return OPERATIONS_1_X.includes(this.documentType);
    }

    private updateClientType(type: number) {
      this.clientTypeControl.patchValue(type);
      if (this.isReadOnly)
        this.clientTypeControl.disable({ onlySelf: true });
    }
}
