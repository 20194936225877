import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ImportDoc} from "../../model/ImportDocsResponseModel";
import {IconsService} from "@amlCore/services";
import {getServiceName} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";
import {HttpParams} from '@angular/common/http';
import {docModule, InputFileComponent, MAX_FILE_SIZE_128MB} from "../../../../core";
import moment from "moment";
import {of} from "rxjs";
import {catchError, tap} from "rxjs/operators";

/**
 * Новый компонент импорта
 * ИСПОЛЬЗОВАНИЕ:
 * При вызове модального окна, необходимо передать docModule
 * Пример: componentInstance.docModuleImport = 'control6670';
 * ВАЖНО:
 * Данный компонент не умеет работать с импортом 407 вложений. Доработать это в соответствующих задачах !
 */
@Component({
    selector: 'app-import-document-v2',
    templateUrl: './importDocumentsV2.component.html',
    styleUrls: ['./importDocumentsV2.component.scss']
})
export class ImportDocumentsV2Component implements OnInit, OnDestroy {
    @Input() url ='/api/v1/documents/import';
    @Input() typeFileToImport = 'ФЭС';
    @Input() nominativeTypeFileToImport = 'ФЭС';
    @Input() group_type?;
    @Input() docModuleImport: docModule = 'fm';
    @Input() maxSizeFile = MAX_FILE_SIZE_128MB;

    @ViewChild('inputFile') inputFile: InputFileComponent;

    loading = false;
    accept = '.xml';
    importedData: [] = [];
    importPercent = 0;
    icons = this._iconsService.getIcons();
    orgInn = sessionStorage.getItem('orgInn');
    overSizeFilesArr: any = [];
    upploadingFiles = false;
    filesCount = 0;
    importFiles: File[] = []; // импортируемые файлы
    needApprovDocs = [];
    needApprovAttachments = [];
    needFillPrescribed = [];
    queryParams: HttpParams = new HttpParams();
    noImportFlag = false;
    loaded = false;
    vidInf407Attachment: string = null;
    isInnInQuerryArray = ['otkazgoz', 'p407', 'strateg'];
    notPassMask: File[] = [];
    textToExport = '';

    importedSuccessfully: string[] = [];
    importedWithErrors: string[] = [];
    notImported: string[] = [];
    @ViewChild('needFillPrescribedModal') fillPrescribedTemplateRef: TemplateRef<any>;

    constructor(private _activeModal: NgbActiveModal,
                private _iconsService: IconsService,
                protected fSaver: FileSaverService,
                private readonly modalService: NgbModal) {
    }

    ngOnInit(): void {
        if (this.group_type && this.isInnInQuerryArray.indexOf(this.group_type) === -1) {
            this.url += `?inn=${this.orgInn}`;
        }
    }

    onClose(): void {
        this._activeModal.close(true);
        this.loading = false;
    }

    startImport(repeatSend: boolean, otherUrl?: string): void {
        const tempObj = {};
        /*
        Создание объекта для определения количества сервисов, в которые надо отправлять документы.
        Результат for of выходит объект, где ключ - название сервиса, а значение строка с индексами, которые
        будут использованы далее. {sd-ko-economic: '1,50,100,'}
         */
        for (const [index, importFile] of this.importFiles.entries()) {
            const serviceName = getServiceName(importFile.name, this.docModuleImport);
            if (!tempObj[serviceName]) {
                tempObj[serviceName] = '' + `${index}`;
            } else {
                tempObj[serviceName] = tempObj[serviceName] + `,${index}`;
            }
        }
        /*
        Формирование массива с объектами, где каждый индекс массива содержит в себе файлы, которые надо отправить на определенный сервис.
        Из прошлого примера serviceName это sd-ko-economic, а stringIndexArr это '1,50,100,'. Далее разделяем строку на массив, фильтруем
        чтобы были только значения и достаём по индексам нужные файлы. Возвращаем объект с урлами и файлами
         */
        const finalArrFiles = Object.entries(tempObj).map(([serviceName, stringIndexArr]: [string, string]) => {
            const filesArr = stringIndexArr.split(',').map((item: string) => {
                return this.importFiles[Number(item)];
            });
            return {
                url: serviceName ? otherUrl ? `${otherUrl.replace(/(documents\/)/, `$1${serviceName}/`)}` :
                    `${this.url.replace(/(documents\/)/, `$1${serviceName}/`)}` : '',
                files: filesArr,
            };
        });
        const notPassedMaskLen = finalArrFiles.filter(item => !item.url).length;
        const needCountImports = finalArrFiles.length - notPassedMaskLen;
        let importPassed = 0;
        finalArrFiles.forEach((item, index) => {
            if (item.url) {
                this.inputFile.sendOnlyFormData(item.files, this.overSizeFilesArr, false, item.url)
                    .pipe(tap(() => this.inputFile.loader = true), catchError(err => {
                    importPassed++;
                    this.stopImportAfterAll(importPassed, needCountImports);
                    return of();
                })).subscribe((data) => {
                    this.importedSuccessfully = [...this.importedSuccessfully, ...data.importedSuccessfully];
                    this.importedWithErrors = [...this.importedWithErrors, ...data.importedWithErrors];
                    this.notImported = [...this.notImported, ...data.notImported];
                    if (data?.needFillPrescribed) {
                        this.needFillPrescribed = [...this.needFillPrescribed, ...data?.needFillPrescribed];
                    }
                    importPassed++;
                    this.stopImportAfterAll(importPassed, needCountImports);
                    this.textToExport += data.text;
                });
            } else {
                this.notPassMask = item.files;
                if (!needCountImports) {
                    this.stopImportAfterAll(importPassed, needCountImports);
                }
            }
        });
    }
    /**
     * Обработчик начала импорта
     */
    onSendStart(files: File[]): void {
        this.loaded = false;
        this.loading = true;
        this.filesCount = files.length;
        if (files.length > 0) {
            for (const item of files) {
                this.importFiles.push(item);
            }
        } else {
            //заканчиваем импорт
            setTimeout(() => {
                this.upploadingFiles = false;
                this.loading = false;
            }, 1000);
        }
    }

    /**
     * Обработчик конца импорта
     */
    onFinishImport(event?: []): void {
        if (event) {
            this.importedData.push(...event);
        }
    }

    /**
     * Получаем файлы првышающие размер
     */
    overSizeFiles(files) {
        this.overSizeFilesArr = [];
        this.overSizeFilesArr = files;
    }

    ngOnDestroy(): void {
        this.loading = false;
    }

    /**
     * Экспорт результатов импорта для общего файла или одного
     */
    exportImportResults(fileName?: string): void {
        let textForTxt = this.textToExport;
        if (fileName) {
            const regex = new RegExp(`(${fileName}[\\s\\S]*?)(?=\n\n)`, 'g');
            const matches = this.textToExport.match(regex);
            if (matches[0]) {
                textForTxt = matches[0];
            }
        }
        const blob = new Blob([textForTxt], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.generateFileName(!fileName);
        link.click();
        link.remove();
    }

    /**
     * Генерация имени файла
     * @param isAll - параметр, в зависимости от которого будет выдаваться имя для всех документов или для одного
     */
    generateFileName(isAll: boolean = false ): string {
        const date = moment();
        return isAll ? `all_import_${date.format("YYYYMMDD")}_${date.format("HHmm")}` :
            `document_import_${date.format("YYYYMMDD")}_${date.format("HHmm")}`;
    }

    /**
     * Нажатие "Выбрать файлы для импорта"
     * обнуляем результаты предыдущего импорта
     */
    getFiles() {
        this.overSizeFilesArr = [];
        this.upploadingFiles = true;
        this.importPercent = 0;
        this.filesCount = 0;
        this.importFiles = [];
        this.notPassMask = [];
        this.textToExport = '';
        this.importedWithErrors = [];
        this.notImported = [];
        this.importedSuccessfully = [];
    }

    /**
     * Импортировать с параметром fillPrescribed=true
     */
    onFillPrescribed() {
        this.filterImportFilesPrescribed();
        this.filterNotImported();
        this.filterTextExport();
        this.loading = true;
        this.inputFile.loader = true;
        this.importPercent = 0;
        this.startImport(false, this.url + '?fillPrescribed=true');
        this.needFillPrescribed = [];
    }

    /**
     * Кнопка Нет
     */
    noImport() {
        this.noImportFlag = true;
        this.needFillPrescribed = [];
    }

    private stopImportAfterAll(importPassed: number, needCountImports: number): void {
        const percent = 100;
        if (!!importPassed && !!needCountImports) {
            this.importPercent = Math.floor(importPassed / needCountImports * percent)
        } else {
            this.inputFile.loader = false;
            this.loading = false;
        }
        if (importPassed === needCountImports) {
            setTimeout(() => {
                this.inputFile.loader = false;
                this.loading = false;
                if (this.needFillPrescribed.length) {
                    this.openFillPrescribedModal();
                }
            }, 1000);
        }
    }

    private openFillPrescribedModal() {
        this.modalService.open(this.fillPrescribedTemplateRef, { ariaLabelledBy: 'modal-basic-title' }).result.then(
            () => this.onFillPrescribed(),
            () => this.noImport()
        );
    }
    private filterImportFilesPrescribed() {
        const tempMap = this.getMapFromPrescribed();
        this.importFiles = this.importFiles.filter(importFile => tempMap.get(importFile.name));
    }

    private filterNotImported() {
        const tempMap = this.getMapFromPrescribed();
        this.notImported = this.notImported.filter(fileName => !tempMap.get(fileName));
    }
    private filterTextExport() {
     const tempMap = this.getMapFromPrescribed();
     const arrayFromText = this.textToExport.split('\n\n').filter(fileName => {
         return !tempMap.get(fileName.replace(/\n.*/, ''));
     });
     this.textToExport = arrayFromText.join('\n\n');
    }
    private getMapFromPrescribed() {
        const needFillPrescribedMap = new Map();
        this.needFillPrescribed.forEach(item => {
            needFillPrescribedMap.set(item, item);
        });
        return needFillPrescribedMap;
    }
}
