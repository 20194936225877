import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CommonService} from "@amlDocument/services";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {SignService} from "../../../../core/components/sign/sign.service";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {CryptographService} from "../../../../cryptography";
import {errorCatchCert, iteratorObject} from "@amlCore/utils";
import {UserService} from "@amlCore/services";
import {signItem} from "../../model";

@Component({
  selector: 'app-common-sign-all-docs',
  templateUrl: './common-sign-all-docs.component.html',
  styleUrls: ['./common-sign-all-docs.component.scss']
})
export class CommonSignAllDocsComponent implements OnInit, OnDestroy {
  listSign: signItem[] = [];
  groupType = '';
  titleName = 'ЭДО 5861-У';
  signProgress = false;
  stopSigned = false;
  password = '';
  isSign = false;
  isPasswordError = false;
  @Input() isFormDirty = false;
  icons = {
    spinner: faSpinner
  };
  constructor(public modal: NgbActiveModal,
              private modalService: NgbModal,
              private commonService: CommonService,
              private singService: SignService,
              private cryptographService: CryptographService,
              private userService: UserService) { }
  ngOnInit(): void {
  }
  open(param: any) {
    this.listSign = param.item;
    this.groupType = param.groupType;
    this.titleName = param.titleName;
  }
  isHaveCheckedDocs() {
   return this.listSign.some(item => item.checked);
  }
  signDocument() {

    const signAllowed = (token: string) => {
      this.stopSigned = false;
      const needSign = this.getAllSignDocs();
      if (needSign.length === 0) {
        return;
      }
      const cert = this.cryptographService.crypt.getSelectedCert();
      if (!cert) {
        needSign.forEach(doc => {doc.error = errorCatchCert(null); });
        return;
      }
      const _iterate = iteratorObject(needSign);
      const oid = this.cryptographService.crypt.getAlgorithmCert();
      this.signProgress = true;
      const that = this;
      const step = (iterate) => {
        const next = iterate.next();
        if (!next.done) {
          blockAndSign(iterate, next.object);
        } else {
          that.signProgress = false;
        }
      };
      this.isSign = true;
      const blockAndSign = (iterate, item) => {
        this.commonService.blockDocuments([item.id], this.groupType).pipe(
            catchError(err => {
              item.process = false;
              item.error = err.error;
              item.lock = false;
              step(iterate);
              return of();
            })
        ).subscribe(() => {
          if (this.stopSigned || item.lock) {
            return;
          }
          item.lock = true;
          that.commonService.getHash(item.id, item.type, oid).subscribe((data) => {
            that.cryptographService.crypt.generateSignature(Object.entries(JSON.parse(data))[0][1], cert).then(signature => {
              that.commonService.sendSign(item.id, item.type, signature, token).subscribe((result) => {
                item.signature = data;
                item.success = result;
                item.process = false;

                /**
                * Для проверки Даты сообщения
                */
                const date = new Date(); 
                const itemDate = item.date.split('.').reverse().join('-'); 
                
                const format = (date) => date < 10 ? `0${date}` : date.toString();
                const getDateString = (dateParam) => {
                  const day = dateParam.getDate();
                  const month = dateParam.getMonth() + 1;
                  const year = dateParam.getFullYear();
                  return `${year}-${format(month)}-${format(day)}`;
                };
                
                item.error = item.success === true ? null : Date.parse(itemDate) !== Date.parse(getDateString(date))  ? 'Документ не может быть подписан, так как Дата сообщения не равна текущей дате' : 'Возникла ошибка! Документ не подписан!';
                item.lock = false;
                step(iterate);
              }, error => {
                item.error = error ? error : 'Возникла ошибка';
                item.lock = false;
                item.process = false;
                step(iterate);
              })
              // разблокировка подписываемого документа после успешной отправки на подпись
              that.commonService.unBlockDocuments([item.id], this.groupType).subscribe((result) => {
              });

            }, error => {
              item.error = errorCatchCert(error);
              item.lock = false;
              item.process = false;
              step(iterate);
              // разблокировка подписываемого документа после ошибки получения generateSignature
              that.commonService.unBlockDocuments([item.id], this.groupType).subscribe((result) => {
              });
            });
          }, error => {
            item.error = error;
            item.lock = false;
            item.process = false;
            step(iterate);
            // разблокировка подписываемого документа после ошибки получения хеша
            that.commonService.unBlockDocuments([item.id], this.groupType).subscribe((result) => {
            });
          });
        });
      };

      blockAndSign(_iterate, _iterate.next().object);
    };
    if (this.password) {
      this.userService.getIdentification(this.password).subscribe(data => {
        if (data.success) {
          signAllowed(data.tokenId);
        } else {
          this.isPasswordError = true;
        }
      });
    } else {
      this.isPasswordError = true;
    }
  }
  getAllSignDocs(): signItem[] {
    return this.listSign.filter(i => i.process && !i.lock);
  }

  /**
   * Назад
   */
  goBack() {
    if (this.signProgress || this.existLocked()) {
      return;
    }
    this.modal.close(this.isSign);
  }
  existLocked(): boolean {
    return this.listSign.filter(i => i.lock).length > 0;
  }
  ngOnDestroy(): void {
    this.singService.signDocumentsEnd(this.groupType);
  }
}
