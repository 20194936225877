import {Component, Input, OnDestroy} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";

@Component({
    selector: 'app-view-migr-card',
    templateUrl: './migr-card.component.html',
    styleUrls: ["../../../elMsgFm/components/DocumentForm/elMsgDocumentForm.component.scss"],
})
export class MigrCardComponent extends DocumentComponentBaseSub implements OnDestroy {
    @Input() readonly formGroupApp: FormGroup;
    @Input() readonly documentData: any;
    @Input() readonly title: string;
}
