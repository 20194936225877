import {Component, OnInit} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService, DocumentBase, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-armfm-inform-freeze5861U-v1',
  templateUrl: './inform-freeze5861U-v1.html',
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class InformFreeze5861UV1 extends DocumentBase implements OnInit {
  selectedSved = [];
  documentType = DocumentTypeEnum.FREEZING_FUNDS;
  form: FormGroup;
  indexSved: number;

  constructor(protected documentBaseService: DocumentBaseService,
              protected iconsService: IconsService,
              protected commonSrv: CommonService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router)
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSelectedSvedItem(index) {
    this.indexSved = index;
  }

  onSelectedListSvedRow(list) {
    this.selectedSved = list;
  }

  initForm(): void {
    const svedKo = this.documentBaseService.getSvedKoFormFreeze(this.d.informChast?.svedKO);
    this.fGroup('informChast').addControl('svedKO', svedKo);
    const versiiaFormata = this.f('sluzhChast.versiiaFormata');
    this.changeVersiiaFormata(versiiaFormata?.value);
    versiiaFormata?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.changeVersiiaFormata(value);
    });
  }

  get getSvedKO(): FormArray {
    return this.fArray('informChast.svedKO') as FormArray;
  }
  confirmWithoutChanges(data: {isConfirmBack: boolean | undefined, documentData: any[]}) {
    this.documentData.document.informChast.svedKO = data.documentData;
    const svedKo = this.documentBaseService.getSvedKoFormFreeze(this.d.informChast?.svedKO);
    const informChastGroup = this.fGroup('informChast');
    informChastGroup.removeControl('svedKO');
    informChastGroup.addControl('svedKO', svedKo);
  }

  changeVersiiaFormata(value: string) {
    this.documentBaseService.versiiaFormataValue = value;
  }
}
