import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentSubForm} from "@amlDocument/base";
import {ITable} from "@amlCore/components";
import {DocumentTypeEnum} from "../../../../../../../../documentForm";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {ViewDopClientInfoV1Component} from "../../view";

@Component({
  selector: 'app-table-dop-client-info-v1',
  templateUrl: './table-dop-client-info-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableDopClientInfoV1Component extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() readonly documentType: DocumentTypeEnum;
  viewForm = ViewDopClientInfoV1Component;

  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentFmV1Service) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getBenefitsiar.call(this.docSrv, data);
  childGetFormGroup = (data) => this.docSrv.getSvedEIOOper.call(this.docSrv, data, [], this.documentType);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }
}
