import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {AccessJson, FmDraftFilter} from "@amlCore/models";
import {RoleService, TableService, VisitedService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {FmFilterService, FmService} from "../../service";
import {IDataResponse} from "@amlCore/interfaces";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {AlertService} from "@amlCore/components";
import {CommonService} from "@amlDocument/services";
import {CommonSendDocumentsComponent} from '../../../documentForm/components/commonSendDocuments';
import {CommonList} from '../../../documentForm/model';
import {FM_DOCS_WITHOUT_FORM} from "../../../documentForm";

@Component({
  selector: 'app-armfm-fm-send-ready-list',
  templateUrl: './fm-send-ready-list.component.html'
})
export class FmSendReadyListComponent extends TableReactiveUrlController implements OnInit {

  isShowVisited = true;
  private readonly _MODAL_CONFIG = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;


  constructor(protected fmSrv: FmService,
              private fmFilterSrv: FmFilterService,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected modalDialog: NgbModal,
              protected tableService: TableService,
              protected router: Router,
              private alertSrv: AlertService,
              protected commonService: CommonService,
              protected roleService: RoleService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'signDate', dir: 'desc'},
        null, null, null, null, null, null, null, commonService, roleService);
  }

  dataList: Array<CommonList> = [];
  isAll = true;
  id: string;
  access: AccessJson;
  goBack = Utils.goBack;
  disabled = false;
  protected readonly FM_DOCS_WITHOUT_FORM = FM_DOCS_WITHOUT_FORM;

  ngOnInit() {
    // this.access = this.userSrv.getAccess(RegistriesAccessEnum.QREF_MVK);
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.activatedRoute.queryParams.subscribe(
        data => {
          this.isAll = !this.id;
          const paramsData = this.syncParams(data);
          paramsData.id = this.id;
          this.loadList(paramsData);
        });
    });
    this.fmFilterSrv.loadUserAccessList();
    super.ngOnInit()
  }

  importLoad(data?) {
    this.onPageChange({$refresh: Math.random()});
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data: FmDraftFilter) {
    const filter = this.tableService.getFilterSubject(data) as FmDraftFilter;
    return this.getFilterPlanObject(filter);
  }

  public onPageChange(params) {
    const filter = this.getParams({...params, ...this.filter}) as FmDraftFilter;
    filter['isAll'] = this.isAll;
    this.navigate(filter);
  }

  public onSearch() {
    const params = this.getSearchPage(this.filter) as FmDraftFilter;
    this.onPageChange(params);
  }

  private loadList(params?: any) {
    this.fmSrv.getFMWaitList(this.getParams(params)).subscribe((data: IDataResponse<CommonList>) => {
      this.dataList = data.content;
      this.total = data.totalElements;
      this.resetSelectedAll();
    });
  }

 /**
  * группировка списка по типам доккументов
  */
  groupByType(list) {
        return list.reduce((groups, item) => {
            const val = item.documentType.type;
            groups[val] = groups[val] || [];
            groups[val].push(item);
            return groups;
        }, {});
  }

  /* Отправка списка контейнеров, и скачивание документов по ID в зависимости от параметра download (true/false)*/
  sendDocuments() {
    const modalRef = this.modalDialog.open(CommonSendDocumentsComponent, this._MODAL_CONFIG);
    const selected = this.getSelectedModel(this.dataList) as Array<CommonList>;
    modalRef.componentInstance.open({
      item: selected.map(i => {
        return {
          id: i.id,
          name: i.documentType.name,
          date: i.messageDate?.replace('00:00:00', ''),
          type: i.documentType.type,
          process: true
        }
      }),
    });
    modalRef.result.then((data) => {
      this.onPageChange({$refresh: Math.random()});
      this.isSelectedAll = false;
    });
  }

  getConfig(docType) {
    return this.fmSrv?.getConfigDoc(docType);
  }

}
