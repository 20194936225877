import { Component, OnInit } from '@angular/core';
import {CommonService, DocumentBase, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {DocumentFmV1Service} from "../../../../../service/document-fm-v1.service";

@Component({
  selector: 'app-refuse-operation4077-u',
  templateUrl: './refuse-operation4077-u.component.html',
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class RefuseOperation4077UComponent extends DocumentBase implements OnInit {
  documentType = DocumentTypeEnum.REFUSE_OPERATION_1_1;
  indexSved: number;
  indexOsnov: number;
  form: FormGroup;

  constructor(protected documentBaseService: DocumentBaseService,
              protected iconsService: IconsService,
              protected commonSrv: CommonService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router,
              protected documentFmService: DocumentFmV1Service) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router);
  }

  ngOnInit(): void {
    this.versiiaFormataValue.pipe(takeUntil(this.destroy$)).subscribe(data => this.defineVersionOperations(data));
    super.ngOnInit();
  }

  onSelectedSvedItem(index) {
    this.indexSved = index;
  }

  onSelectedOsnovItem(index) {
    this.indexOsnov = index;
  }

  initForm(): void {
    const transactionsReject = this.documentFmService.getTransactionsRejectV1Form(this.d.informChast?.svedPredstav, this.documentType);
    this.fGroup('informChast').addControl('svedPredstav', transactionsReject);
  }

  /**
   * Подготовка начальных данных для формы создания документа
   */
  prepareInitDocumentData(): any{
    const documentData = this.commonSrv.prepareInitDocumentData(this.documentType);
    documentData.document.informChast.svedPredstav = [];
    return documentData;
  }


  get getFormSved(): FormArray {
    return this.fArray('informChast.svedPredstav')?.at(this.indexSved)?.get('svedOtkaz') as FormArray;
  }

  get getFormOsnov(): FormArray {
    return this.getFormSved?.at(this.indexOsnov)?.get('osnovanieOp') as FormArray;
  }

  get getFormUsers(): FormArray {
    return this.getFormSved?.at(this.indexOsnov)?.get('uchastnik') as FormArray;
  }

  get versiiaFormata() {
    return this.f('sluzhChast.versiiaFormata');
  }

  get svedPredstav() {
    return this.form?.get('informChast')?.get('svedPredstav') as FormArray;
  }

  defineVersionOperations(versiia: string) {
    switch (versiia) {
      case '1.1':
        this.documentType = DocumentTypeEnum.REFUSE_OPERATION_1_1;
        this.toggleFields1_1();
        break;
      case '1.2':
        this.documentType = DocumentTypeEnum.REFUSE_OPERATION_1_2;
        this.toggleFields1_2();
        break;
      default:
        this.documentType = DocumentTypeEnum.REFUSE_OPERATION_1_1;
        this.toggleFields1_1();
    }
  }

  toggleFields1_1() {
    const svedPredstav = this.svedPredstav;
    svedPredstav?.controls?.forEach((svedPredstavControl: FormGroup) => {
      const svedOtkaz = svedPredstavControl?.get('svedOtkaz') as FormArray;
      svedOtkaz?.controls?.forEach((svedOtkazControl: FormGroup) => {
        svedOtkazControl?.get('kodPrichinaOtkaz')?.disable();
        svedOtkazControl?.get('prichinaUdaleniia')?.disable();
        svedOtkazControl?.get('inaiaPrichinaOtkaza')?.disable();
      });
    });
  }

  toggleFields1_2() {
    const svedPredstav = this.svedPredstav;
    svedPredstav?.controls?.forEach((svedPredstavControl: FormGroup) => {
      const svedOtkaz = svedPredstavControl?.get('svedOtkaz') as FormArray;
      svedOtkaz?.controls?.forEach((svedOtkazControl: FormGroup) => {
        svedOtkazControl?.get('kodPrichinaOtkaz')?.enable();
        svedOtkazControl?.get('prichinaUdaleniia')?.enable();
        svedOtkazControl?.get('inaiaPrichinaOtkaza')?.enable();
      });
    });
  }
}
