import { Component, OnInit } from '@angular/core';
import {IconsService} from "@amlCore/services";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {docMaskConstants} from "src/app/core/constants/docMask.constants";

@Component({
  selector: 'app-correct-replace-message',
  templateUrl: './correct-replace-message.component.html',
  styleUrls: ['./correct-replace-message.component.scss']
})
export class CorrectReplaceMessageComponent implements OnInit {
  docResult: any[] = [];
  groupName = '';
  success: boolean;
  icons = this.iconsService.getIcons();
  constructor(private iconsService: IconsService,
              private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  open(param: any) {
    this.docResult = param.docResult;
    this.groupName = param.group || 'fm';
    this.success = param.success;
  }

  onClose(): void {
    this.activeModal.close();
  }

  getDocumentName(fileName: string) {
    const docMasks = docMaskConstants[this.groupName] || [];
    const fileType = docMasks.find(fileMask => fileMask.regex.test(fileName.toLowerCase()));
    return fileType?.docName || fileName;
  }
}
