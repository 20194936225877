import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DictionaryService} from "@amlCore/services";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentTypeEnum} from "@amlCore/enums";
import {REFUSE_OPERATION_1_X} from "../../../../../../../../documentForm";

@Component({
  selector: 'app-view-info-inboiul-v1',
  templateUrl: './view-info-inboiul-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewInfoInboiulV1Component extends DocumentComponentBaseSub implements OnInit {
  @Input() readonly formGroupApp: FormGroup;
  @Input() readonly documentData: any;
  @Input() readonly documentType: DocumentTypeEnum;
  @Input() readonly isVersiiaFormata1x = false;

  constructor(readonly docSrv: DocumentFmV1Service,
              readonly dictStaticSrv: DictionaryService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this._naimUchreditelChangeValid()
  }

  /**
   * Метод для проверки названия учередителя в трасте.
   * Нужно, чтобы не мешало сохранять документ
   */
  private _naimUchreditelChangeValid() {
    this.cdr.detectChanges(); // без этого disable() работать отказывается
    this.formArray('uchreditel').controls.forEach((control: FormGroup) => {
      const naimUchreditelValue = control.get('naimUchreditel').value;
      if (naimUchreditelValue.iurLitso !== null) {
        control.get('naimUchreditel.fizLitso').disable();
        control.get('naimUchreditel.fizLitso').updateValueAndValidity();
      }
      else if (naimUchreditelValue.fizLitso.fam !== null && naimUchreditelValue.fizLitso.imia !== null) {
        control.get('naimUchreditel.iurLitso').disable();
        control.get('naimUchreditel.iurLitso').updateValueAndValidity();
      }
    })
  }

  isRefuseOperation1x() {
    return REFUSE_OPERATION_1_X.includes(this.documentType);
  }
}
