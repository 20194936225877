import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-download-request-format',
  templateUrl: './download-request-format.component.html',
})
export class DownloadRequestFormatComponent {
  @Input() text;
  @Input() title = "Сообщение";
  @Input() firstFormat = "Печать pdf";
  @Input() secondFormat = "Печать odf";
  @Input() cancelBtn = "Отмена";
  @Input() isOutputText = false;
  @Input() hideCloseBtn = false;
  @Input() showWarningIcon = false;
  @Input() hideCancelBtn = false;
  constructor(public modal: NgbActiveModal) { }



}
