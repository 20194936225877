import {ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {IconsService, ResourceService} from "@amlCore/services";
import {NgbModal, NgbModalOptions, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {AlertPanelComponent, AlertPanelService, SaveErrorModalComponent, SignComponent} from "@amlCore/components";
import {Observable, of, Subject} from "rxjs";
import {catchError, filter, map, takeUntil} from "rxjs/operators";
import {DocumentBase} from "./documentBase";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {ConfirmDeactivate} from "../../../client";
import {FmService} from "../../fm/service/fm.service";
import {DocumentConfig, DocumentTypeEnum, getDocumentConfig} from "../enums";
import {CommonSendDocumentsComponent} from "../components/commonSendDocuments/commonSendDocuments.component";
import {CommonCheckDocumentsComponent} from "../components/commonCheckDocuments/commonCheckDocuments.component";
import {fillPrescribedControls, Utils} from "src/app/core";
import {CommonService} from "../services";
import {getPrescribedFields, getPrescribedFieldsStrateg5392} from "../../../core/components/tableComp/tableEditor/prescribedFields";
import {CommonSignAllDocsComponent} from "../components/common-sign-all-docs/common-sign-all-docs.component";

@Component({
  selector: 'app-document-form',
  templateUrl: './documentForm.component.html',
  styleUrls: ['./documentForm.component.scss']
})
export class DocumentFormComponent implements DoCheck, OnInit, OnDestroy, ConfirmDeactivate {
  @ViewChild('alertPanel', { static: true }) alertPanel: AlertPanelComponent;
  /**
   * Компонент, который открыт в таблице
   */
  document: DocumentBase;
  /**
   * Форма создания или редактирования
   * true - форма редактирования
   */
  isEdit: boolean;

  /**
   * Признак - форма в режиме просмотра
   */
  isReadOnly = false;

  /**
   * Признак - наличие ошибок в форме
   */
  hasErrors = false;
  savingInProgress = false;
  documentType: DocumentTypeEnum;
  documentId: string; /*идентификатор документа УПД*/
  icons = this._iconsSrv.getIcons();
  pageName = '';
  isBlockedOnServer = false; // признак что документ заблокировали на сервере
  isSaved = false;
  protected readonly _MODAL_CONFIG = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;
  destroy$ = new Subject();
  modal: NgbModalRef;
  logInvalidFieldsResult;
  flkErrors;
  blockType = ''
  constructor(
    protected fmSrv: FmService,
    protected _iconsSrv: IconsService,
    protected _resourceService: ResourceService,
    protected _activatedRoute: ActivatedRoute,
    protected modalDialog: NgbModal,
    protected alertPanelSrv: AlertPanelService,
    protected router: Router,
    protected cdr: ChangeDetectorRef,
    protected modalService: NgbModal,
    protected commonService: CommonService
  ) {
  }
  ngDoCheck(): void {
    this.document.form?.valueChanges
        .pipe(filter(() => this.isSaved), takeUntil(this.destroy$))
        .subscribe(value => this.isSaved = false);

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.isBlockedOnServer && this.documentId && this.commonService.getToken()) {
      this.commonService.unBlockDocuments([this.documentId], this.blockType,).pipe(catchError(err => {
        this.alertPanel.open(this.alertPanelSrv.getErrorMsg('Ошибка при разблокировке документа', false));
        return of();
      }), map(data => {
        if (data?.success.length === 0) {
          this.alertPanel.open(this.alertPanelSrv.getErrorMsg('Не удалось разблокировать документ', false));
          return of();
        }
      })
  ).subscribe();
      this.commonService.saveDocIds([])
      this.commonService.removeDocIdStorage()
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.commonService.setDocument(null);
  }

  ngOnInit() {
    this.documentId = this._activatedRoute.snapshot.params.id;
    this.isEdit = Boolean(this.documentId);
    this._activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.pageName = params.page);
    if(this.documentId){
      this.setReadOnly(true);
      // блокируем при просмотре
      this.commonService.blockDocuments( [this.documentId], this.blockType)
          .pipe(takeUntil(this.destroy$), catchError(err => {
                if (err.statusCode === 403 || err.statusCode === 400) {
                  this.document.documentIsUnblocked$.next(true);
                }
                const message = err.error ? err.error : err.statusCode === 403 ? 'Доступ запрещен' : 'Документ заблокирован другим пользователем';
                this.alertPanel.open(
                this.alertPanelSrv.getErrorMsg(message, false));
                return of();
              })
          )
          .subscribe((data) => {
            if (data?.success.length != 0) {
              this.blockedDocumentServer()
              this.setReadOnly(false)
              this.commonService.setDocIdStorage(this.documentId)
              this.commonService.setDocument(this.document); // Используется для формирования идентификатора при редактировании
              this.document.documentIsUnblocked$.next(true);
            } else {
              this.alertPanel.open(this.alertPanelSrv.getErrorMsg('Документ открыт другим пользователем', false));
              this.document.documentIsUnblocked$.next(true);
            }
          })
      // сохраняем id документа для доступа при выходе из системы
      this.commonService.saveDocIds([this.documentId])
    }

    if (!this.documentId) {
      this.commonService.setDocument(this.document); // Используется для формирования идентификатора (при создании документа)
    }

    setTimeout(() => {
      this.document?.form?.markAsPristine();
    }, 200);
  }
  // перевод документа в состояние просмотра и снятие с него dirty меток
  documentToViewMode(){
    this.isEdit = false
    this.setSubmitted(false);
    this.setReadOnly(true)
    this.document.form.markAsPristine();
  }
  blockedDocumentServer() {
    this.isBlockedOnServer = true
  }
  setReadOnly(readOnly: boolean){
    this.isReadOnly = readOnly;
    this.document.setReadOnly(readOnly);
  }

  getTitle() {
    return this.document
      ? this.document.getTitle()
      : '';
  }

  onActivate(componentRef) {
    this.document = componentRef as DocumentBase;
    this.documentType = componentRef.documentType;
  }

  goBack() {
    history.back();
  }

  moveToDeleted(): void {
    this.document.form.markAsPristine();
    if(this.documentId){
      this.commonService.moveToDeleted([this.documentId])
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.goBack();
        }, error => console.warn(error));
    } else {
      this.goBack();
    }
  }

  deleteDocument(): void {
    if (this.document.documentType == 'OES_OTKAZ_GOZ') {
      this.commonService
        .deleteDocument(this.document.documentType, this.documentId)
        .subscribe(() => {
          this.goBack();
        },
        error => {
          console.warn(error);
      })
      return;
    }

    this.commonService
        .deleteDocument(this.document.documentType, this.documentId)
        .subscribe(
            () => {
                this.goBack();
            },
            (error) => {
                console.warn(error);
            }
        );
  }

  showDeleteBtn(): boolean {
    return ['CREATED'].includes(this.document?.documentData?.documentStatus?.type);
  }

  /**
   * Открытие мод. окна "Подпись документа"
   */
  signDocument(): void {
    this.commonService.getDocInfoById(this.documentId, this.document.documentType).pipe(takeUntil(this.destroy$)).subscribe(value => {
      let modalRef = null;
      if (!value?.checked) {
        modalRef = this.modalDialog.open(CommonSignAllDocsComponent, {...this._MODAL_CONFIG,
          windowClass: 'redefine-modal-lg'});
        modalRef.componentInstance.open({
          item: [{
            id: this.documentId,
            name: this.document.documentData?.documentName,
            date: this.document.documentData?.document?.sluzhChast?.dataSoobshcheniia ||
                this.document.documentData?.document?.sluzhChastKO?.dataSoob ||
                this.document?.documentData?.document?.dokument?.dataSoob,
            type: this.document.documentType,
            checked: value?.checked,
            process: true,
          }],
          titleName: 'ЭДО 5861-У',
        });
      } else {
        modalRef = this.modalDialog.open(SignComponent, this._MODAL_CONFIG);
        modalRef.componentInstance.open({
          item: [{
            id: this.documentId,
            name:  value?.checked ? 'Подписать данный документ' : this.document.documentData?.documentName,
            date: this.document.documentData?.document?.sluzhChast?.dataSoobshcheniia || this.document.documentData?.document?.sluzhChastKO?.dataSoob || this.document?.documentData?.document?.dokument?.dataSoob,
            type: this.document.documentType,
            process: true,
            checked: value?.checked
          }],
          getHash: this.commonService.getHash.bind(this.commonService),
          sendSign: this.commonService.sendSign.bind(this.commonService),
        });
        modalRef.componentInstance.isFormDirty = this.document.form?.dirty;
      }
      modalRef.result.then((isSign) => {
        if (isSign) {
          this.documentToViewMode()
          this.goBack();
        }
      });
    });
  }

  /* Отправка списка контейнеров, и скачивание документов по ID в зависимости от параметра download (true/false)*/
  sendDocument() {
    const modalRef = this.modalDialog.open(CommonSendDocumentsComponent, this._MODAL_CONFIG);
    modalRef.componentInstance.open({
      section: this.getSendSectionType(),
      item: [{
        id: this.documentId,
        name: 'Отправить данный документ',
        date: this.document.documentData.document?.sluzhChast?.dataSoobshcheniia || this.document.documentData?.document?.sluzhChastKO?.dataSoob,
        type: this.document.documentType,
        process: true
      }],
    });
    modalRef.result.then((data) => {
      if (data) {
        this.goBack();
      }
    });
  }

  /* Проверка выбранного документа*/
  checkDocument() {
    const modalRef = this.modalDialog.open(CommonCheckDocumentsComponent, this._MODAL_CONFIG);
    modalRef.componentInstance.open({
      item: [{
        id: this.documentId,
        name: `${this.document?.documentData?.documentName}`,
        date: this.document.documentData.document?.sluzhChast?.dataSoobshcheniia ?? this.document.documentData.document?.sluzhChastKO?.dataSoob ?? this.document?.documentData?.createDate.split(' ')[0],
        type: this.document.documentType,
        process: true
      }],
    });

    modalRef.result.then((result) => {
      this.document.documentData.checked = result !== undefined ? result : this.document.documentData.checked;
    })
  }

  saveDocument(): void {
    if (this.document.form?.value) {
      // заполняем предписанные поля
      const prescribedFields = getPrescribedFields(this.document.documentType, this.document.form);
      fillPrescribedControls(this.document.form, prescribedFields);

      if (this.document.form.invalid) {
        this.logInvalidFieldsResult = this.logInvalidFields(this.document.form, '', {}, this.document.replaceName || {});
        if (this.hasErrors){
          this.setSubmitted(true);
          this.hasErrors = false;

          this.flkErrors = null;
          this.openSaveErrorModal();
          return;
        }
      }
      this.savingInProgress = true;
      if (this.documentId) {
        this.commonService.saveDocument(
          this.document.documentType,
          this.documentId,
          this.document.documentData.needAttention,
          this.document.form.value
        )
          .pipe(
            catchError(this.setSubmitted),
            takeUntil(this.destroy$)
          )
          .subscribe(data => {
            this.logInvalidFieldsResult = null;
            this.flkErrors = data?.check;
           (this.flkErrors?.errors?.length || this.flkErrors?.warnings?.length || this.flkErrors?.fatals?.length) && this.openSaveErrorModal();

            this.savingInProgress = false;
            if (data.success) {
              this.document.documentData.document = this.document.form.value; // todo
              this.document.documentData.documentName = data.fileName;
              this.document.form.markAsPristine();
              this.document.submitted = false;
              this.isSaved = true;
            }
          });
      } else {
        if (getDocumentConfig(this.document.documentType) === DocumentConfig.OES_OTKAZ_GOZ) {
          const prescribedFields = getPrescribedFieldsStrateg5392(this.document.form);
          fillPrescribedControls(this.document.form, prescribedFields);
        }

        this.commonService.createDocument(
          this.document.documentType,
          this.document.documentData.needAttention,
          this.document.form.value).pipe(
          catchError(this.setSubmitted),
          takeUntil(this.destroy$)
        )
          .subscribe(data => {
            this.logInvalidFieldsResult = null;
            this.flkErrors = data?.check;
            (this.flkErrors?.errors?.length || this.flkErrors?.warnings?.length || this.flkErrors?.fatals?.length) && this.openSaveErrorModal();

            if (data.success) {
              this.document.form.markAsPristine();
              const config = getDocumentConfig(this.document.documentType);

              switch (config.url) {
                case 'strateg5392u':
                  this.router.navigate([`/strateg5392u/document/${data.documentId}/${config.url}`],
                  { queryParams : {page: this.pageName}, replaceUrl: true });
                  break;

                case 'economicsMeasures':
                  this.router.navigate([`/control6670/document/${data.documentId}/${config.url}`],
                  { queryParams : {page: this.pageName}, replaceUrl: true });
                  break;

                default:
                  this.router.navigate([`/fm/document/${data.documentId}/${config.url}`],
                    { queryParams : {page: this.pageName}, replaceUrl: true });
                  break;
              }
              this.isSaved = true;
            }
            this.savingInProgress = false;
          });
      }

    }
  }

  openSaveErrorModal() {
    this.modal = this.modalService.open(SaveErrorModalComponent, {
      centered: true,
      size: 'lg',
      scrollable: true,
      backdrop: 'static',
      keyboard: false
    });
    this.modal.componentInstance.logInvalidFieldsResult = this.logInvalidFieldsResult
    this.modal.componentInstance.flkErrors = this.flkErrors
  }

  protected setSubmitted(error: ErrorEvent | boolean): Observable<never> {
    this.savingInProgress = false;
    if (this.document?.submitted !== undefined) {
      if (typeof error === 'boolean')
        this.document.submitted = error;
      if (error instanceof ErrorEvent && error?.error)
        this.document.submitted = true;
    }
    return of();
  }

  /**
   * нужно ли подтверждение для выхода из формы
   */
  needConfirmDeactivate(): boolean {
    return this.document.form?.dirty;
  }

  // todo вывод невалидных полей (в дальнейшем сделать чтобы переходить по найденному пути)
  logInvalidFields(control: AbstractControl, controlPath = '',
                   controlToTextMap: {[key: string]: string} = {},
                   replaceName: {[key: string]: string} = {}
  ) {
    const invalidErrorsArray = [];
    const emptyFieldErrorsArray = [];

    const invalidFieldsFunc = (control: AbstractControl, controlPath = '') => {
      if (control instanceof FormControl && control.invalid ) {
        if (control.hasError('required')) {
          emptyFieldErrorsArray.push({ controlPath, errors: control.errors });
        } else {
          invalidErrorsArray.push({ controlPath, errors: control.errors });
        }
        this.hasErrors = true;
      } else if (control instanceof FormGroup) {
        for (const key in control.controls) {
          if (control.controls.hasOwnProperty(key)) {
            const nestedControl = control.get(key);
             invalidFieldsFunc(nestedControl, controlPath ? `${controlPath}.${key}` : key);
          }
        }
      } else if (control instanceof FormArray) {

        if (control.length === 0 && control.hasError('required')) {
          emptyFieldErrorsArray.push({ controlPath, errors: control.errors });
          this.hasErrors = true;
        }

        if (control.length !== 0) {
          for (let i = 0; i < control.length; i++) {
            const arrayControl = control.at(i);
             invalidFieldsFunc(arrayControl, `${controlPath}[${i}]`);
          }
        }
      }
    }

    const transformFieldErrorControlPath = (errorField) => {
      if (controlToTextMap[errorField?.controlPath]) {
        errorField.controlPath = controlToTextMap[errorField?.controlPath];
      } else {
        const newEmptyFieldError: string[] = errorField?.controlPath.split('.').map(word => {
          if (replaceName[word]) {
            return replaceName[word];
          } else {
            return word[0].toUpperCase() + word.slice(1);
          }
        });
        let changedRegistr = '';
        newEmptyFieldError.forEach(word => changedRegistr += `/${word}`);

        errorField.controlPath = Utils.translit(changedRegistr.slice(1));
      }
    }

    invalidFieldsFunc(control, controlPath);
    emptyFieldErrorsArray.forEach(transformFieldErrorControlPath);
    invalidErrorsArray.forEach(transformFieldErrorControlPath);

    return { invalidErrorsArray, emptyFieldErrorsArray };
  }

  showToDraftsBtn(): boolean {
    return ["DELETED"].includes(
        this.document?.documentData?.documentStatus?.type
    );
  }

  moveToDrafts(): void {
    this.commonService.deletedToDrafts([this.documentId], this._activatedRoute.snapshot?.pathFromRoot[1].routeConfig.path).subscribe(() => this.goBack());
  }

  getSendSectionType(): string {
    switch (this.document.documentType) {
      case DocumentTypeEnum.OES_OTKAZ_GOZ:
        return 'oes/otkaz/goz';
      case DocumentTypeEnum.SD_KONFO_ECONOMIC_MEASURE:
        return 'sd/konfo/economic-measure';
      default:
        return '';
    }
  }
}
