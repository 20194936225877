import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentSubForm, DocumentTypeEnum, OPERATIONS_1_X} from "../../../../../../../../documentForm";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {ViewClientInfoV1Component} from "../../view/view-client-info-v1/view-client-info-v1.component";

@Component({
  selector: 'app-table-client-info-v1',
  templateUrl: './table-client-info-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableClientInfoV1Component extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() readonly documentType: DocumentTypeEnum;
  viewForm = ViewClientInfoV1Component;

  constructor(
      protected readonly modalService: NgbModal,
      private readonly docSrv: DocumentFmV1Service
  ) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getUchastnikOpFmV1(data, null, this.documentType);
  childGetFormGroup = (data) => this.docSrv.getUchastnik(data, null, this.documentType);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }

  get isDopUchastniki() {
    return OPERATIONS_1_X.includes(this.documentType)
        || this.documentType === DocumentTypeEnum.NFO_OPERATIONS
        || this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
  }

  get isSuspiciousActivity() {
    return this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
  }
}
