// ToDo при добавлении readOnly для ДО заменить оставшиеся параметры для вида pageName = shortRole.id
export enum pageNameEnum {
    fm = 'arm_fm',
    svetofor = 'svetofor',
    rejection = 'reestr_rejection',
    REQUESTS_GA_ACCESS = 'requests-government-agencies',
    ACCESS_4937 = 'arm_4937_u',
    strateg5392u = 'oes-otkaz-goz',
    strateg655p = 'arm_strateg',
    elMsgFm = 'arm_407',
    dictionaries = 'aml_common_dictionary',
    control6670 = 'arm_6670'
}
