import {Component, Input, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-arm-doc-need-attention",
    templateUrl: "./need-attention.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"]
})
export class NeedAttentionComponent extends DocumentComponentBaseSub implements OnInit {
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
    @Input() documentType: any;
    @Input() params: any;
    @Input() isReadOnlyRole: boolean

    needAttentionField: FormControl;
    regExp = new RegExp(/\d{6}\.\w{3,}/gm);
    docName;

    constructor(readonly docSrv: DocumentBaseService) {
        super();
    }

    ngOnInit(): void {
        this.docName = this.documentData?.documentName;
        this.needAttentionField = new FormControl(this.documentData?.needAttention);

        if (this.documentType === 'OPERATIONS') {
            this._checkDocName();
        }
        this._needAttentionChange();
    }

    private _needAttentionChange() {
        this.needAttentionField.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(value => {
                this._changeDocName(value ? '9' : '8');
                this.documentData.needAttention = value;
                this.formGroupApp.markAsDirty();
            })
    }

    private _checkDocName() {
        const condition = this.params.some(element =>
            element?.operatsiia.some(operation =>
                operation?.kodOperatsii[0] === '7'));

        if (condition) {
            this.needAttentionField.setValue(true);
            this.needAttentionField.updateValueAndValidity({onlySelf: true});
        }
    }

    private _changeDocName(firstNumber: string) {
        if (this.docName) {
            const oldDocName = this.docName.match(this.regExp);
            const newDocName = firstNumber + oldDocName[0].slice(1);
            const index = this.docName.indexOf(oldDocName);

            this.documentData.documentName = this.docName.slice(0, index) + newDocName;
        }
    }
}
