// Предписанные поля для 407-Положение
import {FormArray, FormGroup} from "@angular/forms";
import { DocumentConfig, DocumentTypeEnum, getDocumentConfig } from "../../../../arm/documentForm/enums/documentTypeEnum";

export const prescribedFields_P407_ANSWER = [
    //Вкладка Информационная часть
    "infChast.svedIuL.adrIuL.region",
    "infChast.svedIuL.inNaimIuL",
    "infChast.svedIuL.sokrNaimIuL",
    "infChast.svedIuL.adrIuL.indeks",
    "infChast.svedIuL.adrIuL.dom",
    "infChast.svedIuL.adrIuL.kodSubieektaPoOKATO",
    "infChast.svedIuL.adrIuL.ulitsa",
    "infChast.svedIuL.adrIuL.korp",
    "infChast.svedIuL.adrIuL.of",
    "infChast.svedIuL.adrIuL.region",
    //Вкладка Сведения об адресах ФЛ/ЮЛ/ИП
    "infChast.razdel1.adrReg.region",
    "infChast.razdel1.adrReg.indeks",
    "infChast.razdel1.adrReg.kodSubieektaPoOKATO",
    "infChast.razdel1.adrReg.ulitsa",
    "infChast.razdel1.adrReg.dom",
    "infChast.razdel1.adrReg.korp",
    "infChast.razdel1.adrReg.of",
    //Вкладка Сведения о бенефициарном владельце
    "infChast.razdel4.mestoRozhdFL",
    "infChast.razdel5.razdel51.svedBenefits.innfl",
    "infChast.razdel5.razdel51.svedBenefits.mestoRozhdFL.kodSubieektaPoOKATO",
    "infChast.razdel5.razdel51.benefitsAdrReg.region",
    "infChast.razdel5.razdel51.svedBenefits.mestoRozhdFL.region",
  ]

  /**
   * Получение списка полей для заполнения пустыми значениями
   * в зависимости от типа документа
   * @param documentType тип документа
   * @param documentForm форма документа
   * @returns
   */
export const getPrescribedFields = (documentType: DocumentTypeEnum, documentForm: FormGroup | FormArray): string[] => {
    switch (getDocumentConfig(documentType)) {
        case DocumentConfig.OES_OTKAZ_GOZ:
            return getPrescribedFieldsStrateg5392(documentForm);

        case DocumentConfig.SD_KONFO_ECONOMIC_MEASURE:
            return getPrescribedFieldsControl6670(documentForm);

        default:
            return [];
    }
}

export const getPrescribedFieldsStrateg5392 = (documentForm: FormGroup | FormArray): string[] => {
    const prescribedFields = [];
    const addAddressFields = (defaultString: string): void => {
        prescribedFields.push(`${defaultString}.dom`);
        prescribedFields.push(`${defaultString}.indeks`);
        prescribedFields.push(`${defaultString}.kodSubieektaPoOKATO`);
        prescribedFields.push(`${defaultString}.adrVladelets.korp`);
        prescribedFields.push(`${defaultString}.adrVladelets.of`);
        prescribedFields.push(`${defaultString}.adrVladelets.rajon`);
        prescribedFields.push(`${defaultString}.adrVladelets.ulitsa`);
    };

    const vladeletsFileds = documentForm.get('razdel2.vladelets') as FormArray;
    vladeletsFileds.value.forEach((vladeletsItem, index) => {
        addAddressFields(`razdel2.vladelets.${index}.adrVladelets`);
        addAddressFields(`razdel2.vladelets.${index}.adrVladeletsInoj`);
        const svedeniia = documentForm.get(`razdel2.vladelets.${index}.razdel3.svedeniia`) as FormArray;
        svedeniia.value.forEach((svedeniiaItem, indexSved) => {
            addAddressFields(`razdel2.vladelets.${index}.razdel3.svedeniia.${indexSved}.adrBankPoluchatel`);
            addAddressFields(`razdel2.vladelets.${index}.razdel3.svedeniia.${indexSved}.adrPoluchatel`);
            addAddressFields(`razdel2.vladelets.${index}.razdel3.svedeniia.${indexSved}.adrPoluchatelInoj`);
        });
    });
    return prescribedFields;
};

// получение путей для заполнения предписанных полей в документах 6670.
// ToDo сейчас идёт получение полей только для фл. Доделать для юл
export const getPrescribedFieldsControl6670 = (documentForm: FormGroup | FormArray) => {
    const prescribedFields = [];
    const svedBlokirLitsaFields = documentForm.get('informChast.svedBlokirLitsa') as FormArray;
    const addSvedFl = (defaultString: string) => {
        prescribedFields.push(`${defaultString}.innfl`)
    }
    const addSvedDokUdLichn = (defaultString: string) => {
        prescribedFields.push(`${defaultString}.seriiaDok`, `${defaultString}.nomDok`)
    };

    svedBlokirLitsaFields.value.forEach((value, index) => {
        addSvedDokUdLichn(`informChast.svedBlokirLitsa.${index}.svedFL.svedDokUdLichn`);
        addSvedFl(`informChast.svedBlokirLitsa.${index}.svedFL`);
    });
    return prescribedFields;
}
