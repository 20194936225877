import { Component, OnInit, OnDestroy } from '@angular/core';
import {TableService} from "@amlCore/services";
import { Subject } from 'rxjs';
import {IDictItemType} from "@amlCore/interfaces";
import {takeUntil} from "rxjs/operators";
import {DictionariesService} from "../service/dictionaries.service";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ImportDictionaryComponent, RoleService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dictionary-list',
  templateUrl: './dictionary-list.component.html'
})
export class DictionaryListComponent implements OnInit, OnDestroy {

  private readonly _MODAL_CONFIG = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;

  protected isReadOnlyRole = false;

  constructor(protected dictSrv: DictionariesService,
    protected tableService: TableService,
    protected modalDialog: NgbModal,
    protected router: Router,
    private _activatedRoute: ActivatedRoute,
    private roleService: RoleService
              ) {
  }

  dictionaryList: IDictItemType[] = [];

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.dictSrv.getDictionariesList().pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.dictionaryList = data;
    });

    this.isReadOnlyRole = this.roleService.getIsReadOnlyRole(this._activatedRoute.snapshot?.pathFromRoot[1].routeConfig.path) || false;
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  viewDictionary(code: string) {
    this.router.navigate([`dictionaries/view/${code}`]);
  }

  importDictionary(code: string) {
    if (!this.isReadOnlyRole) {
      const modal = this.modalDialog.open(ImportDictionaryComponent, this._MODAL_CONFIG);
      const url = () => {
        switch (code) {
          case 'banc':
            return `/api/v1/dictionary/banc/update/by-banc`;
          case 'fil':
            return `/api/v1/dictionary/fil/update/by-fil`;
          case 'cb-bnkseek':
            return `/api/v1/dictionary/cb-bnkseek/update/by-ed807`;
          default:
            return ``;
        }
      }
      
      modal.componentInstance.url = url();
      modal.componentInstance.code = code;
    }
  }
}


