import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup, FormGroupDirective} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {Utils} from "@amlCore/utils";

/**
 * Компонент для всех для FormGroup отображающихся по кнопке
 */
@Component({
  selector: 'app-accordion-form-group',
  template: `
    <ng-container *ngIf="{show: show | async} as sub">
      <div class="accordion-header">
        <h5 class="typography-h5 typography-blue" [class.text-danger]="getErrorClassCondition(sub.show)">{{ title }}</h5>
        <button (click)="updateIsShow(!sub.show, true)"
                *ngIf="!isReadOnly && isNeedUpdateShow"
                #showButton
                [disabled]="isFieldReq(formGroup)"
                class="btn btn-link float-right"
                [class.text-danger]="getErrorClassCondition(sub.show)"
                type="button">{{sub.show ? cancelText : expendText}}
        </button>
      </div>
      <hr class="typography-sub-hr pt-0 mt-0"/>
      <div [formGroup]="formGroup">
        <div *appShowFormGroup="sub.show; isReadOnly: isReadOnly;">
          <ng-content></ng-content>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ["../../base/documentForm.component.scss"]
})
export class AccordionFormGroupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() formGroupName: string;
  @Input() title = '';
  @Input() isReadOnly: boolean;
  @Input() cancelText = 'Удалить';
  @Input() expendText = 'Добавить';
  @Input() showAlways = false;
  @Input() isRequiredBlock: boolean;
  @Input() submitted: boolean;
  @Input() isNeedUpdateShow = true;

  show = new BehaviorSubject(false);
  showObs = this.show.asObservable();
  destroy$ = new Subject();

  @ViewChild('showButton') showButton: ElementRef<HTMLButtonElement>;

  constructor(private parentForm: FormGroupDirective,
              private changeDetect: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.updateFormGroupAndView();

    if (this.isReadOnly) {
      this.formGroup.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.formGroup?.firstChange || !changes?.formGroupName?.firstChange)
      this.updateFormGroupAndView();
  }

  updateIsShow(isShow?: boolean, isCalledByUser?: boolean ) {
    if (isShow == false) {
      this.formGroup.reset();
    }
    let currentShow = this.formGroup.disabled
      ? this.isFieldReq(this.formGroup)
      : true;
    if (this.isReadOnly)
      currentShow = !this.isNullFieldsFormGroup;
    this.show.next(isShow ?? currentShow);

    if (this.isNullFieldsFormGroup && !isCalledByUser && !this.showAlways) {
      this.show.next(false);
    }

    if (this.isNullFieldsFormGroup  && !this.showAlways) {
      this.formGroup.disable();
    }

    if (isShow) {
      this.show.next(isShow);
    }

    if (!isShow && !this.isReadOnly && this.isRequiredBlock) {
      this.changeDetect.detectChanges();
      this.formGroup.enable();
    }

    if (isCalledByUser) setTimeout(() => this.formGroup?.markAsDirty());
    this.changeDetect.detectChanges();
  }

  isFieldReq(control: AbstractControl): boolean  {
    return Boolean(control?.validator && control.validator({} as AbstractControl)?.required);
  }

  private updateFormGroupAndView() {
    const formGroupFromName = this.parentForm?.form?.get(this.formGroupName) as FormGroup;
    this.formGroup = formGroupFromName ?? this.parentForm?.form;
    this.showAlways ? this.updateIsShow(this.showAlways) : this.updateIsShow();
  }

  /**
   * заполнен ли formGroup только нулевыми полями
   */
  private get isNullFieldsFormGroup(): boolean {
    const formValue = Utils.clone(this.formGroup.getRawValue());
    const amountFields = Object.keys(formValue).length;
    const amountNullFields = Utils.deleteEmptyObj(formValue, true);
    return amountFields === amountNullFields;
  }

  getErrorClassCondition(show) {
    return this.formGroup.invalid && !show && !this.isReadOnly && this.isRequiredBlock && this.submitted;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
