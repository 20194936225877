import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CryptographService} from "../../../cryptography";
import {Observable, of} from "rxjs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import { SignService } from "./sign.service";
import { Utils } from "../../utils";
import { FileSaverService } from "ngx-filesaver";
import { ConfirmComponent } from "../../modals";
import {catchError} from "rxjs/operators";
import {signItem} from "../../../arm/documentForm/model";

@Component({
  selector: 'app-sign-window',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class SignComponent implements OnInit, OnDestroy {

  listSign: signItem[] = [];
  isSingleDocument: boolean;
  isDocumentSigned: boolean;
  getHash: (id, type, oid) => Observable<any>;
  sendSign: (id, type, signature) => Observable<any>;
  block: (ids) => Observable<any>;
  unblock: (ids) => Observable<any>;
  signProgress = false;
  isSign = false; // признак что подписывали документы
  stopSigned = false;
  sectionType = ''
  @Input() isFormDirty = false;
  icons = {
    spinner: faSpinner
  }
  docsResult: any;
  notCheckedList = [];
  constructor(public modal: NgbActiveModal, private cryptographService: CryptographService,
    protected signSrv: SignService,
    protected fSaver: FileSaverService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.isSingleDocument = this.listSign?.length === 1;
  }

  ngOnDestroy(): void {
    this.signSrv.signDocumentsEnd(this.sectionType);
  }

  open(param: any) {
    param.item.forEach(element => {
      element.checked === true ?
      this.listSign.push(element) :
      this.notCheckedList.push(element);
    })
    this.sendSign = param.sendSign;
    this.getHash = param.getHash;
    this.sectionType = param.sectionType
    this.block = param.block || (() => of({}));
    this.unblock = param.unblock || (() => of({}));
  }

  /**
   * Назад
   */
  goBack() {
    if (this.signProgress || this.existLocked()) {
      return;
    }
    this.modal.close(!this.isSingleDocument ? this.isSign : this.isDocumentSigned);
  }

  stopSign() {
    this.stopSigned = true;
    this.signProgress = false;
  }

  getAllSignDocs(): signItem[] {
    return this.listSign.filter(i => i.process && !i.lock);
  }

  getIdsSignDocs(): string[] {
    return Array.from(new Set( this.listSign.map(item => item.id)))
  }

  existLocked(): boolean {
    return this.listSign.filter(i => i.lock).length > 0;
  }

  confirmSign() {
    const modal = this.modalService.open(ConfirmComponent, {
      centered: true
    });

    modal.componentInstance.text = 'Документ не сохранен и будет подписан без внесенных изменений';
    modal.componentInstance.okBtn = 'Да';
    modal.componentInstance.cancelBtn = 'Нет';

    return modal;
  }

  signDocument() {

    const signAllowed = () => {
          this.stopSigned = false;
          const needSign = this.getAllSignDocs();
          if (needSign.length === 0) {
            return;
          }
          const cert = this.cryptographService.crypt.getSelectedCert();
          if (!cert) {
            needSign.forEach(doc => {doc.error = this.errorCatchCert(null) })
            return;
          }
          const _iterate = this.iteratorObject(needSign);
          const oid = this.cryptographService.crypt.getAlgorithmCert();
          this.signProgress = true;
          const that = this;
      const step = (iterate) => {
        const next = iterate.next();
        if (!next.done) {
          blockAndSign(iterate, next.object);
        } else {
          that.signProgress = false;
          this.checkSignState();
        }
      }
      this.isSign = true;

      const blockAndSign = (iterate, item) => {
        this.block([item.id]).pipe(
            catchError(err => {
              item.process = false;
              item.error = err.error
              item.lock = false
              step(iterate)
              return of()
            })
        ).subscribe(() => {
          if (this.stopSigned || item.lock) {
            return;
          }
          item.lock = true;
          that.getHash(item.id, item.type, oid).subscribe((data) => {
            that.cryptographService.crypt.generateSignature(Object.entries(JSON.parse(data))[0][1], cert).then(signature => {
              that.sendSign(item.id, item.type, signature).subscribe((result) => {
                item.signature = data;
                item.success = result;
                this.isSingleDocument && (this.isDocumentSigned = result);
                item.process = false;
                item.error = item.success === true ? null : 'Возникла ошибка! Документ не подписан!';
                item.lock = false;
                step(iterate)
              }, error => {
                item.error = error ? error : 'Возникла ошибка';
                item.lock = false;
                item.process = false;
                step(iterate)
              })

              // разблокировка подписываемого документа после успешной отправки на подпись
              that.unblock([item.id]).subscribe((result) => {
              });

            }, error => {
              item.error = this.errorCatchCert(error);
              item.lock = false;
              item.process = false;
              step(iterate)
              // разблокировка подписываемого документа после ошибки получения generateSignature
              that.unblock([item.id]).subscribe((result) => {
              });
            })
          }, error => {
            item.error = error;
            item.lock = false;
            item.process = false;
            step(iterate);
            // разблокировка подписываемого документа после ошибки получения хеша
            that.unblock([item.id]).subscribe((result) => {
            });
          })
        })
      }

      blockAndSign(_iterate, _iterate.next().object)
    }

    if (this.isFormDirty) {
      this.confirmSign().result.then(() => { signAllowed(); }, () => { return; })
    } else {
      signAllowed();
    }
  }

  errorCatchCert = (error) => {
    let message: string;
    let code: string;
    if (error?.message?.indexOf('0x8010006E') > 0) {
      message = `Отсутствует закрытый ключ или действие было отменено пользователем`;
      code = `0x8010006E`;
    } else if (error?.message?.indexOf('0x000004C7') > 0) {
      message = `Операцию с ключами или сертификатами была отменена пользователем`;
      code = `0x000004C7`;
    } else {
      message = `В профиле пользователя не назначен сертификат электронной подписи, или выбранный сертификат не валиден!`;
      code = null;
    }
    return {message, code };
  }

  iteratorObject(array) {
    let nextIndex = 0;
    return {
      next: function (): any {
        return nextIndex < array.length ?
          {object: array[nextIndex++], done: false} :
          {done: true};
      }
    }
  }

/**
   * Запрос информации по подписи
   */
 checkSignState(): void {
  this.signSrv.signDocumentsInfo(this.sectionType).pipe().subscribe((data) => {
    this.docsResult = Object.keys(data?.signDocs).length !== 0 ? data : null;
  },
  err => {
  });
}

  /**
   * Экспорт результатов подписи
   */
   exportSignResults(): void {
    this.signSrv.downloadSignResults(this.sectionType).subscribe((value) => {
        if (value.type === 4) {
          this.fSaver.save(value.body, Utils.getFileNameFromContentDisposition(value));
        }
      }
    );
  }

  /**
   * Экспорт результата подписи для конкретного файла
   */
   downloadFileImportResult(docName: string): void{
        this.signSrv.downloadSignResults(this.sectionType,docName).subscribe((value) => {
        if (value.type === 4) {
          this.fSaver.save(value.body, Utils.getFileNameFromContentDisposition(value));
        }
      }
    );
  }

  checkErrorMessage(errorText: string): boolean {
    return errorText !== 'В профиле пользователя не назначен сертификат электронной подписи, или выбранный сертификат не валиден!';
  }

  checkBlockError(errorText: string = ''): boolean {
     return !errorText?.includes('Документ открыт другим пользователем')
  }
}
