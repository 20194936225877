export const docMaskConstants = {
    control6670: [
        {
            regex: /^spo281fz_(\d{10})_(\d{8})_(\d{3})\.xml$/,
            serviceName: 'sd/konfo/economic-measure',
            docName: 'ЭС об экономических мерах'
        }
    ],
    fm: [
        {
            regex: /sko115fz_(01|02|06)_(\d{9})_(\d{8})_(?!000000)([0-9]{6})\.xml$/,
            serviceName: 'operations',
            docName: 'Сведения об операциях'
        },
        {
            regex: /sko115fz_05_(\d{9})_(\d{8})_(?!000000)([0-9]{6})\.xml$/,
            serviceName: 'obstruction',
            docName: 'ФЭС о фактах воспрепятствования'
        },
        {
            regex: /sko115fz_09_(\d{9})_(\d{8})_(?!000000)([0-9]{6})\.xml$/,
            serviceName: 'suspicious',
            docName: 'ФЭС о подозрительной деятельности'
        },
        {
            regex: /sko4077u_(01|11)_(\d{9})_(\d{8})_(?!000)([0-9]{3})\.xml$/,
            serviceName: 'refuseContract',
            docName: 'Отказ от заключения договора (расторжение)'
        },
        {
            regex: /sko4077u_02_(\d{9})_(\d{8})_(?!000)([0-9]{3})\.xml$/,
            serviceName: 'refuseOperation',
            docName: 'Отказ в совершении операции'
        },
        {
            regex: /sko115fz_04_(\d{9})_(\d{8})_(?!000000)([0-9]{6})\.xml$/,
            serviceName: 'verificationResult',
            docName: 'Сведения о результатах проверки'
        },
        {
            regex: /sko115fz_03_(\d{9})_(\d{8})_(?!000000)([0-9]{6})\.xml$/,
            serviceName: 'freezing',
            docName: 'Сведения о принятых мерах по замораживанию (блокированию)'
        },
        {
            regex: /(\d{8}[.]\d{3})$/,
            serviceName: 'operations',
            docName: 'Сведения об операциях'
        },
        {
            regex: /^\d{5}[d|f]\d{2}\.\d{2}8$/,
            serviceName: 'freezing',
            docName: 'Сведения о принятых мерах по замораживанию (блокированию)'
        },
        {
            regex: /^\d{5}[k|m]\d{2}\.\d{2}9$/,
            serviceName: 'verificationResult',
            docName: 'Сведения о результатах проверки'
        },
        {
          regex: /\d{5}[a|b]\d{2}\.\d{3}$/,
          serviceName: 'refuseContract',
          docName: 'Отказ от заключения договора (расторжение)'
        }
    ],
    strateg655p: [
        {
            regex: /^sbc[0|2][1-3]\d{7}_\d{4}\d{8}_\d{4}\d{4}\d{2}\d{8}_[0-7](?:00|(?:0[1-9]|[1-6]\d|7[0-6]))\.xml$/,
            serviceName: 'otkr/zakr/chetGoz',
            docName: ''
        },
        {
            regex: /^sbc[1|3][1-3]\d{7}_\d{4}\d{8}_\d{4}\d{4}\d{2}\d{8}_[0-7](?:00|(?:0[1-9]|[1-6]\d|7[0-6]))\.xml$/,
            serviceName: 'izm/chetGoz',
            docName: ''
        },
        {
            regex: /^sko443_(03|13)_\d{9}_\d{8}_\d{3}\.xml$/,
            serviceName: 'otkr/zakr/chetDepo',
            docName: ''
        },
        {
            regex: /^sko443_(02)_\d{9}_\d{8}_\d{3}\.xml$/,
            serviceName: 'sd/ko/securities',
            docName: ''
        },
        {
            regex: /^sko443_(23)_\d{9}_\d{8}_\d{3}\.xml$/,
            serviceName: 'sd/ko/izm/chet/depo',
            docName: ''
        },
        {
            regex: /^sko443_(01|11)_\d{9}_\d{8}_\d{3}\.xml$/,
            serviceName: 'sd/ko/otkr/zakr/akkr',
            docName: ''
        },
        {
            regex: /^sko443_(21)_\d{9}_\d{8}_\d{3}\.xml$/,
            serviceName: 'izm/akkred',
            docName: ''
        },
    ],
    strateg5392u: [
        {
          regex: /^gozko_(\d{9})_(\d{8})_(\d{3})\.xml$/,
          serviceName: 'oes/otkaz/goz',
          docName: 'ОЭС об отказе ГОЗ'
        }
    ],
    elMsgFm: []
};

export type docModule = 'fm' | 'control6670' | 'strateg655p' | 'strateg5392u' | 'elMsgFm';

export const docSBCRegex = /^sbc[0-3][1-3]\d{7}_\d{4}\d{8}_\d{4}\d{4}\d{2}\d{8}_[0-7](?:00|(?:0[1-9]|[1-6]\d|7[0-6]))\.xml$/;
