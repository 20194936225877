import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {AccessJson, FmDraftFilter} from "@amlCore/models";
import {RoleService, TableService, VisitedService} from "@amlCore/services";
import {Utils, hasCheckedDoc, checkIsVersionDependentDoc} from "@amlCore/utils";
import {FmFilterService, FmService} from "../../service";
import {IDataResponse} from "@amlCore/interfaces";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {
  CommonService,
  CommonSignAllDocsComponent,
  FM_DOCS_WITHOUT_FORM,
  ImportDocumentsV2Component
} from "../../../documentForm";
import {AlertService, SignComponent} from "@amlCore/components";
import {CheckDocumentsComponent} from '../checkDocuments';
import {getDocTypes} from '../../../../core/constants';
import {CommonList} from "../../../documentForm/model";

@Component({
  selector: 'app-armfm-fm-draft-list',
  templateUrl: './fm-draft-list.component.html'
})
export class FmDraftListComponent extends TableReactiveUrlController implements OnInit {

  private readonly _MODAL_CONFIG = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;

  isShowVisited = true;

  constructor(protected fmSrv: FmService,
              private fmFilterSrv: FmFilterService,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected modalDialog: NgbModal,
              protected tableService: TableService,
              protected router: Router,
              protected commonService: CommonService,
              protected alertSrv: AlertService,
              protected roleService: RoleService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'createDate', dir: 'desc'},
        null, null, null, null, null, null, null, commonService, roleService);
  }

  dataList: Array<CommonList> = [];
  isAll = true;
  id: string;
  access: AccessJson;
  goBack = Utils.goBack;
  docTypesDataList;
  protected readonly FM_DOCS_WITHOUT_FORM = FM_DOCS_WITHOUT_FORM;
  protected readonly checkIsVersionDependentDoc = checkIsVersionDependentDoc;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.activatedRoute.queryParams.subscribe(
        data => {
          this.isAll = !this.id;
          const paramsData = this.syncParams(data);
          paramsData.id = this.id;
          this.loadList(paramsData);
        });
    });
    this.fmFilterSrv.loadUserAccessList();
    this.docTypesDataList = getDocTypes('fm');
    super.ngOnInit()
  }

  importLoad(data?) {
    this.onPageChange({$refresh: Math.random()});
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data: FmDraftFilter) {
    const filter = this.tableService.getFilterSubject(data) as FmDraftFilter;
    return this.getFilterPlanObject(filter);
  }

  public onPageChange(params) {
    const filter = this.getParams({...params, ...this.filter}) as FmDraftFilter;
    filter['isAll'] = this.isAll;
    this.navigate(filter);
  }

  public onSearch() {
    const params = this.getSearchPage(this.filter) as FmDraftFilter;
    this.onPageChange(params);
  }

  private loadList(params?: any) {
    this.fmSrv.getFMList(this.getParams(params)).subscribe((data: IDataResponse<CommonList>) => {
      this.dataList = data.content;
      this.total = data.totalElements;
      this.resetSelectedAll();
    });
  }


  /**
   * Открытие мод. окна "Импорт документов"
   */
  openImportDocuments(): void {
    const modalRef = this.modalDialog.open(ImportDocumentsV2Component, this._MODAL_CONFIG)
    modalRef.componentInstance.maxSizeFile = 6291456;
    modalRef.componentInstance.docModuleImport = 'fm';
    modalRef.componentInstance.accept = '';
    modalRef.result.then((data) => {
      this.importLoad(data);
    });
  }

  /**
   * Открытие мод. окна "Подпись документов"
   */
  signDocument(): void {
    const selected = this.getSelectedModel(this.dataList) as Array<CommonList>

    let modalRef = null;
    if (selected.some(selectedItem => !selectedItem.checked)) {
      modalRef = this.modalDialog.open(CommonSignAllDocsComponent, {...this._MODAL_CONFIG,
        windowClass: 'redefine-modal-lg'});
      modalRef.componentInstance.open({
        item: selected.map(i => {
          return {
            id: i.id,
            name: i.documentName,
            date: i.messageDate?.replace('00:00:00', ''),
            type: i.documentType.type,
            checked: i.checked,
            process: true
          };
        }),
        titleName: 'ЭДО 5861-У',
      });
    } else {
      modalRef = this.modalDialog.open(SignComponent, this._MODAL_CONFIG);
      modalRef.componentInstance.open({
        item: selected.map(i => {
          return {
            id: i.id,
            name: i.documentType.name,
            date: i.messageDate?.replace('00:00:00', ''),
            type: i.documentType.type,
            checked: i.checked,
            process: true
          };
        }),
        getHash: this.fmSrv.getHash.bind(this.fmSrv),
        sendSign: this.fmSrv.sendSign.bind(this.fmSrv),
        block: this.fmSrv.blockDocuments.bind(this.fmSrv),
        unblock: this.fmSrv.unBlockDocuments.bind(this.fmSrv),
      });
    }
    modalRef?.result.then((isSign) => {
      if (isSign) {
        this.importLoad();
        this.isSelectedAll = false;
      }
    });
  }

 /**
  * группировка списка по типам доккументов
  */
  groupByType(list) {
        return list.reduce((groups, item) => {
            const val = item.documentType.type;
            groups[val] = groups[val] || [];
            groups[val].push(item);
            return groups;
        }, {});
  }


  checkDocuments() {
    const selected = this.getSelectedModel(this.dataList) as Array<any>;
    const hasChecked = hasCheckedDoc(selected);
    const modalRef = this.modalDialog.open(CheckDocumentsComponent, this._MODAL_CONFIG);
    modalRef.componentInstance.open({
      item: selected.map(i => {
        return {
          id: i.id,
          docName: i.documentName,
          name: i.documentType.name,
          date: i.messageDate?.replace('00:00:00', ''),
          type: i.documentType.type,
          process: true
        }
      }),
    });
    modalRef.result.then((result) => {
      if (result || (hasChecked && !result)) {
        this.onPageChange({$refresh: Math.random()});
        this.isSelectedAll = false;
      }
    })
  }

  createDocument(docType: string, unitType = 'fm') {
     const config = this.fmSrv?.getConfigDoc(docType);
     this.router.navigate([`/${unitType}/newDocument/${config.url}`], { queryParams : {page: this.pageName} });
  }

  getConfig(docType) {
    return this.fmSrv?.getConfigDoc(docType);
  }
}


