import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconsService} from '@amlCore/services';
import {SaveFileService} from "../../../../core/services/save-file.service";
import {CommonService} from "@amlDocument/services";
import {GroupType} from "../../../documentForm";


@Component({
  selector: 'app-receive-documents',
  templateUrl: './sendDocuments.component.html',
  styleUrls: ['./sendDocuments.component.scss'],
})
export class SendDocumentsComponent implements OnInit {

  icons = this._iconsService.getIcons();
  data: any;
  disabled = false;
  success: boolean;
  loader = false;
  error = '';
  stopSended = false;
  sendProgress = false;

  preparedDocs = [];      // готовые к отправке
  sendedContainers = [];  // отправленные
  docsToSend = [];        // ждут отправку
  sendedDocs = [];        // уже отправленные
  sectionType = '';

  constructor(private _activeModal: NgbActiveModal,
              private _iconsService: IconsService,
              private commonSrv: CommonService,
              private saveFileService: SaveFileService) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this._activeModal.close(this.success);
  }

  open(item: any) {
    this.data = item.item;
    this.sectionType = item.section || '';
  }

  stopSend() {
    this.stopSended = true;
    this.sendProgress = false;
  }

  /* Отправка списка контейнеров, и скачивание документов по ID в зависимости от параметра download (true/false)*/
  sendDocuments(): void {
  this.disabled = true;
  this.loader = true;
  const listIds = this.data.map((item) => item.id);

  if (listIds.length === 0) {
    return;
  }

  const _iterate = this.iteratorObject(this.data);
  this.sendProgress = true;


  this.commonSrv.blockDocuments(listIds, this.sectionType).subscribe((block) => {

    this.commonSrv.sendDocuments(listIds, this.sectionType).subscribe(() => {

        if (this.stopSended) {
          this.stopSend();
          return;
        }

        const step = (iterate) => {
          const next = iterate.next();
          if (!next.done || !this.stopSended) {
            setTimeout(() => info(iterate), 1000)
          } else {
            this.sendProgress = false;
          }
        }

        const info = (iterate) => {
          // проверяем информацию по отправке
          this.commonSrv.sendDocumentsInfo(listIds, this.sectionType).pipe(
            ).subscribe((data) => {

              if (data.error !== null) {
                this.stopSend();
                this.error = data.error;
                // разблокировка всех при ошибке
                this.commonSrv.unBlockDocuments(listIds, this.sectionType).subscribe(() => {})
              }

              const IdsArr = data.containers;

              this.sendedDocs = data.sendedDocs;
              this.docsToSend = data.docsToSend;
              this.preparedDocs = data.preparedDocs;
              this.data.forEach((element: any) => {
                // ожидают отправки
                element.docsToSend = this.docsToSend?.includes(element.id);
                if (element.docsToSend)
                  return;
                // готовые к отправке
                element.preparedDocs = this.preparedDocs?.includes(element.id);
                if (element.preparedDocs)
                  return;
                // отправленные
                element.success = IdsArr.some(container => this.sendedDocs[container.id]?.includes(element.id));
                // разблокировка отправленного
                this.commonSrv.unBlockDocuments([element.id], this.sectionType).subscribe(() => {})
              });

              // если отправлен контейнер (completed=true)
              if (data.completed) {
                this.stopSend();
                // скачиваем все файлы по ID с сервера перебором
                IdsArr.map((idElem) => {
                  this.saveFileService.saveFiles(`/api/v1/documents/container/download/${idElem.id}`, idElem.name);

                  if (this.sectionType === GroupType.svetofor) {
                    const signatureFileName = idElem?.name.slice(0, idElem?.name.length - 3) + 'sig';

                    this.saveFileService.saveFiles(`/api/v1/documents/${this.sectionType}/container/download/${idElem.id}`, idElem?.name);
                    this.saveFileService.saveFiles(`/api/v1/documents/${this.sectionType}/container/download/signature/${idElem.signatureId}`, signatureFileName);
                  }
                });

                this.disabled = false;
                this.success = true;
                this.loader = false;
                this.commonSrv.unBlockDocuments(listIds, this.sectionType).subscribe(() => {});
              } else {
                step(iterate);
              }
            },
            err => {
              this.stopSended = true;
              this.error = err;
              this.disabled = false; this.success = true;this.loader = false;
              this.commonSrv.unBlockDocuments(listIds, this.sectionType).subscribe((result) => {
                console.log(result);
              });
            })
        }
        // информация по отправке
        info(_iterate)
    },
    (err) => {console.log(err);this.disabled = false;this.success = false; this.error = err,
      this.commonSrv.unBlockDocuments(listIds, this.sectionType).subscribe((result) => {
        console.log(result);
      });},
    () => { }
    )
  });

}

iteratorObject(array) {
  let nextIndex = 0;
  return {
    next: function (): any {
      return nextIndex < array.length ?
        {object: array[nextIndex++], done: false} :
        {done: true};
    }
  }
}
}

