import { Component, OnInit } from '@angular/core';
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {ITableItem} from "@amlCore/components";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../../../../../../documentForm";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {DictionariesService} from "../../../../../../../../../dictionaries";
import {filter, map, switchMap, takeUntil, tap} from "rxjs/operators";

@Component({
  selector: 'app-view-osnov-sov-oper-v1',
  templateUrl: './view-osnov-sov-oper-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewOsnovSovOperV1Component extends DocumentComponentBaseSub implements ITableItem, OnInit {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Основание совершения операции`;
  titleNaimDoc = 'Наименование документа';
  getDictionary = this.dictSrv.getQrefViddocosnov4936List().pipe(map(page => page.content));
  currentGetForm = (param) => this.docSrv.getOsnovanieOpV1(param, null, this.documentType);

  constructor(private readonly docSrv: DocumentFmV1Service, private dictSrv: DictionariesService) {
    super();
  }

  ngOnInit(): void {
    this.getDictionary.pipe(
        switchMap(dictionaries => this.field('kodDok')?.valueChanges.pipe(
            map(dictionariesCode => dictionaries.find(dictionary => dictionary?.code === dictionariesCode)),
            filter(currentDictionary => Boolean(currentDictionary?.name)),
            tap(currentDictionary => {
              this.field('naimDok')?.patchValue(currentDictionary?.name);
            }),
            takeUntil(this.componentDestroyed$)
        )),
    ).subscribe();
  }

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  onTextInput(e) {
    const textarea = e.target;
    textarea.style.height = '';
    textarea.style.height = Math.min(textarea.scrollHeight + 2, 200) + "px";
  }

  get isNaimDok() {
    return [DocumentTypeEnum.OPERATIONS_1_0,
      DocumentTypeEnum.REFUSE_OPERATION_1_1, DocumentTypeEnum.REFUSE_OPERATION_1_2,
      DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(this.documentType);
  }
}
