import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FmService } from '../../../arm/fm/service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from 'src/app/core/modals';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/arm/documentForm/services';

@Component({
  selector: 'app-gen-identifier-btn',
  templateUrl: './gen-identifier-btn.component.html',
  styleUrls: ['./gen-identifier-btn.component.css']
})
export class GenIdentifierBtnComponent implements OnInit, OnDestroy {
  @Input() marginTop = '29px';
  @Input() disabled = false;
  @Output() btnClick = new EventEmitter<void>();
  private destroy$ = new Subject();

  constructor(private fmService: FmService, private commonService: CommonService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  genIdentify() {
    const document = this.commonService.getDocument();
    const docType = document.documentType;
    const tipInf = document.form.get('sluzhChast')?.get('tipInf');
    const id = document.documentId;
    const inn = sessionStorage.getItem('orgInn');
    switch (docType) {
      case 'SD_KONFO_ECONOMIC_MEASURE':
        this.getSdKo(id, docType, inn);
        break;
      default:
        this.getDefaultId(tipInf, id, docType);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  getDefaultId(tipInf, id, docType) {
    if (!tipInf.value) {
      const modalRef = this.modalService.open(AlertModalComponent, {
        centered: true
      });
      const text = `<span class='text-danger'>Необходимо заполнить обязательное поле "Тип информации"</span>`
      modalRef.componentInstance.text = text;
    } else {
      this.fmService.genIdentifier(docType, tipInf.value ).pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.btnClick.emit(data);
      });
    }
  }

  getSdKo(id, docType, inn) {
    this.fmService.genIdentifier(docType, '', inn).pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.btnClick.emit(data);
    });
  }
}
