import {Component, Input, OnInit} from "@angular/core";
import {ITable} from "@amlCore/components";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewSvedTspComponent} from "./view-sved-tsp.component";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentTypeEnum} from "../../../enums";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
    selector: "app-arm-table-sved-tsp",
    templateUrl: "./table-sved-tsp.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"]
})
export class TableSvedTspComponent extends DocumentSubForm implements OnInit, ITable {
    readonly DocumentTypeEnum = DocumentTypeEnum;
    @Input() documentType = DocumentTypeEnum.SD_KONFO_ECONOMIC_MEASURE;
    viewForm = ViewSvedTspComponent;

    constructor(
        protected readonly modalService: NgbModal,
        private readonly docSrv: DocumentBaseService
    ) {
        super(modalService);
    }

    currentGetFormGroup = (data) => this.docSrv.getSvedTsP.call(this.docSrv, data);

    ngOnInit() {
        super.ngOnInit();
    }
    getIurLitso(item: any) {
        return item?.emitent?.naimEmitentTsP?.iurLitso;
    }
    getFioStroka(item: any) {
        return item?.emitent?.naimEmitentTsP.fizLitso?.fiostroka;
    }
    getFioFull(item: any) {
        return `${item?.emitent?.naimEmitentTsP.fizLitso?.imia || ''} ${item?.emitent?.naimEmitentTsP?.fizLitso?.fam || ''} ${item?.emitent?.naimEmitentTsP?.fizLitso?.otch || ''}`;
    }
    isDocument6670() {
        return this.documentType === DocumentTypeEnum.SD_KONFO_ECONOMIC_MEASURE;
    }
}
