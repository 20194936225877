import {DocumentComponentBase} from "./documentComponentBase";
import {ActivatedRoute, Router} from "@angular/router";
import {IconsService} from "@amlCore/services";
import {OnDestroy, OnInit} from "@angular/core";
import {DocumentSubForm} from "./documentSubForm";
import {BehaviorSubject, Subject} from "rxjs";
import {first, skipWhile, switchMap, takeUntil} from "rxjs/operators";
import {DocumentTypeEnum, FM_DOCS_1_X, OPERATIONS_1_X, STRATEG655_3732} from "../enums";
import {Tab} from "../types";
import {IDocumentComponent} from "../model";
import {CommonService, DocumentBaseService} from "../services";
import {DocumentFmV1Service} from "../../fm/service/document-fm-v1.service";

/**
 * Базовый класс для всех документов
 * * save() - сохранение документа
 * * loadDocument() - загрузка документа
 */
export abstract class DocumentBase extends DocumentComponentBase implements IDocumentComponent, OnInit, OnDestroy {
  /**
   * Тип документа
   */
  abstract documentType: DocumentTypeEnum;
  /**
   * id документа
   */
  documentId: string; // todo сделать абстрактный
  /**
   * Данные по документу
   */
  documentData: any;  // todo сделать абстрактный

  activeTab: Tab = 'document';
  subActiveType: Tab = 'soobInfo';
  destroy$ = new Subject();

  /**
   * Загрузка документа происходит только
   * после его разблокировки
   */
  documentIsUnblocked$ = new BehaviorSubject(false);

  /**
   * Загрузка документа происходит только
   * после его разблокировки
   */
  private versiiaFormataSubject$ = new Subject<string>();
  public versiiaFormataValue = this.versiiaFormataSubject$.asObservable();

  /**
   * Для подмены текста контролов
   */
  replaceName: {[key: string]: string} = {};
  protected constructor(
    protected documentService: DocumentBaseService,
    protected iconsService: IconsService,
    protected commonSrv: CommonService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected documentFmService?: DocumentFmV1Service,
  ) {
    super(iconsService);
    this.setReadOnly();
  }

  get d(): any {
    return this.documentData?.document;
  }

  private get isArm4937() {
    return this.documentType === DocumentTypeEnum.NFO_OPERATIONS
      || this.documentType === DocumentTypeEnum.NFO_VERIFICATION_RESULT
      || this.documentType === DocumentTypeEnum.NFO_FREEZING_FUNDS
      || this.documentType === DocumentTypeEnum.NFO_OBSTRUCTION_FACTS
      || this.documentType === DocumentTypeEnum.NFO_REFUSE_OPERATION
      || this.documentType === DocumentTypeEnum.NFO_SUSPICIOUS_ACTIVITY;
  }

  private get isOesOtkazGoz() {
    return this.documentType === DocumentTypeEnum.OES_OTKAZ_GOZ;
  }

  private get isFmVersion1x() {
    return FM_DOCS_1_X.includes(this.documentType);
  }

  private get isStrateg655(): boolean {
    return STRATEG655_3732.includes(this.documentType);
  }

  /**
   * Заголовок документа
   */
  // abstract getTitle(): string;
  getTitle(): string {
    return "";
  }

  changeTab(type: Tab, subType?: Tab): void {
    this.activeTab = type;
    subType ? this.subActiveType = subType : null;
  }

  isSelectedParent(parent: DocumentSubForm): boolean {
    return parent.selectedIndex !== null
      ? parent.isReadOnly
      : true;
  }

  ngOnInit(): void {
    this.setDocumentId();
    this.loadDocument();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Метод обработки получения данных с сервера
   */
  loadDocument(): void {
    if (this.documentId) {
      this.documentIsUnblocked$.pipe(
        skipWhile(value => {
          return !value
        }),
        first(),
        switchMap(() => {
          return this.commonSrv.getDocInfoById(this.documentId, this.documentType)
        }),
        takeUntil(this.destroy$)
      )
        .subscribe(data => {
          this.documentData = data;
          this.prepareForm();
        });
    } else {
      this.documentData = this.prepareInitDocumentData()
      this.prepareForm();
      this.form.markAsDirty();
    }
  }

  private prepareForm(): void{
    // определяем форму для разных типов документов (в последствии расширить)
    if (this.isFmVersion1x) {
      this.versiiaFormataSubject$.next(this.documentData?.document?.sluzhChast?.versiiaFormata)
    }

    if (this.isArm4937) {
      this.form = this.documentService.getInitialDocumentFormArm4937(this.d);
    } else if (this.isOesOtkazGoz) {
      this.form = null;
    } else if (this.isFmVersion1x) {
      this.form = this.documentFmService.getInitialDocumentFormFm(this.d, [], this.documentType);
    } else if (this.isStrateg655) {
      this.form = this.documentService.getInitialDocumentFormStrateg655(this.d, [], this.documentType);
    } else {
      this.form = this.documentService.getInitialDocumentFormFm(this.d, [], this.documentType);
    }
    this.initForm();
    this.disableForm();
  }

  /**
   * Подготовка начальных данных для формы создания документа
   */
  prepareInitDocumentData(): object{
    return this.commonSrv.prepareInitDocumentData(this.documentType);
  }

  setReadOnly(readOnly?: boolean) {
    this.isReadOnly = readOnly || this.activatedRoute.parent.snapshot.queryParams.page !== 'drafts';
  }

  setDocumentId() {
    this.documentId = this.activatedRoute.parent.snapshot.params.id;
  }
}
