import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable, ITableCallBack} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../enums";
import {ViewMainObstructionFactComponent} from "./view-main-obstruction-fact.component";

@Component({
  selector: 'app-arm-doc-table-main-obstruction-fact',
  templateUrl: './table-main-obstruction-fact.component.html',
  styleUrls: ['../../../base/documentForm.component.scss'],
})
export class TableMainObstructionFactComponent extends DocumentSubForm implements OnInit, ITable {
  @Output() confirmWithoutChanges = new EventEmitter<{isConfirmBack: boolean | undefined, documentData: any[]}>();
  documentType = DocumentTypeEnum.OBSTRUCTION_FACTS; // todo
  viewForm = ViewMainObstructionFactComponent;
  pageSize = 10;
  page = 0;
  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentBaseService) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getFaktVosprepiat.call(this.docSrv, data);

  ngOnInit() {
    super.ngOnInit();
  }

  onPageChange(e) {}

  callbackItemClosed(event: ITableCallBack): void {
    super.callbackItemClosed(event);
    if (!event.isConfirmBack) {
      this.documentData = this.controlFormGroupApp.value.map((item) => {
        return {
          dataVyiavleniia: `${item?.dataVyiavleniia}`,
          formaVosprepiatstvovaniia: item?.formaVosprepiatstvovaniia,
          kodOKSM: `${item?.kodOKSM}`,
          komment: item?.komment || null,
          nomerZapisi: `${item?.nomerZapisi}`,
          svedIdentifikatsiia: `${item?.svedIdentifikatsiia}`,
          tipZapisi: `${item?.tipZapisi}`,
          zakonodatelstvoRF: item?.zakonodatelstvoRF
        };
      })
      this.documentData = this.documentData.filter(item => item.zakonodatelstvoRF?.length);
    }
    this.confirmWithoutChanges.emit({isConfirmBack: event.isConfirmBack, documentData: this.documentData});
  }
}
