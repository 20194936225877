import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ITable, ITableCallBack} from 'src/app/core';
import {ViewFreezeInfoCreditOrg} from '../views';
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";

@Component({
  selector: 'app-armfm-inf-freeze-table-info-org-v1',
  templateUrl: './table-if-info-credit-org.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableIfInfoCreditOrg
  extends DocumentSubForm
  implements OnInit, ITable
{
  @Output() confirmWithoutChanges = new EventEmitter<{isConfirmBack: boolean | undefined, documentData: any[]}>();
  documentType = DocumentTypeEnum.FREEZING_FUNDS;
  viewForm = ViewFreezeInfoCreditOrg;
  keyData = 'mery';
  total = 0;
  pageSize = 10;
  page = 0;
  constructor(
    protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService
  ) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getSvedKoFreeze.call(this.docSrv, data);
  ngOnInit(): void {
    super.ngOnInit();
  }
  onPageChange(e) {}

  callbackItemClosed(event: ITableCallBack): void {
    super.callbackItemClosed(event);
    if (!event.isConfirmBack) {
      this.documentData = this.controlFormGroupApp.value.map((item) => {
        return {priznakPredstavSved: `${item?.priznakPredstavSved}`, infFilial: item?.infFilial || null, mery: item?.mery};
      })
      this.documentData = this.documentData.filter(item => item.mery.length);
    }
    this.confirmWithoutChanges.emit({isConfirmBack: event.isConfirmBack, documentData: this.documentData});
  }
}
