import {Injectable} from "@angular/core";
import {ApiService, UserService} from "@amlCore/services";
import {IDataResponse} from "@amlCore/interfaces";
import {BehaviorSubject, Observable} from "rxjs";
import moment from "moment";
import {DocumentConfig, DocumentTypeEnum, getDocumentConfig, getGroupType, GroupType} from "../enums";
import { CommonList } from "../model";
import {ReceiveDocResponseModel} from "../../fm";
import {OrganizationModel} from "@amlCore/models";

@Injectable()
export class CommonService {
    constructor(private api: ApiService, protected userSrv: UserService) {}

    private _docIds: string[] = [];

    versiiaFormata = "2.1";
    versProg = "ARM FM";
    orgInfoObs = this.userSrv.orgInfo;


    /**
     * Используется для формирования идентификатора (пока для fm) / не менять
    */

    private documentSubject = new BehaviorSubject(null);

    setDocument(document) {
        this.documentSubject.next(document);
      }

      getDocument() {
        return this.documentSubject.value;
      }
    /**


    /**
     * Подготовка начальных данных для формы создания нового документа
     */
    prepareInitDocumentData(documentType: DocumentTypeEnum): any {
        const orgInfo = this.userSrv.getOrgInfo();
        const configuration = getDocumentConfig(documentType);

        switch (documentType) {
            case DocumentTypeEnum.OES_OTKAZ_GOZ:
                return {
                    document: this._getDocumentDataOesOtkazGoz(
                        orgInfo,
                        configuration
                    ),
                };
            case DocumentTypeEnum.SD_KONFO_ECONOMIC_MEASURE:
                return {
                    document: this._getDocumentDataControl6670(orgInfo)
                }

            case DocumentTypeEnum.SD_KO_SECURITIES:
              return {
                  document: this._getDocumentDataControlStrateg655p(orgInfo)
              }

            case DocumentTypeEnum.SD_KO_OTKR_ZAKR_CHET_DEPO:
              return {
                  document: this._getDocumentDataControlStrateg655p(orgInfo)
              }
            case DocumentTypeEnum.SD_KO_IZM_CHET_DEPO:
              return {
                  document: this._getDocumentDataControlStrateg655p(orgInfo)
              }

            default:
                return {
                    document: this._getDocumentDataFm(orgInfo, configuration),
                };
        }
    }


    /**
     * Сохранение id документа в сервис
    */
    saveDocIds(id: string[]): void {
        this._docIds = id
    }

    /**
     * Получение id документа их сервиса
     */
    getDocIds(): string[] {
        return this._docIds
    }

    /**
     * Добавление id документа в стор
    */
    setDocIdStorage(documentId: string) : void {
        window.sessionStorage.setItem('documentId', documentId)
    }

    /**
     * Удаление id документа из стора
     */
    removeDocIdStorage() : void {
        window.sessionStorage.removeItem('documentId')
    }

    /**
     * Получение токена для предотвращения отправки лишних запросов
     */
    getToken(): string {
        return window.sessionStorage.getItem('Auth-Token') || ''
    }

    // получить данные по документу
    getDocInfoById(id, type) {
        const config = getDocumentConfig(type);
        return this.api.get(config.api + id);
    }

    // для подписи документов
    getHash(id, type, oid) {
        return this.api.get('/api/v1/documents/sign/dateForSign', {documentIds: id, certOid: oid}, null, 'text');
    }

    // для подписи документов
    sendSign(id, type, signature, token?: string) {
        return token ? this.api.post(`api/v1/documents/sign/${id}`, {signature, token})
            : this.api.post(`api/v1/documents/sign/${id}`, {signature});
    }

    /**
     * Блокировка документов
     * @param type
     * @param ids
     */
    blockDocuments(ids: string[], group?: string) {
        const url = group === 'rejection'
                    ?  `${DocumentConfig.REGISTRY_REJECTION.api}block`
                    : group ? `/api/v1/documents/${group}/block`
                    : `/api/v1/documents/block`;
        return this.api.post(url, {documents: ids})
    }

    /**
     * Разблокировка документов
     * @param type
     * @param ids
     */
    unBlockDocuments(ids?: string[], group?: string) {
        const sentIds = ids?.length ? ids : this._docIds;
        const url = group === 'rejection'
                    ? `${DocumentConfig.REGISTRY_REJECTION.api}unblock`
                    : group ? `/api/v1/documents/${group}/unblock`
                    : `/api/v1/documents/unblock`;
        return this.api.post(url, {documents: sentIds});
    }

    /**
     * Отправка файла
     */
    sendDocuments(ids: string[], type: string): Observable<any> {
      const sendUrl = this.getSendUrl(type);
      return  (this.api.post(sendUrl, {
          documents: ids,
      }));
    }

    /**
     *  Информация по отправке
     */
    sendDocumentsInfo(ids: string[], type: string): Observable<any> {
      const sendUrl = this.getSendUrl(type) + '/info';
      return this.api.post(sendUrl);
    }

    /**
     *  проверка документов
     */
    checkDocuments(ids: string[], group?: string): Observable<any> {
        return group
            ? this.api.post(`/api/v1/documents/${group}/check`, {
                  documents: ids,
              })
            : this.api.post(`/api/v1/documents/check`, { documents: ids });
    }

    /**
     *  проверка вложения
     */
    checkAttachment(id: string): Observable<any> {
        return this.api.get(`/api/v1/documents/p407answer/attachments/check/${id}`);
    }

    /**
     *  экспорт результатов проверки документов
     */
    exportCheckDocuments(params: any) {
        return this.api.byte(`/api/v1/documents/check/export`, params);
    }

    /**
     *  экспорт результатов проверки вложения
     */
    exportCheckAttachment(id: string, params: any) {
        return this.api.byte(`/api/v1/documents/p407answer/attachments/check/export/${id}`, params); 
    }

    /**
     * Создать документ
     */
    createDocument(documentType: DocumentTypeEnum, needAttention: boolean, document): Observable<any> {
        const typeConf = getDocumentConfig(documentType);
        return this.api.post(`${typeConf.api}edit/new${needAttention?'/'+needAttention:''}`, document);
    }

    /**
     * Сохранить документ
     * @param documentType тип документа
     * @param documentId id документа
     * @param needAttention значение поля "Требует особого внимания" в документе
     * @param document value формы документа
     */
    saveDocument(documentType: DocumentTypeEnum, documentId: string, needAttention: boolean, document): Observable<any> {
        const typeConf = getDocumentConfig(documentType);
        return this.api.post(`${typeConf.api}edit/${documentId}${needAttention?'/'+needAttention:''}`, document);
    }

    /**
     * Удалить документ
     * @param documentType тип документа
     * @param documentId id документа
     */
    deleteDocument(documentType: DocumentTypeEnum, documentId: string): Observable<any> {
        const typeConf = getDocumentConfig(documentType);
        if (documentType == 'OES_OTKAZ_GOZ') {
            return this.api.delete(`${typeConf.api}${documentId}`);
        }
        return this.api.delete(`/api/v1/documents/${typeConf.serviceName}/${documentId}`);
    }

    /**
     * Удалить несколько документов
     * @param documentType тип документа
     * @param documentIds массив id документов
     */
    deleteDocuments(documentType: DocumentTypeEnum, documentIds: Array<string>): Observable<any> {
        const typeConf = getDocumentConfig(documentType);
        return this.api.deleteArray(`${typeConf.api}`, documentIds);
    }

    /**
     * Переместить в Удаленные
     * @param documentIds массив id документов
     * @param group переданная группа
     */
    moveToDeleted(documentIds: Array<string>, group: string = ''): Observable<any> {
        const currentGroup = getGroupType(group);
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/moveToDeleted`,  documentIds)
                            : this.api.post(`/api/v1/documents/moveToDeleted`,  documentIds);
    }

    /**
     * Переместить в Готовые к отправке
     * @param documentIds массив id документов
     * @param group переданная группа
     */
    moveToSend(documentIds: Array<string>, group: string = '') {
        const currentGroup = getGroupType(group);
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/draft/moveToSend`, documentIds) :
            this.api.post(`/api/v1/documents/draft/moveToSend`, documentIds);
    }

    /**
     * Переместить из раздела Удаленные в Черновики
     * @param group группа из url
     * @param fromDirectory из какой директории возвращаем в черновики
     * @param documentIds массив id документов
     */
    deletedToDrafts(documentIds: Array<String>, group: string = '', fromDirectory: string = 'deleted'): Observable<any> {
        const currentGroup = getGroupType(group);
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/${fromDirectory}/moveToDraft`, documentIds)
            : this.api.post(`/api/v1/documents/${fromDirectory}/moveToDraft`, documentIds);
    }

    /**
     * Получение списка удалённых документов
     * @param group название раздела/группы, откуда поступает запрос
     * @param params параметры для фильтрации
     * @returns
     */
    getDeletedList(group, params ): Observable<any> {
        const currentGroup = getGroupType(group)
        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        if (group === 'rejection') {
            return this.api.post(`/api/v1/documents/registryRejection/removed/`, params);
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/deleted/`, params)
                            : this.api.post(`/api/v1/documents/deleted/`, params);
    }

    /**
     * Получение списка ахивированных документов
     * @param params
     * @returns
     */
    getArchiveList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/archive`, params)
        : this.api.post(`/api/v1/documents/archive/`, params);

    }

    /**
     * Получение списка готовых к отправке документов
     * @param group - группа документа
     * @param params - параметры для поиска
     */
    getSendReadyList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/wait/`, params)
            : this.api.post(`/api/v1/documents/wait/`, params);

    }

    /**
     * Получение списка черновиков
     * @param group - группа документа
     * @param params - параметры для поиска
     */
    getDraftList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/draft/`, params)
            : this.api.post(`/api/v1/documents/draft/`, params);

    }

    /**
     * Получение списка отправленных
     * @param group - группа документа
     * @param params - параметры для поиска
     */
    getSendedList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/sended/`, params)
            : this.api.post(`/api/v1/documents/sended/`, params);

    }

    /**
     * Запрос на перемещение документов в папку Архив
     * @param documentIds id документов, которые нужно перенести в папку Архив
     * @param group группа
     * @returns
     */
    moveToArchive(documentIds: Array<String>, group = ''): Observable<any> {
        return group ? this.api.post(`/api/v1/documents/${group}/archive/moveToArchive`, documentIds)
            : this.api.post(`/api/v1/documents/archive/moveToArchive`, documentIds)
    }

    /**
     * Запрос на создании копии документа
     * @param documentId id документа, копию которого нужно создать
     * @param group название сервиса из Фм, к которому относится документ (verificationResult, freezing и т.д.)
     * @returns
     */
    createCopyDocuments(documentId: string, group: DocumentTypeEnum): Observable<any> {
        const config = getDocumentConfig(group);
        return this.api.get(`${config.api}copy/${documentId}`);
    }

    /**
     * Копирование документа в папке Архив
     * @param documentId  id документа
     * @param group группа
     * @returns
     */

    copyArchiveDoc(group = '', documentId: string): Observable<any> {
        const currentGroup = getGroupType(group);
        // ToDo временно стоит костыль для плавного перехода с fm='' на fm='sko115fz'
        if (group === 'fm') {
            return this.api.get(`/api/v1/documents/sko115fz/archive/copy/${documentId}`)
        }
        return currentGroup ? this.api.get(`/api/v1/documents/${currentGroup}/archive/copy/${documentId}`) :
            this.api.get(`/api/v1/documents/archive/copy/${documentId}`)
    }

    /**
     * Копирование документа в папке Отправленные
     * @param documentId  id документа
     * @returns
     */

    copySended5392Doc(documentId: string): Observable<any> {
        return this.api.get(`/api/v1/documents/oes/otkaz/goz/copy/${documentId}`)
    }

    private _getDocumentDataOesOtkazGoz(orgInfo, configuration) {
        return {
            sluzhChastKO: {
                dataSoob: moment().format("DD/MM/YYYY"),
                tipInf: configuration?.tipInf[0],
                versProg: "Comita AML 1.0",
                versForm: '1.2',
                idkorr: orgInfo?.idLk,
                bikkonaprv: orgInfo?.code,
            },
        };
    }

    private _getDocumentDataControl6670(orgInfo) {
        return {
            sluzhChast: {
                dataSoobshcheniia: moment().format("DD/MM/YYYY"),
                versProg: "Comita AML 1.0",
                versiiaFormata: '1.0',
                bikkonaprv: orgInfo?.code,
            },
            informChast: {
                infOrganizatsiia: {
                    innorganizatsiia: orgInfo?.tin,
                }
            }
        };
    }

    private _getDocumentDataControlStrateg655p(orgInfo) {
      return {
          sluzhChast: {
              dataSoob: moment().format("DD/MM/YYYY"),
              versProg: "ARMStrategKO",
              versForm: '1.2',
              bikkonaprv: orgInfo?.code,
              tipInf: 'ИНФРФМ'
          },
          informChast: {
            svedKO: {}
          }
      };
  }

    private _getDocumentDataFm(orgInfo, configuration) {
        return {
            informChast: {
                infBank: {
                    regNomKO: orgInfo?.regNumber,
                    bikko: orgInfo?.code,
                    nomFlPeredaiushchij: orgInfo?.filial,
                    okatoko: orgInfo?.oktmo?.substring(0, 2),
                },
            },
            sluzhChast: {
                versiiaFormata: this.versiiaFormata,
                versProg: this.versProg,
                tipInf: configuration?.tipInf[0],
                idkorr: orgInfo?.idLk,
                dataSoobshcheniia: moment().format("DD/MM/YYYY"),
            },
        };
    }

  getRecordsByDocId(documentId, group?: string): Observable<any> {
    let currentGroup = getGroupType(group);
    switch (group) {
      case 'fm' : {
        currentGroup = 'sko115fz';
        break;
      }
    }
    return this.api.get(`/api/v1/documents/${currentGroup}/recordsInfo/${documentId}`);
  }

  changeRecords(params, group): Observable<any> {
    const currentGroup = getGroupType(group);
    // ToDo временно стоит костыль для плавного перехода с fm='' на fm='sko115fz'
    if (group === 'fm') {
        return this.api.post(`/api/v1/documents/sko115fz/changeRecords`, params)
    }
    return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/changeRecords`, params) :
        this.api.post(`/api/v1/documents/changeRecords`, params)
   }

    /**
     * Отменить отправку
     * @param group - группа документа
     * @param documentIds - массив с идентификаторами документов, которые необходимо вернуть в черновики
     */
    cancelSend(documentIds, group) {
        const currentGroup = getGroupType(group);
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/sended/moveToDraft`, documentIds) :
                this.api.post(`/api/v1/documents/sended/moveToDraft`, documentIds);
    }

    /**
     * Формирование отчёта о результатах получения
     */
    reportSendedFromReceiveList(docsResultList: ReceiveDocResponseModel[], group: string,  params?: string) {
        let url = group ? `/api/v1/documents/${group}/sended/receive/download` : '/api/v1/documents/sended/receive/download';
        if (params) {
            url += `?fileName=${encodeURIComponent(params)}`;
        }
        return this.api.byte(url, docsResultList);
    }
    getOrgInfo() {
        return this.userSrv.getOrgInfo();
    }

    /**
     * Получение root url для скачивания по ссылке
     */
    getRootTypeUrl(type) {
        return `/api/v1/documents/${getDocumentConfig(DocumentTypeEnum[type])?.serviceName}`;
    }

    // todo в последствии переделать на switch - либо вообще убрать этото метод и оставить -
    //  "this.getConfig(document.documentType.type).api + 'container/download/' + document?.id;"
    getUrlToDownloadContainer(document: CommonList) {
      if (document?.documentType?.type === 'P407_ANSWER' ||
          document?.documentType?.type === 'P407_REQUESTS' ||
          document?.documentType?.type === 'OES_OTKAZ_GOZ'  ||
          document?.documentType?.type === 'SD_KONFO_ECONOMIC_MEASURE') {
          return getDocumentConfig(document.documentType.type).api + 'container/download/' + document?.container?.id;
      }
      return '/api/v1/documents/container/download/' + document?.container?.id;
    }

    getSendUrl(type: string): string {
        switch (type) {
            case 'oes/otkaz/goz':
                return `/api/v1/documents/oes/otkaz/goz/send`;
            case 'OES_OTKAZ_GOZ':
                return `/api/v1/documents/oes/otkaz/goz/send`;
            case 'sd/konfo/economic-measure':
                return `/api/v1/documents/sd/konfo/economic-measure/send`;
            case '':
                return `/api/v1/documents/wait/send`;
            default:
                return `/api/v1/documents/${type}/wait/send`;
        }
    }
}
