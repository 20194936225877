import {Component, OnInit, ChangeDetectorRef, Input} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import {BaseFilterController} from "../../BaseFilterController";
import {DocTypeSearch, getDocStatuses, getDocTypes} from "../../../constants";

@Component({
  selector: "app-elMsgFm-draft-list-filter",
  templateUrl: "./elMsgFmDraftListFilter.component.html",
})

export class ElMsgFmDraftListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Номер запроса, имя xml-документа';
  @Input() statuses: 'created' | 'deleted' = 'created'
  docStatuses: DocTypeSearch[] = []
  constructor(private readonly fb: FormBuilder,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.docStatuses = getDocStatuses(this.statuses)
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Документ',
      documentStatus: 'Статус',
      dateFrom: status === 'created' ? 'Дата создания с' : 'Дата документа с',
      dateTo: status === 'created' ? 'Дата создания по' : 'Дата документа по',
      creatorId: 'Владелец документа',
    };
  }
  docTypes = getDocTypes('elMsgFm');

  get users() {
    return this.dictSrv?.remoteDict?.usersSvetofor?.list || [];
  }
  
  get textLabel() {
    return status === 'created' ? 'Дата создания с' : 'Дата документа с'
  }
}
