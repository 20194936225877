import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentTypeEnum} from "../../../../../../../../documentForm";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {ViewOsnovSovOperV1Component} from "../../view/view-osnov-sov-oper-v1/view-osnov-sov-oper-v1.component";

@Component({
  selector: 'app-table-osnov-sov-oper-v1',
  templateUrl: './table-osnov-sov-oper-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableOsnovSovOperV1Component extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() documentType: DocumentTypeEnum;
  viewForm = ViewOsnovSovOperV1Component;

  constructor(
      protected readonly modalService: NgbModal,
      private readonly docSrv: DocumentFmV1Service
  ) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getOsnovanieOpV1.call(this.docSrv, data, null, this.documentType);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }

  get isNaimDok() {
    return [DocumentTypeEnum.OPERATIONS_1_0,
      DocumentTypeEnum.REFUSE_OPERATION_1_1,
      DocumentTypeEnum.REFUSE_OPERATION_1_2,
      DocumentTypeEnum.OPERATIONS_1_1,
      DocumentTypeEnum.OPERATIONS_1_2].includes(this.documentType);
  }
}
