import {Injectable} from '@angular/core';
import {DocumentBaseForm, DocumentBaseService} from "@amlDocument/services";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ValidatorService} from "@amlCore/services";
import {DocumentTypeEnum, FormGroupGetterFn, OPERATIONS_1_X, REFUSE_CONTRACT_1_X, REFUSE_OPERATION_1_X} from "../../documentForm";
import {Utils} from '../../../core/utils';

@Injectable({
  providedIn: 'root'
})
export class DocumentFmV1Service extends DocumentBaseForm{

  constructor(protected fb: FormBuilder,
              protected validationSrv: ValidatorService,
              protected baseFormService: DocumentBaseService) {
    super(fb, validationSrv);
  }

  // Переопределение служебной части

  getInitialDocumentFormFm: FormGroupGetterFn = (data, valid?, docType?) => {
    const initialForm = this.fb.group({
      informChast: this.getInformChast(data?.informChast),
      sluzhChast: this.getSluzhChast(data?.sluzhChast, [], docType)
    }, valid);
    if (![DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(docType)) {
      (initialForm.get('sluzhChast').get('fioupolnSotrudn') as FormGroup).get('fiostroka').disable();
    }

    if ([DocumentTypeEnum.REFUSE_OPERATION_1_1, DocumentTypeEnum.REFUSE_OPERATION_1_2].includes(docType)) {
      (initialForm.get('sluzhChast').get('idkorr')).disable();
      (initialForm.get('sluzhChast') as FormGroup).addControl('bikkonaprv',
          this.fbControl(data?.sluzhChast?.bikkonaprv, this.valid({isBIKKO: true, isReq: true})));
    }
    return initialForm;
  }
  getOperationDetailsV1Form(data: any, valid?, docType?): FormArray {
    return this.fbArray(this.getOperationDetailsV1, data, null,  this.valid({ isReq: true }), docType);
  }

  getOperationDetailsV1(data: any, valid?, docType?): FormGroup {
    return this.fb.group({
      infFilial: this.getInfFilial(data?.infFilial, valid, docType),
      operatsiia: this.fbArray(this.getOperatsiiaFmV1, data?.operatsiia, null, this.valid({ isReq: true }), docType),
      priznakPredstavSved: [data?.priznakPredstavSved ?? 0, this.valid({ isReq: true })],
    });
  }

  getOperatsiiaFmV1(data: any,  valid?, documentType?): FormGroup {
    return this.fb.group({
      dataOperatsii: [data?.dataOperatsii, this.valid({ isDate: true, isReq: true })],
      dataVyiavleniia: [data?.dataVyiavleniia, this.valid({ isDate: true, isReq: false })],
      dopKodOperatsii: [data?.dopKodOperatsii],
      kharakterOp: [data?.kharakterOp ?? '', this.valid({ max: 2000 })],
      kodOperatsii: [data?.kodOperatsii, this.valid({ isReq: true, max: 4 })],
      kodPriznOperatsii: [data?.kodPriznOperatsii, this.valid({ isReq: true, max: 1 })],
      kodVal: [data?.kodVal, this.valid({ isReq: true, max: 3 })],
      kodValKonversiia: [data?.kodValKonversiia, this.valid({ max: 3 })],
      komment: [data?.komment, this.valid({ max: 2000 })],
      naimPlatezhnaiaSistema1: [data?.naimPlatezhnaiaSistema1, this.valid({ max: 100 })],
      naimPlatezhnaiaSistema2: [data?.naimPlatezhnaiaSistema2, this.valid({ max: 100 })],
      naznacheniePlatezha: [data?.naznacheniePlatezha ?? '', this.valid({ max: 2000 })],
      nomerOperatsiia: [data?.nomerOperatsiia, this.valid({ max: 100 })],
      nomerZapisi: [data?.nomerZapisi, this.valid({ isReq: true, pattern: '([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})'})],
      osnovanieOp: this.fbArray(this.getOsnovanieOpV1, data?.osnovanieOp, null, null, documentType),
      priznFTr: [data?.priznFTr, [...this.valid({ isReq: true}), this.validationSrv.arrayItemLengthValidator()]],
      priznNeobOperatsii: [data?.priznNeobOperatsii],
      priznakOperatsiiaESP: [data?.priznakOperatsiiaESP, this.valid({ max: 1 })],
      sumKonversiia: [data?.sumKonversiia, this.valid({ pattern: /^[0-9]{1,20}\.[0-9]{2}$/ })],
      sumOperatsii: [data?.sumOperatsii, this.valid({ isReq: true, pattern: /^[0-9]{1,20}\.[0-9]{2}$/ })],
      sumRub: [data?.sumRub, this.valid({ isReq: true, pattern: /^[0-9]{1,20}\.[0-9]{2}$/ })],
      svedDragMetall: this.fbArray(this.getSvedDragMetallV1, data?.svedDragMetall),
      svedeniiaKartaInBank: this.getSvedeniiaKartaInBankV1(data?.svedeniiaKartaInBank, null, documentType),
      svedeniiaPerevodyDS: this.getSvedeniiaPerevodyDSV1(data?.svedeniiaPerevodyDS, null, documentType),
      svedeniiaVneseniePoluchenieNalDS: this.getSvedeniiaVneseniePoluchenieNalDSV1(data?.svedeniiaVneseniePoluchenieNalDS, null, documentType),
      tipZapisi: [data?.tipZapisi, this.valid({ isReq: true, max: 1 })],
      uchastnikOp: this.fbArray(this.getUchastnikOpFmV1, data?.uchastnikOp, null, this.valid({ isReq: true }), documentType),
      vremiaTranzaktsiiaESP: [data?.vremiaTranzaktsiiaESP, this.valid({  pattern:/[0-9]{2}:[0-9]{2}:[0-9]{2} \((м|М)(с|С)(к|К)(\+|-)[0-9]{2}:[0-9]{2}\)/ }) ],
    });
  }

  getOsnovanieOpV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    const osnovanieOp = this.fb.group({
      naimDok: [data?.naimDok, this.valid({isReq: true, max: 250})],
      dataDok: [data?.dataDok, this.valid({ isDate: true })],
      inoeNaimDok: [data?.inoeNaimDok, this.valid({ max: 250 })],
      kodDok: [data?.kodDok, this.valid({ isReq: true, max: 2 })],
      nomDok: [data?.nomDok, this.valid({ max: 100 })],
      sodDok: [data?.sodDok, this.valid({ isReq: !([DocumentTypeEnum.REFUSE_OPERATION_1_2, DocumentTypeEnum.REFUSE_OPERATION_1_1,
          DocumentTypeEnum.OPERATIONS_1_0, DocumentTypeEnum.OPERATIONS_1_2,
          DocumentTypeEnum.OPERATIONS_1_1].includes(documentType)), max: 2000 })]
    }, valid);

    return osnovanieOp
  }

  getSvedDragMetallV1: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      kodDragMetall: [data?.kodDragMetall, this.valid({isReq: true})],
      naimDragMetall: [data?.naimDragMetall, this.valid({ max: 250 })],
    }, valid);
  }

  private getSvedeniiaKartaInBankV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fbGroup(data, {
      kodTerInBank: [data?.kodTerInBank, this.valid({ isReq: true, max: 500 })],
      svedMestoOperatsiia: this.getSvedNalDSV1(data?.svedMestoOperatsiia, null, documentType),
      nomerKarta: [data?.nomerKarta, this.valid({ isReq: true, max: 40 })],
      svedDerzhatelKarty: [data?.svedDerzhatelKarty, this.valid({ isReq: true, max: 2000 })],
      priznakSotrudnik: [data?.priznakSotrudnik ?? 0, this.valid({ isReq: true, max: 1 })],
      naimInBank: [data?.naimInBank, this.valid({ isReq: true, max: 500 })],
      sviftinBank: [data?.sviftinBank, this.valid({ isSwift2or8or11: true })]
    }, valid);
  }

  private getSvedeniiaPerevodyDSV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    const form = this.fbGroup(data, {
      adresIP: [data?.adresIP, this.valid({ max: 40 })],
      adresMAC: [data?.adresMAC, this.valid({ max: 23 })],
      identESPPlatelshchik: this.fbArray(this.fbControl, data?.identESPPlatelshchik, this.valid('identESPPlatelshchik')),
      identESPPoluchatelia: this.fbArray(this.fbControl, data?.identESPPoluchatelia, this.valid('identESPPoluchatelia')),
      kodTerInGos: [data?.kodTerInGos, this.valid({ max: 500 })],
      nomerSchetPlatelshchik: [data?.nomerSchetPlatelshchik, this.valid({ max: 40 })],
      nomerSchetPoluchatel: [data?.nomerSchetPoluchatel, this.valid({ max: 40 })],
      schetBankPlatelshchik: [data?.schetBankPlatelshchik, this.valid({ max: 40 })],
      schetBankPoluchatel: [data?.schetBankPoluchatel, this.valid({ max: 40 })],
      statusPerevod: [data?.statusPerevod, this.valid({ max: 1 })],
      svedBankPlatelshchik: this.getSvedBankV1(data?.svedBankPlatelshchik),
      svedBankPoluchatel: this.getSvedBankV1(data?.svedBankPoluchatel),
      svedPriemNalDS: this.getSvedNalDSV1(data?.svedPriemNalDS, null, documentType),
      svedVydachaNalDS: this.getSvedNalDSV1(data?.svedVydachaNalDS, null, documentType),
      svedAvtorizatsiiaESP: this.getSvedNalDSV1(data?.svedAvtorizatsiiaESP, null, documentType),
      tipOperatorDS: [data?.tipOperatorDS, this.valid({ isReq: true, max: 1 })],
      vidPerevodDS: [data?.vidPerevodDS, this.valid({ isReq: true, max: 1 })],
    }, valid);

    if (documentType !== DocumentTypeEnum.OPERATIONS_1_2) {
      form.get('kodTerInGos').disable();
    }

    return form;
  }

  private getSvedeniiaVneseniePoluchenieNalDSV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fbGroup(data, {
      kodKharakterOp: [data?.kodKharakterOp, this.valid({ isReq: true, max: 1 })],
      nomerSchetKlient: [data?.nomerSchetKlient, this.valid({ isReq: true, max: 40 })],
      nomerKarta: [data?.nomerKarta, this.valid({ max: 40 })],
      svedPriemVydacha: this.getSvedNalDSV1(data?.svedPriemVydacha, null, documentType),
    }, valid);
  }

  getUchastnikOpFmV1: FormGroupGetterFn = (data: any, valid?, documentType?) => {
    const group = this.fb.group({
      kodIuLFL: [data?.kodIuLFL, this.valid({ max: 10 })],
      kommentUchastnik: [data?.kommentUchastnik, this.valid({ max: 2000 })],
      priznKlient: [data?.priznKlient, this.valid({ isReq: true, max: 1 })],
      priznUchastnika: [data?.priznUchastnika, this.valid({ isReq: true, max: 1 })],
      statusUchastnika: [data?.statusUchastnika, this.valid({ isReq: true, max: 1 })],
      tipUchastnika: [data?.tipUchastnika, this.valid({ isReq: true, max: 1, minNumber: 0, maxNumber: 5 })],
    });

    if (data?.tipUchastnika === '1') {
      const otherParams = {
        tipUchastnika: data?.tipUchastnika,
        priznUchastnika: data?.priznUchastnika
      }

      group.removeControl('uchastnikFLIP');
      group.removeControl('uchastnikINBOIuL');
      group.addControl('uchastnikIuL', this.getUchastnikIuLV1(data?.uchastnikIuL, null, documentType, otherParams));
    }

    if (data?.tipUchastnika === '2' || data?.tipUchastnika === '3' || data?.tipUchastnika === '4') {
      const otherParams = {
        tipUchastnika: data?.tipUchastnika ?? '2',
        priznKlient: data?.priznKlient
      }

      group.removeControl('uchastnikIuL');
      group.removeControl('uchastnikINBOIuL');
      group.addControl('uchastnikFLIP', this.getUchastnikFLIPV1(data?.uchastnikFLIP, null, documentType, otherParams));
    }
    if (data?.tipUchastnika === '5') {
      group.removeControl('uchastnikFLIP');
      group.removeControl('uchastnikIuL');
      group.addControl('uchastnikINBOIuL', this.getUchastnikINBOIuLV1(data?.uchastnikINBOIuL, null, null, data?.tipUchastnika));
    }
    group.updateValueAndValidity();
    return group;
  }

  private getSvedNalDSV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    const isNeedCountry = ![DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType);
    const isReqAdrFields = false;
    return this.fbGroup(data, {
      bikko: [data?.bikko, this.valid({isBIKKO: true })],
      naimKO: [data?.naimKO, this.valid({ max: 250 })],
      idTerminal: [data?.idTerminal, this.valid({ max: 50 })],
      adrMestaPriemaVydacha: this.getAddress(data?.adrMestaPriemaVydacha, [], documentType, isNeedCountry, isReqAdrFields),
    }, valid);
  }

  private getSvedBankV1: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      bikko: [data?.bikko, this.valid({isBIKKO: true })],
      naimKO: [data?.naimKO, this.valid({ isReq: true, max: 250 })],
    }, valid);
  }

  getUchastnikIuLV1: FormGroupGetterFn = (data, valid, documentType, otherParams) => {
    const uchastnik = this.fbGroup(data, {
      svedEIO: this.fbArray(this.getSvedEIOOper, data?.svedEIO, null, null, documentType),
      novSvedEIO: this.fbArray(this.getSvedEIOOper, data?.novSvedEIO),
      svedIuL: this.getSvedIuLOperations(data?.svedIuL, null, documentType),
      novSvedIuL: this.getSvedIuLOperations(data?.novSvedIuL)
    }, valid);

    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2]) {
      uchastnik.get('novSvedEIO').disable();
      uchastnik.get('novSvedIuL').disable();
    }
    return uchastnik
  }

  getUchastnikFLIPV1: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    otherParams = { ...otherParams, flip: 'FLIP' };
    const uchastnik = this.fbGroup(data, {
      identifikatsiiaFL: [data?.identifikatsiiaFL, this.valid({ max: 1 })],
      svedFLIP: this.getSvedFLIPOperations(data?.svedFLIP, [], documentType, otherParams),
      novSvedFLIP: this.getSvedFLIPOperations(data?.novSvedFLIP, [], documentType, otherParams),
    }, valid);

    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2]) {
      uchastnik.get('novSvedFLIP').disable();
    }
    return uchastnik;
  }

  getUchastnikINBOIuLV1: FormGroupGetterFn = (data, valid, documentType) => {
    const uchastnik = this.fbGroup(data, {
      svedINBOIuL: this.getSvedINBOIuLOper(data?.svedINBOIuL, null, documentType),
      novSvedINBOIuL: this.getSvedINBOIuLOper(data?.novSvedINBOIuL, null, documentType),
    }, valid);

    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2]) {
      uchastnik.get('novSvedINBOIuL').disable();
    }
    return uchastnik;
  }

  getInfFilial: FormGroupGetterFn = (data, valid?, documentType?) => {
    const isOkatoFlNotReq = [DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType);
    const group = this.fbGroup(data, {
      bikfl: [data?.bikfl ?? '', this.valid( { min: 9, max: 9 })],
      nomFl: [data?.nomFl, this.valid({ isReq: true, max: 4 })],
      okatofl: [data?.okatofl, this.valid({ isReq: !isOkatoFlNotReq, min: 2, max: 2 })],
      regNomKO: [data?.regNomKO, this.valid({ isReq: true, max: 4 })],
    }, valid);

    if (documentType && [DocumentTypeEnum.REFUSE_CONTRACT_1_1,
      DocumentTypeEnum.REFUSE_OPERATION_1_1, DocumentTypeEnum.REFUSE_OPERATION_1_2].includes(documentType)) {
      group.removeControl('regNomKO');
    }

    return group;
  }

  getProverkaV1(data: any): FormGroup {
    return this.fb.group({
      nomerZapisi: [data?.nomerZapisi, this.valid({ isReq: true, pattern: '([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})' })],
      tipZapisi: [data?.tipZapisi, this.valid({ isReq: true, max: 10 })],
      dataPredProv: [data?.dataPredProv, this.valid({ isReq: true, isDate: false })],
      dataTekProv: [data?.dataTekProv, this.valid({ isReq: true, isDate: false })],
      kol30: [data?.kol30, this.valid({ isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol50: [data?.kol50, this.valid({ isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      komment: [data?.komment, this.valid({ isReq: false, max: 2000 })],
      kol0Fizlits: this.getKolV1(data?.kol0Fizlits),
      kol0Obshchee: this.getKolV1(data?.kol0Obshchee),
      kol0Organizatsij: this.getKolV1(data?.kol0Organizatsij),
      kolFizlits: this.getKolV1(data?.kolFizlits),
      kolObshchee: this.getKolV1(data?.kolObshchee),
      kolOrganizatsij: this.getKolV1(data?.kolOrganizatsij),
    });
  }

  private getKolV1(data: any): FormGroup {
    return this.fb.group({
      kol0000: [data?.kol0000, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol0001: [data?.kol0001, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol0002: [data?.kol0002, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol0003: [data?.kol0003, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
    });
  }

  getAddress = (data, valid?, documentType?, isNeedCountry = false, isReqFields = true) => {
    const isRefuseOperation = REFUSE_OPERATION_1_X.includes(documentType);
    const isRefuseContract = REFUSE_CONTRACT_1_X.includes(documentType);
    const form = this.fbGroup(data, {
      dom: [data?.dom, this.valid({ max: 60 })],
      indeks: [data?.indeks, this.valid({ max: 30 })],
      kodOKSM: [data?.kodOKSM, this.valid({ max: 3, isReq: !isRefuseOperation && isReqFields })],
      kodSubieektaPoOKATO: [data?.kodSubieektaPoOKATO, this.valid({ min: 2, max: 2 })],
      korp: [data?.korp, this.valid({ max: 50 })],
      of: [data?.of, this.valid({ max: 100 })],
      punkt: [data?.punkt, this.valid({ max: 250, isReq: !isRefuseOperation && isReqFields})],
      rajon: [data?.rajon, this.valid({ max: 250 })],
      ulitsa: [data?.ulitsa, this.valid({ max: 250 })],
      stranaNaimenovanie: [data?.stranaNaimenovanie, this.valid({ max: 250 })],
    }, valid);
    if (!isNeedCountry) {
      form.get('stranaNaimenovanie').disable();
    }
    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType)) {
      form.addControl('adresStroka', this.fbControl(data?.adresStroka, this.valid({max: 500})));

      const controlsName = {
        firstArrayControlsNames: ['dom', 'indeks', 'kodOKSM', 'kodSubieektaPoOKATO', 'korp', 'of', 'punkt', 'ulitsa', 'rajon'],
        secondArrayControlsNames: ['adresStroka']
      }

      this._disableFormControls(form, !data?.adresStroka, controlsName)
    }

    if (isRefuseOperation || isRefuseContract) {
      form.get('indeks').disable();
    }
    return form;
  }

  getSvedIuLOperations: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    const isNeedCountry = ![DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType);
    const svedIuL = this.fbGroup(data, {
      adrIuL: this.getAddress(data?.adrIuL, [], documentType, isNeedCountry),
      adrRegIuL: this.getAddress(data?.adrRegIuL, [], documentType, otherParams),
      dataRegIuL: [data?.dataRegIuL, this.valid({isDate: true})],
      dataAkkreditatsii: [data?.dataAkkreditatsii, this.valid({isDate: true})],
      inniuL: [data?.inniuL, this.valid({isInnKio5or10: true})],
      kppiuL: [data?.kppiuL, this.valid({max: 9, min: 9})],
      naimIuL: [data?.naimIuL, this.valid({isReq: true, max: 1000})],
      ogrniuL: [data?.ogrniuL, this.valid({max: 500})],
      priznakFlIuL: [data?.priznakFlIuL ?? 0, this.valid({max: 1})],
    }, valid);

    if (OPERATIONS_1_X.includes(documentType) || REFUSE_CONTRACT_1_X.includes(documentType)) {
      svedIuL.get('dataAkkreditatsii').disable();
    }
    return svedIuL;
  }

  getSvedFLIPOperations: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    const isNeedCountry = ![DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType);
    const group = this.fbGroup(data, {
      adrReg: this.getAddress(data?.adrReg, [], documentType, isNeedCountry),
      adrRegIP: this.getAddress(data?.adrRegIP, [], documentType, isNeedCountry),
      dataRozhdeniia: [data?.dataRozhdeniia, this.valid({ isDate: true })], // req?
      dokUdLichn: [data?.dokUdLichn, this.valid({ isReq: true, max: 1 })],
      fioflip: this.getFioOperations(data?.fioflip, null, documentType),
      innflip: [data?.innflip ?? '', this.valid({ max: 12 , min: 12})],
      kodOKSM: [data?.kodOKSM, this.valid({ max: 3 })],
      stranaNaimenovanie: [data?.stranaNaimenovanie],
      ogrnip: [data?.ogrnip, this.valid({ max: 15, min: 15 })],
      polisNomer: [data?.polisNomer, this.valid({isPolicyOMC9or16: true})],
      priznakPubLitso: [data?.priznakPubLitso, this.valid({ max: 1 })],
      regnNomer: [data?.regnNomer, this.valid({ max: 250 })],
      snilsfl: [data?.snilsfl, this.valid({ max: 14, min: 14, pattern: '[0-9]{3}-[0-9]{3}-[0-9]{3}(-| )[0-9]{2}'})],
      tipFLChastnaiaPraktika: [data?.tipFLChastnaiaPraktika, this.valid({ max: 1 })],
      svedDokPreb: this.getSvedDokPrebOper(data?.svedDokPreb, null, documentType),
      svedDokUdLichn: this.getSvedDokUdLichnOper(data?.svedDokUdLichn, null, documentType),
      svedMigrKart: this.getMigrKart(data?.svedMigrKart, null, documentType),
      telefonNomer: this.fbArray(this.fbControl, data?.telefonNomer, this.valid('telefonNomer')),
      dataRegIP: [data?.dataRegIP, this.valid({ isDate: true })],
      mestoRozhd: this.getMestoRozhd(data?.mestoRozhd),
    }, valid);
    // проверка на существование значения в otherParams по ключу 0
    if (otherParams?.tipUchastnika === '2' || otherParams?.tipKlienta === '2') {
      group.get('tipFLChastnaiaPraktika').disable();
      group.get('regnNomer').disable();
    }
    if (otherParams?.tipUchastnika === '3' || otherParams?.tipKlienta === '3') {
      group.get('tipFLChastnaiaPraktika').disable();
      group.get('regnNomer').disable();
    }

    if (OPERATIONS_1_X.includes(documentType) || REFUSE_CONTRACT_1_X.includes(documentType)) {
      group.get('dataRegIP').disable();
      group.get('adrRegIP').disable();
    }
    return group;
  }

  getFioOperations(data: any, valid?, documentType?) {
    const fio = this.getFio(data);
    if (documentType === DocumentTypeEnum.OPERATIONS_1_0) {
      fio.get('fiostroka').disable();
    }
    return fio;
  }

  getSvedDokUdLichnOper(data, valid?, documentType?) {
    const baseSvedDok = this.baseFormService.getSvedDokUdLichn(data, valid, documentType);
    baseSvedDok.addControl('vidDokNaimenovanie', this.fbControl(data?.vidDokNaimenovanie, this.valid({isReq: true, max: 250})));
    baseSvedDok.addControl('nachPravPreb', this.fbControl(data?.nachPravPreb, this.valid({isDate: true})));
    baseSvedDok.addControl('okonchPravPreb', this.fbControl(data?.okonchPravPreb, this.valid({isDate: true})));
    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType)) {
      baseSvedDok.get('vidDokNaimenovanie').disable();
      baseSvedDok.get('nachPravPreb').disable();
      baseSvedDok.get('okonchPravPreb').disable();
    }
    return baseSvedDok;
  }

  getSvedDokPrebOper(data, valid?, documentType?) {
    const baseSvedDokPreb = this.baseFormService.getSvedDokPreb(data, valid, documentType);
    baseSvedDokPreb.addControl('vidDokNaimenovanie', this.fbControl(data?.vidDokNaimenovanie, this.valid({isReq: true, max: 250})));
    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType)) {
      baseSvedDokPreb.get('vidDokNaimenovanie').disable();
    }
    return baseSvedDokPreb;
  }

  getSvedINBOIuLOper(data, valid?, documentType?) {
    const isNeedCountry = ![DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType);
    const svedINBOIuL = this.fbGroup(data, {
      polnoeNaimINBOIuL: [data?.polnoeNaimINBOIuL, this.valid({ isReq: true, max: 500 })],
      naimINBOIuL: [data?.naimINBOIuL, this.valid({  max: 250 })],
      priznakOrgFormaINBOIuL: [data?.priznakOrgFormaINBOIuL, this.valid({ isReq: true, max: 1 })],
      regINBOIuL: this.fbArray(this.getRegINBOIuL, data?.regINBOIuL),
      uchreditel: this.fbArray(this.getUchreditelOper, data?.uchreditel, null, null, documentType),
      mestoDeiatelnostINBOIuL: this.getAddress(data?.mestoDeiatelnostINBOIuL, [], documentType, isNeedCountry),
    }, valid);
    if ([DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType)) {
      svedINBOIuL.get('naimINBOIuL').disable();
    }
    return svedINBOIuL
  }

  getUchreditelOper: FormGroupGetterFn = (data, valid?, documentType?) => {
    const isNeedCountry = ![DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType);
    return this.fbGroup(data, {
      adrUchreditel: this.getAddress(data?.adrUchreditel, [], documentType, isNeedCountry),
      naimUchreditel: this.getNaimUchreditelOper(data?.naimUchreditel, null, documentType),
    }, valid);
  }

  private getNaimUchreditelOper: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fbGroup(data, {
      fizLitso: this.getFioOperations(data?.fizLitso, null, documentType),
      iurLitso: [data?.iurLitso, this.valid({ max: 1000 })],
    }, valid);
  }

  getSvedEIOOper: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    otherParams = { ...otherParams, tipEIO: data?.tipEIO };
    const isNeedCountry = ![DocumentTypeEnum.OPERATIONS_1_1, DocumentTypeEnum.OPERATIONS_1_2].includes(documentType);
    return this.fbGroup(data, {
      fleio: this.getSvedFLIPOperations(data?.fleio, [], documentType, otherParams),
      iuLEIO: this.getSvedIuLOperations(data?.iuLEIO, [], documentType, isNeedCountry),
      priznEIO: [data?.priznEIO ?? 0, this.valid({ max: 1 })],
      tipEIO: [data?.tipEIO, this.valid({ isReq: true, max: 1 })]
    }, valid);
  }

  getMestoRozhd: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fbGroup(data, {
      kodOKSM: [data?.kodOKSM, this.valid({ max: 3 })],
      stranaNaimenovanie: [data?.stranaNaimenovanie, this.valid({ max: 250 })],
      kodSubieektaPoOKATO: [data?.kodSubieektaPoOKATO, this.valid({ min: 2, max: 2 })],
      punkt: [data?.punkt, this.valid({ max: 250 })],
      rajon: [data?.rajon, this.valid({ max: 250 })],
    }, valid);
  }

  getUchreditelV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    const group = this.fbGroup(data, {
      adrUchreditel: this.getAddress(data?.adrUchreditel, valid, documentType, true),
      naimUchreditel: this.getNaimUchreditel(data?.naimUchreditel),
    }, valid);

    return group;
  }

  getSvedDokUdLichnV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    const vidDokKods = ['28', '35', '99'];
    const is5392 = this.validationSrv?.is5392Subject$?.getValue();
    const isp407Answer = this.validationSrv?.isP407answerSubject$?.getValue();
    const isRefuseOperation = REFUSE_OPERATION_1_X.includes(documentType);

    const group = this.fbGroup(data, {
      datVydachiDok: [data?.datVydachiDok, this.valid({ isReq: is5392 || isp407Answer || isRefuseOperation, isDate: true })],
      inoeNaimenovanieDok: [data?.inoeNaimenovanieDok, this.valid({ isReq: vidDokKods.includes(data?.vidDokKod) ? true : false, max: 500 })],
      kemVydanDok: [data?.kemVydanDok, this.valid({ ...(is5392 && {isReq: true}), max: 500 })],
      kodPodr: [isRefuseOperation ? data?.kodPodr ?? '' : data?.nomDok, this.valid({ max: 50 })],
      nomDok: [isRefuseOperation ? data?.nomDok ?? '' : data?.nomDok, this.valid({ max: 50 })],
      seriiaDok: [data?.seriiaDok, this.valid({ max: 50 })],
      vidDokKod: [(documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY) ? data?.vidDokKod ?? '' : data?.vidDokKod, this.valid({ isReq: true, max: 2 })],
    }, valid);

    if (documentType && [DocumentTypeEnum.REFUSE_CONTRACT_1_1,
      DocumentTypeEnum.REFUSE_OPERATION_1_2, DocumentTypeEnum.REFUSE_OPERATION_1_1].includes(documentType)) {
      group.addControl('vidDokNaimenovanie', new FormControl(data?.vidDokNaimenovanie, this.valid({ isReq: true })));
    }

    return group
  }

  getSvedIuLV1: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    const group = this.fbGroup(data, {
      adrIuL: this.getAddress(data?.adrIuL, valid, documentType, true),
      adrRegIuL: this.getAddress(data?.adrRegIuL, valid, documentType, true),
      dataRegIuL: [data?.dataRegIuL, this.valid({ isDate: true })],
      inniuL: [data?.inniuL, this.valid({ isInnKio5or10: true })],
      kppiuL: [data?.kppiuL, this.valid({ max: 9, min: 9 })],
      naimIuL: [data?.naimIuL, this.valid({ isReq: true, max: 1000 })],
      ogrniuL: [data?.ogrniuL, this.valid({ max: 500 })],
      priznakFlIuL: [data?.priznakFlIuL ?? 0, this.valid({ max: 1 })],
      okpoiuL: [data?.okpoiuL, this.valid({ max: 9 })],
      okvediuL: [data?.okvediuL],
      okved2IuL: [data?.okved2IuL],
    }, valid);

    if (documentType && documentType === DocumentTypeEnum.REFUSE_CONTRACT_1_1) {
      group.removeControl('priznakFlIuL');
      group.removeControl('dataRegIuL');
    }

    if (documentType && [DocumentTypeEnum.REFUSE_OPERATION_1_1, DocumentTypeEnum.REFUSE_OPERATION_1_2].includes(documentType)) {
      group.removeControl('priznakFlIuL');
      group.removeControl('dataRegIuL');
    }
    return group;
  }

  getSvedFLIPV1: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    const isRefuseOperation = REFUSE_OPERATION_1_X.includes(documentType);
    const group = this.fbGroup(data, {
      adrReg: this.getAddress(data?.adrReg, valid, documentType, true),
      dataRozhdeniia: [data?.dataRozhdeniia, this.valid({ isDate: true })], // req?
      fioflip: this.getFio(data?.fioflip),
      innflip: [data?.innflip ?? '', this.valid({ max: 12 , min: 12})],
      kodOKSM: [data?.kodOKSM, this.valid({isReq: isRefuseOperation, max: 3 })],
      ogrnip: [data?.ogrnip, this.valid({ max: 15, min: 15 })],
      polisNomer: [data?.polisNomer, this.valid({isPolicyOMC9or16: true})],
      priznakPubLitso: [data?.priznakPubLitso, this.valid({ max: 1 })],
      regnNomer: [data?.regnNomer, this.valid({ max: 250 })],
      snilsfl: [data?.snilsfl, this.valid({ max: 14, min: 14, pattern: '[0-9]{3}-[0-9]{3}-[0-9]{3}(-| )[0-9]{2}'})],
      tipFLChastnaiaPraktika: [data?.tipFLChastnaiaPraktika, this.valid({ max: 1 })],
      svedDokPreb: this.getSvedDokPreb(data?.svedDokPreb, null, documentType),
      svedDokUdLichn: this.getSvedDokUdLichnV1(data?.svedDokUdLichn, null, documentType),
      telefonNomer: this.fbArray(this.fbControl, data?.telefonNomer, this.valid('telefonNomer')),
      okvedip: [data?.okvedip],
      okved2IP: [data?.okved2IP],
      stranaNaimenovanie: [data?.stranaNaimenovanie, this.valid({max: 250})],
      mestoRozhd: this.getMestoRozhd(data?.mestoRozhd, valid, documentType),
      adrRegIP: this.getAddress(data?.adrRegIP, valid, documentType, true)
    }, valid);

    // проверка на существование значения в otherParams по ключу 0
    if (otherParams?.tipUchastnika === '2' || otherParams?.tipKlienta === '2') {
      group.get('tipFLChastnaiaPraktika').disable();
      group.get('regnNomer').disable();
    }

    if (otherParams?.tipUchastnika === '3' || otherParams?.tipKlienta === '3') {
      group.get('tipFLChastnaiaPraktika').disable();
      group.get('regnNomer').disable();
    }

    if (documentType && REFUSE_CONTRACT_1_X.includes(documentType)) {
      const fioflipGroup = group.get('fioflip') as FormGroup;

      fioflipGroup.removeControl('fiostroka');
      group.removeControl('polisNomer');
      group.removeControl('telefonNomer');
      group.removeControl('svedDokPreb');
    }

    if (documentType && REFUSE_OPERATION_1_X.includes(documentType)) {
      const fioflipGroup = group.get('fioflip') as FormGroup;

      fioflipGroup.removeControl('fiostroka');
      group.removeControl('polisNomer');
      group.removeControl('telefonNomer');
      group.removeControl('svedDokPreb');
    }

    return group;
  }

  getSvedINBOIuLV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    const group = this.fbGroup(data, {
      polnoeNaimINBOIuL: [data?.polnoeNaimINBOIuL, this.valid({ isReq: true, max: 500 })],
      priznakOrgFormaINBOIuL: [data?.priznakOrgFormaINBOIuL, this.valid({ isReq: true, max: 1 })],
      naimINBOIuL: [data?.naimINBOIuL, this.valid({ isReq: true, max: 250 })],
      regINBOIuL: this.fbArray(this.getRegINBOIuL, data?.regINBOIuL),
      uchreditel: this.fbArray(this.getUchreditelV1, data?.uchreditel),
      mestoDeiatelnostINBOIuL: this.getAddress(data?.mestoDeiatelnostINBOIuL),
      kodINBOIuL: [this.fbArray(this.fbControl, data?.kodINBOIuL, this.valid({ max: 50 }))],
      nomerINBOIuL: [this.fbArray(this.fbControl, data?.nomerINBOIuL, this.valid({ max: 50 }))],
    }, valid);

    if (documentType && ![DocumentTypeEnum.REFUSE_CONTRACT_1_1, ...REFUSE_OPERATION_1_X].includes(documentType)) {
      group.get('naimINBOIuL').disable();
    }

    if (documentType && REFUSE_OPERATION_1_X.includes(documentType)) {
      group.removeControl('regINBOIuL');
    } else {
      group.removeControl('kodINBOIuL');
      group.removeControl('nomerINBOIuL');
    }

    return group;
  }

  // для "Отказ от заключения договора (расторжение)" 4077-У версии 1.x
  getSvedPredstavV1Form(data: any, docType?): FormArray {
    return this.fbArray(this.getSvedPredstavV1, data, docType, this.valid({ isReq: true }));
  }

  getSvedPredstavV1(data: any, docType?): FormGroup {
    return this.fb.group({
      infFilial: this.getInfFilial(data?.infFilial, null, docType),
      svedOtkaz: this.fbArray(this.getSvedOtkazV1, data?.svedOtkaz, null, this.valid({ isReq: true }), docType),
      priznakPredstavSved: [data?.priznakPredstavSved ?? 0, this.valid({ isReq: true })],
    });
  }

  getSvedOtkazV1: FormGroupGetterFn = (data, valid?, documentType?) => {
    const svedOtkaz = this.fbGroup(data, {
      dataOtkaza: [data?.dataOtkaza, this.valid({ isReq: true, isDate: true })],
      dataUvedomlenia: [data?.dataUvedomlenia, this.valid({ isReq: true, isDate: true })],
      inaiaPrichinaOtkaza: [data?.inaiaPrichinaOtkaza, this.valid({ max: 2000 })],
      kharakterOp: [data?.kharakterOp, this.valid({ isReq: true, max: 2000 })],
      kodDenezhSredstv: [data?.kodDenezhSredstv, this.valid({ max: 1 })],
      kodOtkaza: [data?.kodOtkaza, this.valid({ isReq: true, max: 2 })],
      kodPriznOperatsii: [data?.kodPriznOperatsii, this.valid({ isReq: true, max: 1 })],
      kodVal: [data?.kodVal, this.valid({ isReq: true, max: 3 })],
      komment: [data?.komment, this.valid({ max: 2000 })],
      nomerZapisi: [data?.nomerZapisi, this.valid({ isReq: true, pattern:'([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{10})'})],
      prichinaUdaleniia: [data?.prichinaUdaleniia, this.valid({ max: 2000 })],
      priznakInf: [data?.priznakInf ?? 0, this.valid({ isReq: true, max: 1 })],
      priznakKlienta: [data?.priznakKlienta, this.valid({ isReq: true, max: 1 })],
      sumOperatsii: [data?.sumOperatsii, this.valid({ isReq: true, pattern: Utils.floatPattern })],
      sumRub: [data?.sumRub, this.valid({ isReq: true, pattern: Utils.floatPattern })],
      tipKlienta: [data?.tipKlienta, this.valid({ isReq: true, max: 1 })],
      tipZapisi: [data?.tipZapisi, this.valid({ isReq: true, max: 1 })],
      osnovanieOp: this.fbArray(this.getOsnovanieOpV1, data?.osnovanieOp, null, null, documentType),
      kodPrichinaOtkaz: [data?.kodPrichinaOtkaz],
      priznNeobOperatsii: [data?.priznNeobOperatsii, this.valid({isReq: true})],
      uchastnik: this.fbArray(this.getUchastnikRefuse, data?.uchastnik, null, null, documentType),
      svedIuL: this.getSvedIuLV1(data.svedIuL, valid, documentType),
      svedFLIP: this.getSvedFLIPV1(data?.svedFLIP, valid, documentType, data?.tipKlienta),
      svedINBOIuL: this.getSvedINBOIuLV1(data?.svedINBOIuL, valid, documentType),
    }, valid);

    if (documentType && [DocumentTypeEnum.REFUSE_OPERATION_1_2, DocumentTypeEnum.REFUSE_OPERATION_1_1].includes(documentType) ) {
      svedOtkaz.removeControl("tipKlienta");
      svedOtkaz.removeControl("svedIuL");
      svedOtkaz.removeControl("svedFLIP");
      svedOtkaz.removeControl("svedINBOIuL");
      svedOtkaz.removeControl("dataUvedomlenia");
      svedOtkaz.get('priznakKlienta').disable();
      svedOtkaz.get('kodOtkaza').setValidators(this.valid({kodOtkaza07or08or80: true}));
    } else {
      svedOtkaz.get('priznakKlienta').enable();
    }

    // отсутствуют поля в Отказ от заключения договора
    if (documentType && [DocumentTypeEnum.REFUSE_CONTRACT_1_1, DocumentTypeEnum.REFUSE_CONTRACT_1_2].includes(documentType)) {
      svedOtkaz.removeControl("kharakterOp");
      svedOtkaz.removeControl("kodPriznOperatsii");
      svedOtkaz.removeControl("kodVal");
      svedOtkaz.removeControl("sumOperatsii");
      svedOtkaz.removeControl("sumRub");
      svedOtkaz.removeControl("priznNeobOperatsii");
      svedOtkaz.removeControl("kodPrichinaOtkaz");
      svedOtkaz.removeControl("prichinaUdaleniia");
      svedOtkaz.removeControl("inaiaPrichinaOtkaza");
    }

    if (documentType && documentType === DocumentTypeEnum.REFUSE_OPERATION_1_1) {
      svedOtkaz.get('kodPrichinaOtkaz')?.disable();
      svedOtkaz.get('prichinaUdaleniia')?.disable();
      svedOtkaz.get('inaiaPrichinaOtkaza')?.disable();
    }
    return svedOtkaz;
  }

  getTransactionsRejectV1Form(data: any, docType?): FormArray {
    return this.fbArray(this.getSvedPredstavV1, data, docType, this.valid({ isReq: true }) );
  }

  getUchastnikRefuse: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fbGroup(data, {
      kodUchastnika: [data?.kodUchastnika, this.valid({ isReq: true, max: 2 })],
      priznUchastnika: [data?.priznUchastnika, this.valid({ isReq: true, max: 1 })],
      statusUchastnika: [data?.statusUchastnika, this.valid({ isReq: true, max: 1 })],
      tipUchastnika: [data?.tipUchastnika, this.valid({ isReq: true, max: 1 })],
      svedeniiaKO: this.getSvedeniiaKO(data?.svedeniiaKO),
      svedFLIP: this.getSvedFLIPV1(data?.svedFLIP, null, documentType, data),
      svedIuL: this.getSvedIuLV1(data?.svedIuL, null, documentType),
      svedINBOIuL: this.getSvedINBOIuLV1(data?.svedINBOIuL, null, documentType)
    }, valid);
  }

  getMigrKart: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      kodMigrKart: [data?.kodMigrKart, this.valid({ isReq: true, max: 2 })],
      seriiaMigrKart: [data?.seriiaMigrKart, this.valid({ max: 50 })],
      nomMigrKart: [data?.nomMigrKart, this.valid({ max: 50})],
      datNachPreb: [data?.datNachPreb, this.valid({ isDate: true })],
      datOkonchPreb: [data?.datOkonchPreb, this.valid({ isDate: true })],
    }, valid);
  }
}
