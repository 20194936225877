import {Component, Input, OnInit} from '@angular/core';
import { BaseFilterController } from '../../BaseFilterController';
import { FormBuilder } from '@angular/forms';
import {
  DictionaryService,
  ResourceService,
  StringDateAdapterService,
  TableService,
} from '@amlCore/services';
import { ActivatedRoute } from '@angular/router';
import { AmlStrategKoFilter } from 'src/app/core/models/amlStrategKoFilter.model';
import { Strateg655PFilterService } from 'src/app/arm/strateg655p/service/strateg655p-filter.service';
import {DocTypeSearch, getDocStatuses} from "../../../constants";

@Component({
  selector: 'app-amlstrategko-draft-list-filter',
  templateUrl: './amlstrategkoDraftListFilter.component.html',
})
export class AmlStrategDraftListFilterComponent
  extends BaseFilterController
  implements OnInit
{
  public filterObjectModel = new AmlStrategKoFilter();
  placeholder = 'по идентификатору записи, по имени xml-документа';
  creatorList = [];
  docStatuses: DocTypeSearch[] = []
  @Input() statuses: 'created' | 'deleted' = 'created'

  constructor(
    private readonly fb: FormBuilder,
    private readonly resourceSrv: ResourceService,
    private dictSrv: DictionaryService,
    protected tableSrv: TableService,
    public dateSrv: StringDateAdapterService,
    protected readonly activatedRoute: ActivatedRoute,
    private strateg655PFilterService: Strateg655PFilterService
  ) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.strateg655PFilterService.loadUserAccessList();
    this.docStatuses = getDocStatuses(this.statuses)
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new AmlStrategKoFilter();
    return this.fb.group(formConfig);
  }

  getStartFilterModel(): AmlStrategKoFilter {
    return {
      documentType: null,
      documentStatus: null,
    } as AmlStrategKoFilter;
  }

  /**
   * Описания полей
   */
   public getFilterDescription(): any {
    return {
      documentType: 'Документ',
      documentStatus: 'Статус',
      dateFrom: 'Дата направления С',
      dateTo: 'Дата направления По',
      creatorId: 'Владелец документа',
    };
  }

  // todo вынос на сервер или энум если оставим тут
  docTypes = [
    {id: 18, name: 'SD_KO_OTKR_ZAKR_CHET_DEPO', title: '3732-У. ЭС об открытии(закрытии) счета депо'},
    {id: 19, name: 'SD_KO_SECURITIES', title: '3732-У. ЭС о приобретении(отчуждении) ценных бумаг'},
    {id: 20, name: 'SD_KO_IZM_CHET_DEPO', title: '3732-У. ЭС об изменении реквизитов счета депо'},
    {id: 21, name: 'SD_KO_OTKR_ZAKR_AKKR', title: '3732-У. ЭС об открытии(закрытии) покрытого (депонированного) аккредитива'},
    {id: 22, name: 'SD_KO_IZM_AKKRED', title: '3732-У. ЭС об изменении реквизитов аккредитивов'},
    {id: 23, name: 'SD_KO_IZM_CHET', title: '2446-У. Электронное сообщение об изменении счета'},
    {id: 24, name: 'SD_KO_IZM_CHET_GOZ', title: 'Сообщение об изменении реквизитов отдельного счета (ГОЗ)'},
    {id: 25, name: 'SD_KO_OTKR_ZAKR_CHET', title: '2446-У. Электронное сообщение об открытии/закрытии счета'},
    {id: 26, name: 'SD_KO_OTKR_ZAKR_CHET_GOZ', title: 'Сведение банка об открытии (закрытии) отдельного счета (ГОЗ)'},
  ];

  get users() {
    return this.dictSrv.remoteDict.strateg.list || [];
  }
}
