import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconsService} from '@amlCore/services';
import { CommonService } from '../../services';
import {SaveFileService} from "../../../../core/services/save-file.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {DocumentTypeEnum} from "@amlCore/enums";

@Component({
  selector: 'app-common-send-documents',
  templateUrl: './commonSendDocuments.component.html',
  styleUrls: ['./commonSendDocuments.component.scss'],
})
export class CommonSendDocumentsComponent implements OnInit, OnDestroy {
  icons = this._iconsService.getIcons();
  data: any;
  disabled = false;
  success: boolean;
  loader = false;
  error = '';
  stopSended = false;
  sendProgress = false;

  preparedDocs = [];      // готовые к отправке
  sendedContainers = [];  // отправленные
  docsToSend = [];        // ждут отправку
  sendedDocs = [];        // уже отправленные
  blockDeniedDocs = [];    // ошибка при блокировке
  sectionType = '';
  blockSection = '';

  destroy$ = new Subject();

  constructor(private _activeModal: NgbActiveModal,
              private _iconsService: IconsService,
              private commonSrv: CommonService,
              private saveFileService: SaveFileService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose(): void {
    this._activeModal.close(this.success);
  }

  open(item: any) {
    this.sectionType = item.section || '';
    this.blockSection = this.blockSectionType();
    this.data = item.item;
  }

  stopSend() {
    this.stopSended = true;
    this.sendProgress = false;
  }

  /* Отправка списка контейнеров, и скачивание документов по ID в зависимости от параметра download (true/false)*/
  sendDocuments(): void {
  this.disabled = true;
  this.loader = true;
  const listIds = this.data.map((item) => item.id);

  if (listIds.length === 0) {
    return;
  }

  const _iterate = this.iteratorObject(this.data);
  this.sendProgress = true;
  this.commonSrv.blockDocuments(listIds, this.blockSection)
  .pipe(takeUntil(this.destroy$))
  .subscribe((block) => {
    this.blockDeniedDocs = block?.blockDenied;

    if (this.blockDeniedDocs.length > 0 && block?.success.length === 0) {
      this.stopSended = true;
      this.loader = false;
      return;
    }

    this._send(block?.success, _iterate);

  }, (errorBlock) => {
    this.disabled = false;
    this.success = false;
    this.error = errorBlock?.error ?? errorBlock,
    this.stopSended = true;
    this.loader = false;
  });
}

  iteratorObject(array) {
    let nextIndex = 0;
    return {
      next: function (): any {
        return nextIndex < array.length ?
          {object: array[nextIndex++], done: false} :
          {done: true};
      }
    }
  }

  checkBlock(docId: string) {
    return this.blockDeniedDocs.includes(docId);
  }

  private _send(listIds: string[], iterate) {
    this.commonSrv.sendDocuments(listIds, this.sectionType)
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {

        if (this.stopSended) {
          this.stopSend();
          return;
        }

        const step = (iterate) => {
          const next = iterate.next();
          if (!next.done || !this.stopSended) {
            setTimeout(() => info(iterate), 3000)
          } else {
            this.sendProgress = false;
          }
        }

        const info = (iterate) => {
          // проверяем информацию по отправке
          this._checkSendInfo(listIds, step, iterate);
        }
        // информация по отправке
        info(iterate)
    },
    (err) => {
      this.disabled = false;
      this.success = false;
      this.error = err?.error ?? err,
      this.stopSended = true;
      this.loader = false;
      this.commonSrv.unBlockDocuments(listIds, this.blockSection)
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {});
    },
    () => { }
    )
  }

  private _checkSendInfo(listIds: string[], step, iterate) {
    this.commonSrv.sendDocumentsInfo(listIds, this.sectionType)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      if (data.error !== null) {
        this.stopSend();
        this.error = data.error;
        // разблокировка всех при ошибке
        this.commonSrv.unBlockDocuments(listIds, this.blockSection)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {})
      }

      const IdsArr = data.containers;

      this.sendedDocs = data.sendedDocs;
      this.docsToSend = data.docsToSend;
      this.preparedDocs = data.preparedDocs;
      this.data.forEach((element: any) => {
        // отправленные
        element.success = IdsArr.some(container => this.sendedDocs[container.id]?.includes(element.id));
        // ожидают отправки
        element.docsToSend = this.docsToSend?.includes(element.id);
        if(element.docsToSend && !element.success)
          return;
        // готовые к отправке
        element.preparedDocs = this.preparedDocs?.includes(element.id);
        if(element.preparedDocs)
          return;
        // разблокировка отправленного
        this.commonSrv.unBlockDocuments([element.id], this.blockSection)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {})
      });

      // если отправлен контейнер (completed=true)
      if (data.completed) {
        this.stopSend();
        // скачиваем все файлы по ID с сервера перебором
        for (let i=0; i <IdsArr.length; i++) {
          if (this.sectionType === 'p407') {
            const containerId = IdsArr[i].id;
            const sendedDocsId = data.sendedDocs[containerId][0];
            const docType = this.data.find(docItem => docItem.id === sendedDocsId).type === DocumentTypeEnum.P407_ANSWER ? 'p407answer' : 'requests/407p/receipt';
            this.saveFileService.saveFiles(`/api/v1/documents/${docType}/container/download/${IdsArr[i].id}`, IdsArr[i]?.name);
          } else {
            this.saveFileService.saveFiles((this.sectionType ? `/api/v1/documents/${this.sectionType}/container/download/${IdsArr[i].id}` :  `/api/v1/documents/container/download/${IdsArr[i].id}`), IdsArr[i]?.name)
          }
        }
        this.disabled = false; this.success = true; this.loader = false;
        this.commonSrv.unBlockDocuments(listIds, this.blockSection)
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {});
      } else {
        step(iterate);
      }
    },
    err => {
      this.stopSended = true;
      this.error = err?.error ?? err;
      this.disabled = false; this.success = true; this.loader = false;
      this.commonSrv.unBlockDocuments(listIds, this.blockSection).subscribe((result) => {
        console.log(result);
      });
    })
  }

  blockSectionType(): string {
    switch (this.sectionType) {
      case 'oes/otkaz/goz':
        return 'otkazgoz';
      case 'OES_OTKAZ_GOZ':
        return 'otkazgoz';
      case 'sd/konfo/economic-measure':
        return 'spo281fz';
      default:
        return this.sectionType;
    }
  }
}

