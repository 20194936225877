import {Component, forwardRef} from '@angular/core';
import {DictionariesService} from "../../service";
import {QrefPidTerUchModel} from "../../models";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {ViewDictionaryBase} from "./view-dictionary-base";

@Component({
  selector: 'app-dict-qref-pidTerUch',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QrefPidTerUchViewComponent),
    multi: true
  }],
  template: `
    <div class="mb-3 col-6 pl-0 pr-0">
      <label class="form-label">{{title}}
        <app-rq *ngIf="isFieldReq(formControl)" [isReadOnly]="isDisabled"></app-rq>
      </label>
      <app-custom-select *ngIf="dictionary | async as dict; else loading"
                         name="description"
                         fieldsFromSearch="description"
                         [items]="dict"
                         [returnOnlyId]="true"
                         [formControl]="formControl"
                         [isInvalid]="submitted && formControl.errors">
        <ng-template let-item #labelTemplate>
          {{ item.description }}
        </ng-template>
        <ng-template let-item let-search="search" #optionTemplate>
          <div style="white-space: normal">
            {{ item.description }}
          </div>
        </ng-template>
      </app-custom-select>
      <ng-template #loading>
        <app-skeleton></app-skeleton>
      </ng-template>
      <app-valid [logic]="formControl" [submitted]="submitted"></app-valid>
    </div>
  `
})
export class QrefPidTerUchViewComponent extends ViewDictionaryBase<QrefPidTerUchModel> {
  getDictionary = this.dictSrv.getQrefPidTerUchList();
  constructor(private dictSrv: DictionariesService) {
    super();
  }
}
