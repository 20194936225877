import {Component, forwardRef, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from "@angular/forms";
import {ErrorFLKItemType} from "@amlCore/models";
import {CustomValidatorError} from "@amlCore/utils";
import {MultiFieldBase} from "../multipleFiled/multiFieldBase";

@Component({
  selector: 'app-multiple-field-description',
  templateUrl: './multipleFieldDescription.component.html',
  styleUrls: ['./multipleFieldDescription.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultipleFiledDescriptionComponent),
    multi: true
  },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultipleFiledDescriptionComponent),
      multi: true
    }]
})

export class MultipleFiledDescriptionComponent extends MultiFieldBase
  implements ControlValueAccessor, Validator, OnChanges {
  @Input() fieldNameValue = '';
  @Input() fieldNameDescription = '';
  @Input() maxlengthValue = 250;
  @Input() maxlengthDescription = '';
  @Input() placeholderMap = new Map();
  @Input() hasIndexed = false;
  @Input() errorFLKData: ErrorFLKItemType;
  public savedErrorFLKData: ErrorFLKItemType;

  @Input() showLabel = false;
  listItem = this.fb.array([]);
  @Input() dictionary: any[];
  constructor(protected fb: FormBuilder) {
    super(fb);
  }

  currentAbstractControl = (value = {}) => this.createCustomFormGroup(value);

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.errorFLKData) {
      this.clearErrorFLKListFields();
      if (this.errorFLKData) {
        this.listItem.controls.forEach(control => {
          const row = control.get('row')?.value;
          const errors = this.errorFLKData
            ? this.errorFLKData[row]
            : null;
          if (errors) {
            errors.forEach(error => {
              const result = {
                customValidator: {
                  errorMsg: error.msg,
                  errorMsgBig: error.reason,
                  withBigMsg: true,
                  withoutSubmitted: true
                } as CustomValidatorError
              };
              const field = control.get(error.path);

              if (field) {
                setTimeout(() => field.setErrors(result));
              } else {
                throw new Error(`Неизвестный путь ${ error.path }`);
              }
            });
          }
        });
      }
      this.savedErrorFLKData = this.errorFLKData;
    }
  }

    /**
     * Очистка ошибок по ФЛК
     */
    clearErrorFLKListFields(): void {
      if (this.savedErrorFLKData) {
        this.listItem.controls.forEach(control => {
          const row = control.get('row')?.value;
          const errors = this.savedErrorFLKData
            ? this.savedErrorFLKData[row]
            : null;
          if (errors) {
            errors.forEach(error => {
              const field = control.get(error.path);
              if (field) {
                setTimeout(() => field.setErrors(null));
              }
              this.savedErrorFLKData = this.errorFLKData;
            });
          }
        });
      }
    }

  /**
   * Создаем контроллеры для каждой строки, обновляем значения полей
   */
  getModelFromView(outsideValue: Array<string> | FormArray): void {
    Array.isArray(outsideValue)
      ? outsideValue.forEach((item) => {
          this.listItem.length === 0
            ? this.add(item)
            : this.listItem.push(this.createCustomFormGroup(item));
        })
      : outsideValue.controls.forEach((group: FormGroup) => {
          this.listItem.push(this.createCustomFormGroup(group.getRawValue()));
        });
  }

    getModelFromSave(modelView: Array<any>): any[] {
        const list: any[] = [];
        modelView.forEach((item) => {
            if (!item[this.fieldNameValue] && !item[this.fieldNameDescription]) {
                return;
            }
            const rowForSave = {};
            rowForSave[this.fieldNameValue] = item[this.fieldNameValue];
            rowForSave[this.fieldNameDescription] = item[this.fieldNameDescription];
            if (this.hasIndexed) {
                rowForSave['row'] = item.row;
            }
            list.push(rowForSave);
        });
        return list;
      }

    add(data = {}): void {
        const group = this.createCustomFormGroup(data);
        if (this.listItem.controls.length >= 1 && this.hasIndexed) {
          group.get('row').setValue(this._createUniqueId());
        } else if (this.listItem.controls.length === 0 && this.hasIndexed) {
          group.get('row').setValue(0);
        }
        this.listItem.push(group);
        this.sortFormArray();
        // для сохранения валидации formArray не пушим в него пустые FormGroup
        if (!this.formArray) this.updateValue();
    }
  // Данный контрол нужен был для для вывода ошибок, но сейчас это нигде не реализовано.
  // Поэтому он и добавление контрола row закоменчены до лучших времён
  // focusField(path: string, rowId: number) {
  //     const fieldEl = document.getElementById(`item_${ path }_${ rowId }`);
  //     console.log(fieldEl)
  //     if (fieldEl && fieldEl.focus) {
  //         setTimeout(() => {
  //             window.setTimeout(() => fieldEl.focus(), 0);
  //       });
  //     }
  // }
  
  setNameValue(index) {
    if (this.fieldNameValue === 'kodDragMetall') {
      const code = this.listItem?.controls[index]?.get('kodDragMetall')?.value;
      const name = this.dictionary?.find(item => item?.code === code)?.name;
      if (name) {
        this.listItem?.controls[index]?.get('naimDragMetall')?.setValue(name);
        this.updateValue(index);
      }
    } 
    return;
  }

  private createCustomFormGroup(data = {}) {
    if (this.createFormGroup !== undefined) {
      const formGroup = this.createFormGroup(data);
      // убрано в рамках AML-3250. 
      // formGroup.addControl('row', this.fb.control(null));
      return formGroup;
    }
    return this.fb.group({
      [this.fieldNameValue]: [data[this.fieldNameValue] ?? null],
      [this.fieldNameDescription]: [data[this.fieldNameDescription] ?? null],
      // row: null
    });
  }

  private sortFormArray() {
    if (this.hasIndexed) {
      const listItem = this.listItem.getRawValue();
      listItem.sort((a, b) => {
        if (a.row > b.row) {
          return 1;
        }
        if (a.row < b.row) {
          return -1;
        }
      });
      this.listItem.patchValue(listItem);
    }
  }

    private _createUniqueId(): number {
      let rowId = 0;
      const ids = [];

      this.listItem.controls.forEach((control) => {
        ids.push(control.get('row').value);
      });

      while (ids.includes(rowId)) {
        rowId++;
      }
      return rowId;
    }
}
