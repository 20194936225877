import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from "@angular/core";
import {NgControl, NgModel} from "@angular/forms";
import {Unsubscribable} from "rxjs";

@Directive({selector: '[appInputFormat]'})
export class InputFormatDirective implements OnInit {
  @Input() maxlength: number;
  /**
   * Тип формата
   */
  private typeFormat: InputFormat;
  /**
   * RegExp по типу формата
   */
  private regex: RegExp;
  /**
   * Форматы, которые надо обработать как цифры
   */
  private digitFormats = [];
  private _commaReplacement = [];
  onInitFormatHandler: Unsubscribable;
  /**
   *  Предыдущее значение модели
   */
  prevModel: any;

  /**
   * Получаем формат
   */
  @Input() set appInputFormat(type: InputFormat) {
    this.typeFormat = type;
  }

  @Output() ngModelChange = new EventEmitter();

  constructor(private control: NgControl) {
  }

  ngOnInit(): void {
    // Инициализируем regex по формату (допустимые символы для ввода)
    switch (this.typeFormat) {
      case "number":
        this.regex = new RegExp(/^\d*$/g);
        break;
      case "numberWithoutZero":
        this.regex = this.maxlength ? new RegExp(`^([1-9][0-9]{0,${this.maxlength-1}})$`,"g")
            :new RegExp(/^([1-9][0-9]*)$/g);
        break;
      case "snils":
        this.regex = new RegExp(/^[0-9 \-]*$/g);
        break;
      case "10.2":
        this.regex = new RegExp(/^\d{1,10}([.,]\d{0,2})?$/g);
        break;
      case "currency":
        this.regex = new RegExp(/^(\d| )*([.,]\d{0,2})?$/g);
        break;
      case "3.2":
        this.regex = new RegExp(/^\d{1,3}([,.]\d{0,2})?$/g);
        break;
      case "3.4":
        this.regex = new RegExp(/^\d{1,3}([,.]\d{0,4})?$/g);
        break;
      case "18.2":
        this.regex = new RegExp(/^\d{1,18}([,.]\d{0,2})?$/g);
        break;
      case "20.2":
        this.regex = new RegExp(/^\d{1,20}([,.]\d{0,2})?$/g);
        break;
      case "fio":
        this.regex = new RegExp(/^[а-яА-ЯёЁa-zA-Z]{1,50}([,.][а-яА-ЯёЁa-zA-Z]{1,50})?([,.][а-яА-ЯёЁa-zA-Z]{1,50})?$/g);
        break;
      case "okved":
        this.regex = new RegExp(/^[0-9.]+$/);
        break;
    }
    this.digitFormats = ["10.2"];
    this._commaReplacement = ["3.2", "3.4"];
    // Проставляем значение при инициализации (для реактивных форм и для NgModel)
    this.prevModel = this.control.value || (this.control as NgModel).model;
    // Форматирование поля при инициализации
    this.startFormat();
  }

  /**
   * Форматирование поля при инициализации
   * подписываемся на изменение - дожидаемся установки значения - обновляем поле - отписываемся
   */
  private startFormat() {
    this.onInitFormatHandler = this.control.valueChanges.subscribe((value: string) => {
      if (this.typeFormat === 'currency' && !this.prevModel) {
        value = this.currencyChange(value);
        this.updateControl(value, false);
      }
      this.onInitFormatHandler.unsubscribe();
    });
  }


  @HostListener('input', ['$event'])
  onInput(event) {
    const value = event.target.value;
    if (value && value !== "") {
      if (!String(value).match(this.regex)) {
        // Есть ошибки по маске ввода - проставляем предыдущее значение модели
        this.control.control.patchValue(this.prevModel);
      } else {
        // сохраняем введенное значение
        this.prevModel = value;
      }
    } else {
      this.prevModel = value;
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    let value = event.target.value;
    if (value) {
      // Если цифровой формат по выходу из поля преобразуем в число (убираем 00 и тд)
      if (this.digitFormats.includes(this.typeFormat)) {
        value = this.replaceDot(value);
        value = Number(value);
        this.updateControl(value);
      } else if (this.typeFormat === 'currency') {
        value = this.currencyChange(value);
        this.updateControl(value);
      } else if (this._commaReplacement.includes(this.typeFormat)) {
        value = this.replaceDot(value);
        this.updateControl(value);
      }
    }
  }

  /**
   * Форматирование денежного типа (в к всегда есть 00 или x0
   */
  private currencyChange(value: string): string {
    value = this.replaceDot(value)?.replace(/ /g, '');
    const dotPattern = /\./g;
    // число xxx
    if (!dotPattern.test(value)) {
      value += '.00';
      // число xxx.
    } else if (value.split('.')[1].length === 0) {
      value += '00';
      // число xxx.x
    } else if (value.split('.')[1].length === 1) {
      value += '0';
    }
    return value;
  }

  /**
   * Меняем , на .
   */
  private replaceDot(value: string): string {
    return value?.toString()?.replace(/,/g, '.');
  }

  /**
   * Обновление кнотрала
   */
  private updateControl(value, emitEvent = true): void {
    this.control.control.patchValue(value, {emitEvent: emitEvent});
    this.prevModel = value;
  }
}

/**
 * Доступные форматы
 * * Для денежного формата см директиву appMoney
 */
export type InputFormat = 'number' | 'fio' | 'numberWithoutZero' | '18.2' | '10.2' | '3.2' | '3.4' | 'snils' | 'currency' | '20.2' | 'okved';
