import {NgModule} from '@angular/core';
import {ClientRoutingModule} from './client-routing.module';
import {ClientComponent} from './client.component';
import * as components from './components';
import * as services from './services';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxMaskModule} from 'ngx-mask';
import {CoreModule} from '../core';
import {UiSwitchModule} from 'ngx-ui-switch';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CryptographService, CryptographyModule, CryptoValidataService} from "../cryptography";
import {DictionariesModule} from "../dictionaries";

@NgModule({
    imports: [
    CommonModule,
        CoreModule,
        NgbModule,
        ClientRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgSelectModule,
        NgxMaskModule.forRoot(),
        UiSwitchModule.forRoot({
            size: 'medium',
            color: '#ae0000'
        }),
        CryptographyModule,
        DictionariesModule
    ],
  declarations: [
    ClientComponent,
    components.NavCabComponent,
    components.ProfileComponent,
    components.CertificateCheckComponent,
    components.CertificateTableComponent,
    components.CertificateSelectComponent,
    components.CertificateContainerComponent,
  ],
  providers: [
    services.NoAuthGuard,
    services.ProfileService,
    services.ConfirmDeactivateGuard,
    components.NavCabService,
    services.MchdService,
  ]
})
export class ClientModule {
  constructor(private cryptographService: CryptographService, private cryptoValidataService: CryptoValidataService) {
    this.cryptographService.init();
  }
}
