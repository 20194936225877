import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService, DocumentBase, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "app-armfm-suspicious-activity-5861U-v1",
    templateUrl: "./suspicious-activity5861U-v1.html",
    styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class SuspiciousActivity5861Uv1 extends DocumentBase implements OnInit {
    readonly documentType = DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
    form: FormGroup;
    clientType: string;

    svedKOIndex: number = null;
    podozrDeiatIndex: number = null;
    klientIndex: number = null;

    selectEIODopKlientForm: FormArray;
    selectBenefitsiarDopKlientForm: FormArray;
    selectPodozrDeiatForm: FormArray;
    selectKlientForm: FormArray;

    constructor(
        protected documentBaseService: DocumentBaseService,
        protected iconsService: IconsService,
        protected commonSrv: CommonService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected cdr: ChangeDetectorRef
    ) {
        super(documentBaseService, iconsService, commonSrv, activatedRoute, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    initForm(): void {
        const suspiciousActivity = this.documentBaseService.getSvedKoSaForm(
            this.d.informChast?.svedKO ?? []
        );
        this.fGroup("informChast").addControl("svedKO", suspiciousActivity);
        this.cdr.detectChanges()
        this.disableForm();
    }

    onSelectedPodozrDeiatRow(index) {
        this._clearDopSved()
        this.klientIndex = null;
        this.podozrDeiatIndex = index;
        this.selectKlientForm = this.klientForm;
    }

    onSelectedSvedKORow(index) {
        this._clearDopSved();
        this.klientIndex = null;
        this.podozrDeiatIndex = null;
        this.svedKOIndex = index;
        this.selectPodozrDeiatForm = this.podozrDeiatForm;
    }

    onSelectedKlientRow(index) {
        this._clearDopSved()
        this.klientIndex = index;

        const currentKlient = this.klientForm?.at(index);
        if (!currentKlient) return;

        if (!this.clientType) {
            this.klientForm.valueChanges.
            pipe(takeUntil(this.destroy$)).
            subscribe((data)=> {
                const currentClientType = data[index]?.tipKlienta;
                if (currentClientType && currentClientType !== this.clientType) {
                    this.clientType = currentClientType;
                }
            })
        }

        this.clientType = this.klientForm?.at(index)?.get('tipKlienta').value;
        this._initDopClientFormAtChangeClientType();
    }

    get svedKOForm(): FormArray {
        return this.form.get('informChast.svedKO') as FormArray;
    }

    get podozrDeiatForm(): FormArray {
        return this.svedKOForm
            ?.at(this.svedKOIndex)
            ?.get("podozrDeiat") as FormArray;
    }

    get klientForm(): FormArray {
        return this.podozrDeiatForm?.at(this.podozrDeiatIndex)?.get('klient') as FormArray;
    }

    get operatsiiaPerechenForm(): FormArray {
        return this.klientForm?.at(this.klientIndex) as FormArray;
    }

    //Iul

    get klientIuLForm(): FormArray {
        return this.klientForm?.at(this.klientIndex)?.get("klientIuL") as FormArray;
    }

    get svedEIOForm(): FormArray {
        return this.klientIuLForm?.get("svedEIO") as FormArray;
    }

    get benefitsiarIuLForm(): FormArray {
        return this.klientIuLForm?.get("benefitsiarIuL") as FormArray;
    }

    //FLIP

    get klientFLIPForm(): FormArray {
        return this.klientForm?.at(this.klientIndex)?.get("klientFLIP") as FormArray;
    }

    get benefitsiarFLIPForm(): FormArray {
        return this.klientFLIPForm?.get("benefitsiarFLIP") as FormArray;
    }

    //INBOIuL

    get klientINBOIuLForm(): FormArray {
        return this.klientForm?.at(this.klientIndex)?.get("klientINBOIuL") as FormArray;
    }

    get benefitsiarINBOIuLForm(): FormArray {
        return this.klientINBOIuLForm?.get("benefitsiarINBOIuL") as FormArray;
    }

    private _initDopClientFormAtChangeClientType(): void {
        if (!this.clientType) return console.log('тип участника не задан!');

        if (this.clientType === '1') {
            this.selectEIODopKlientForm = this.svedEIOForm;
            this.selectBenefitsiarDopKlientForm = this.benefitsiarIuLForm;
        }

        if (this.clientType === '2' || this.clientType === '3' || this.clientType === '4')
            this.selectBenefitsiarDopKlientForm = this.benefitsiarFLIPForm;

        if (this.clientType === '5')
            this.selectBenefitsiarDopKlientForm = this.benefitsiarINBOIuLForm;

      }

      private _clearDopSved() {
        this.selectEIODopKlientForm = null;
        this.selectBenefitsiarDopKlientForm = null;
      }

}
