import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import * as selectItems from "../../../../control6670/json/selectItems.json";
import {takeUntil} from "rxjs/operators";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
  selector: 'app-info-emitent',
  templateUrl: './info-emitent.component.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class InfoEmitentComponent extends DocumentComponentBaseSub implements OnInit {
  @Input() formGroupApp: FormGroup;
  @Input() documentData: any;
  selectTipEmitentTsP = selectItems.tipEmitentTsPItems;
  selectPriznEmitentTsP = selectItems.priznEmitentTsP;

  constructor(
      private readonly docSrv: DocumentBaseService,
      private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    // Исправляет консольную ошибку ExpressionChangedAfterItHasBeenCheckedError
    this.formGroupApp.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.formGroupApp.get('tipEmitentTsP').valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((value) => {
      if (value === '3') {
        this.fizLitso.enable();
        this.iurLitso.disable();
      }
      if (value === '1') {
        this.fizLitso.disable();
        this.iurLitso.enable();
      }
    });
  }

  get fizLitso(): FormGroup {
    return this.formGroupApp?.get('naimEmitentTsP')?.get('fizLitso') as FormGroup;
  }

  get iurLitso(): AbstractControl {
    return this.formGroupApp?.get('naimEmitentTsP')?.get('iurLitso') as AbstractControl;
  };
}
