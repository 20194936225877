import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges} from "@angular/core";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {ITableCallBack, ITableItemDescriptor} from "@amlCore/components";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {DocumentTypeEnum} from "../enums";
import {takeUntil} from "rxjs/operators";

/**
 * Базовый класс для всех форм документов
 */
export abstract class DocumentSubForm implements OnInit, OnChanges, OnDestroy {
  public icons = { trash: faTrash };
  @Input() formGroupApp: FormArray;
  // должна соответствовать FormArray
  @Input() documentData: Array<any> = [];
  @Input() isReadOnly: boolean;
  @Input() submitted: boolean;
  @Input() isParts = false;
  // номер выбранного чекбокса (записи) в таблице
  selectedIndex = null;
  viewForm = null;
  destroy$ = new Subject();
  // обязательно для табличных форм
  itemDescriptor: ITableItemDescriptor;
  documentType: DocumentTypeEnum;
  // чтобы пропихнуть в app-table-comp
  controlFormGroupApp: FormControl;
  subsctiptionsControlFormGroupApp: Subscription;
  // для перезаписи несохраненных изменений (при нажатии кнопки выйти)
  // обязательно для табличных форм
  currentGetFormGroup: (model) => FormGroup;
  // опционально для табличных форм
  childGetFormGroup?: (model) => FormGroup;
  @Input() selected: (value, origin?, index?) => void;
  /**
   * @deprecated - после добавления форм данные расположены в documentData
   */
  @Input() list: any = [];
  /**
   * @deprecated - после добавления форм ищем данные по индексу documentData
   */
  keyData = "";

  @Output() newItemEmitter = new EventEmitter();

  protected constructor(protected modalService: NgbModal) {
  }

  /**
   * @deprecated - заменено на popupItem
   */
  openItem(item, page, isReadOnly = true) {
    const modalRef = this.modalService.open(this.viewForm, {
      backdrop: "static",
      keyboard: true,
      size: "full",
      backdropClass: "modal-hide-opacity",
      windowClass: "full",
      scrollable: true,
    });
    // Передаем данные в компонент
    modalRef.componentInstance.open({ item, page, isReadOnly });
    modalRef.result.then((data) => {
      if (data) {
        this.newItemEmitter.emit({ item: data, index: page - 1 });
      }
    });
  }

  /**
   * @deprecated - после добавления форм нужно только задать .$selected и вернуть просто index в selected()
   * заменено на onSelectItem()
   */
  onSelected(row, index?): void {
    if (row.$selected) {
      row.$selected = false;
      if (typeof this.selected === "function") {
        this.selected(null);
      }
    } else {
      this.selectOnce(row);
      if (typeof this.selected === "function") {
        this.selected(this.keyData ? row[this.keyData] : row, row, index);
      }
    }
  }

  ngOnInit() {
    // Описание элемента таблицы
    this.itemDescriptor = {
      create: this.isParts ? this.childGetFormGroup : this.currentGetFormGroup,
      component: this.viewForm,
      params: {
        typePage: this.documentType,
        isParts: this.isParts,
      },
    } as ITableItemDescriptor;
    this.initAndUpdateVariable();
  }

  ngOnChanges(change: SimpleChanges) {
    const form = change?.formGroupApp as SimpleChange;
    if (form && (form?.currentValue || form?.previousValue)) {
      this.initAndUpdateVariable();
    }

    if ('documentType' in change) {
      if ((change.documentType.currentValue !== change.documentType.previousValue) && !change.documentType.firstChange) {
        this.updateDocumentType();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  popupItem(index, formGroupApp, documentData, isReadOnly, submitted, isParts?, item?) {
    const modalRef = this.modalService.open(this.viewForm, {
      backdrop: "static",
      keyboard: true,
      size: "full",
      backdropClass: "modal-hide-opacity",
      windowClass: "full",
      scrollable: true,
    });
    // Передаем данные в компонент
    modalRef.componentInstance.open({
      index,
      formGroupApp,
      documentData,
      isReadOnly,
      submitted,
      isParts,
      item,
    });
  }

  selectOnce(item) {
    // data для поддержки @deprecated "list"
    const data = this.list.length > 0 ? this.list : this.formGroupApp.value;
    data?.forEach((row) => (row.$selected = false)); // todo может влиять на валидацию
    item.$selected = true;
  }

  onSelectItem(item, index): void {
    const isSelectedCurrentItem = this.selectedIndex === index;
    this.selectedIndex = index;
    if (isSelectedCurrentItem) {
      this.selectedIndex = null;
      if (typeof this.selected === "function") {
        this.selected(null);
      }
    } else {
      if (typeof this.selected === "function") {
        this.selected(index);
      }
    }
  }

  callbackItemClosed(event: ITableCallBack): void {
    const editIndex = event.editIndex;
    if (event.isConfirmBack) return; // todo при выходе без сохранения изменений (пока работает и так)
    if (editIndex !== null && editIndex !== undefined && editIndex !== -1)
      this.updateGroupToFormArray(event?.form, editIndex);
  }

  callbackItemDelete(event: ITableCallBack) {
    this.formGroupApp.removeAt(event.editIndex);
    this.formGroupApp.markAsDirty();
  }

  initAndUpdateVariable() {
    if (this.formGroupApp) {
      this.subsctiptionsControlFormGroupApp?.unsubscribe(); // todo не лучшее решение
      this.subsctiptionsControlFormGroupApp = this.formGroupApp?.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.initAndUpdateVariable());
    }

    this.controlFormGroupApp = new FormControl(this.formGroupApp?.value, this.formGroupApp?.validator);

    this.formGroupApp?.disabled && this.controlFormGroupApp.disable();
    this.formGroupApp?.enabled && this.controlFormGroupApp.enable();
  }

  private updateGroupToFormArray(formGroup: FormGroup, index: number): void {
    this.formGroupApp.at(index)
      ? this.formGroupApp.setControl(index, formGroup)
      : this.formGroupApp.push(formGroup);

    this.formGroupApp.markAsDirty();
    this.formGroupApp.updateValueAndValidity();
  }
  /**
   * Использовать с app-table-comp если используется чекбокс
   * @param event
   */
  callbackSelectedRow(event) {
    this.onSelectItem(null, event[0]);
  }

  updateDocumentType() {
    this.itemDescriptor = {...this.itemDescriptor, params: {...this.itemDescriptor.params, typePage: this.documentType}};
  }
}
