import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class GlobalDataProviderService {
    public roleAccessModel = new Subject();

    private importAttachments$ = new Subject<boolean>();
    private serverResponseDataSub = new Subject();
    serverResponseDataObs$ = this.serverResponseDataSub.asObservable();
    importAttachmentObs = this.importAttachments$.asObservable();
    setServerResponseDataSub(value) {
      this.serverResponseDataSub.next(value);
    }

    setImportAttachments() {
      this.importAttachments$.next(true);
    }
}
