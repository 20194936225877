import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentSubForm, DocumentTypeEnum} from "../../../../../../../../documentForm";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {ViewInfoCreditOrgV1Component} from "../../view/view-info-credit-org-v1/view-info-credit-org-v1.component";

@Component({
  selector: 'app-table-info-credit-org-v1',
  templateUrl: './table-info-credit-org-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableInfoCreditOrgV1Component extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() documentType = DocumentTypeEnum.OPERATIONS_1_0;
  viewForm = ViewInfoCreditOrgV1Component;
  pageSize = 10;
  page = 0;

  constructor(
      protected readonly modalService: NgbModal,
      private readonly docSrv: DocumentFmV1Service
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.currentGetFormGroup = this.getFormGroup();

    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }

  getFormGroup() {
    switch (this.documentType) {
      case DocumentTypeEnum.REFUSE_CONTRACT_1_1:
      case DocumentTypeEnum.REFUSE_CONTRACT_1_2:
      case DocumentTypeEnum.REFUSE_OPERATION_1_2:
      case DocumentTypeEnum.REFUSE_OPERATION_1_1:
        return (data) => this.docSrv.getSvedPredstavV1.call(this.docSrv, data, null, this.documentType);

      case DocumentTypeEnum.OPERATIONS_1_0:
      case DocumentTypeEnum.OPERATIONS_1_1:
      case DocumentTypeEnum.OPERATIONS_1_2:
        return (data) => this.docSrv.getOperationDetailsV1.call(this.docSrv, data, null, this.documentType);

      default:
        break;
    }
  }

  get isOperations(){
    return this.documentType === DocumentTypeEnum.OPERATIONS
  }

  get isSuspiciousActivity(){
    return this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
  }

}
