import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable, ITableCallBack} from "@amlCore/components";
import {ViewInfoCreditOrg} from '../views';
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";


@Component({
  selector: 'app-armfm-inf-res-ver-table-info-org-v1',
  templateUrl: './table-ir-info-credit-org.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableIrInfoCreditOrg extends DocumentSubForm implements OnInit, ITable {
  @Output() confirmWithoutChanges = new EventEmitter<{isConfirmBack: boolean | undefined, documentData: any[]}>();
  documentType = DocumentTypeEnum.VERIFICATION_RESULT;
  viewForm = ViewInfoCreditOrg;
  keyData = 'proverka';
  total = 0;
  pageSize = 10;
  page = 0;

  constructor(protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService) {
    super(modalService)
  }

  currentGetFormGroup = (data) => this.docSrv.getSvedKo.call(this.docSrv, data);

  ngOnInit(): void {
    super.ngOnInit();
  }
  onPageChange(e) {}

  callbackItemClosed(event: ITableCallBack): void {
    super.callbackItemClosed(event);
    if (!event.isConfirmBack) {
      this.documentData = this.controlFormGroupApp.value.map((item) => {
        return {priznakPredstavSved: `${item?.priznakPredstavSved}`, infFilial: item?.infFilial || null, proverka: item?.proverka};
      })
      this.documentData = this.documentData.filter(item => item.proverka.length);
    }
    this.confirmWithoutChanges.emit({isConfirmBack: event.isConfirmBack, documentData: this.documentData});
  }
}
