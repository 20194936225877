import {DocumentTypeEnum} from '../enum/documentTypeEnum';

export interface DocTypeSearch {
    id: number;
    name: string;
    title: string;
}

export interface DocType {
    [key: string]: DocTypeSearch[];
}

interface FilterDescription {
    [key: string]: Object;
}

// ToDo доплнять при необходимости
const docTypesConstants: DocType = {
    strateg655p: [
        {
            id: 18,
            name: DocumentTypeEnum.SD_KO_OTKR_ZAKR_CHET_DEPO,
            title: "3732-У. ЭС об открытии(закрытии) счета депо",
        },
        {
            id: 19,
            name: DocumentTypeEnum.SD_KO_SECURITIES,
            title: "3732-У. ЭС о приобретении(отчуждении) ценных бумаг",
        },
        {
            id: 20,
            name: DocumentTypeEnum.SD_KO_IZM_CHET_DEPO,
            title: "3732-У. ЭС об изменении реквизитов счета депо",
        },
        {
            id: 21,
            name: DocumentTypeEnum.SD_KO_OTKR_ZAKR_AKKR,
            title: "3732-У. ЭС об открытии(закрытии) покрытого (депонированного) аккредитива",
        },
        {
            id: 22,
            name: DocumentTypeEnum.SD_KO_IZM_AKKRED,
            title: "3732-У. ЭС об изменении реквизитов покрытого (депонированного) аккредитива",
        },
        {
            id: 23,
            name: DocumentTypeEnum.SD_KO_IZM_CHET,
            title: "2446-У. Электронное сообщение об изменении счета",
        },
        {
            id: 24,
            name: DocumentTypeEnum.SD_KO_IZM_CHET_GOZ,
            title: "Сообщение об изменении реквизитов отдельного счета (ГОЗ)",
        },
        {
            id: 25,
            name: DocumentTypeEnum.SD_KO_OTKR_ZAKR_CHET,
            title: "2446-У. Электронное сообщение об открытии/закрытии счета",
        },
        {
            id: 26,
            name: DocumentTypeEnum.SD_KO_OTKR_ZAKR_CHET_GOZ,
            title: "Сведение банка об открытии (закрытии) отдельного счета (ГОЗ)",
        },
    ],
    strateg5392u: [{ id: 17, name: DocumentTypeEnum.OES_OTKAZ_GOZ, title: "5392-У. ОЭС об отказе ГОЗ" }],
    fm: [
        { id: 0, name: DocumentTypeEnum.OPERATIONS, title: "5861-У. Сведения об операциях" },
        {
            id: 1,
            name: DocumentTypeEnum.OBSTRUCTION_FACTS,
            title: "5861-У. ФЭС о фактах воспрепятствования",
        },
        {
            id: 2,
            name: DocumentTypeEnum.SUSPICIOUS_ACTIVITY,
            title: "5861-У. ФЭС о подозрительной деятельности",
        },
        {
            id: 3,
            name: DocumentTypeEnum.REFUSE_CONTRACT,
            title: "5861-У. Отказ от заключения договора (расторжение)",
        },
        {
            id: 4,
            name: DocumentTypeEnum.REFUSE_OPERATION,
            title: "5861-У. Отказ в совершении операции",
        },
        {
            id: 5,
            name: DocumentTypeEnum.VERIFICATION_RESULT,
            title: "5861-У. Сведения о результатах проверки",
        },
        {
            id: 6,
            name: DocumentTypeEnum.FREEZING_FUNDS,
            title: "5861-У. Сведения о принятых мерах по замораживанию (блокированию)",
        },
    ],
    elMsgFm: [
        { id: 10, name: DocumentTypeEnum.P407_ANSWER, title: "Электронное сообщение" },
        // по согласованию с Андрей Смирнов (01.10.2024) - Электронное сообщение Росфинмониторинга пока не добавляем
        // лишний тип документа в 407 (?)
        // {
        //     id: 0,
        //     name: "REGISTRY_REPORT",
        //     title: "Электронное сообщение Росфинмониторинга",
        // },
        {
          id: 9,
          name: DocumentTypeEnum.P407_REQUESTS,
          title: "Квитанция о принятии/непринятии электронного сообщения Росфинмониторинга",
      },
    ],
    svetofor: [
        {id: 7, name: DocumentTypeEnum.REGISTRY_MEASURES, title: '794-П. Реестр принятых мер'},
        {id: 8, name: DocumentTypeEnum.REGISTRY_CLIENTS, title: '794-П. Реестр клиентов кредитной организации'}
    ],
    control6670: [
        {
            name: DocumentTypeEnum.SD_KONFO_ECONOMIC_MEASURE,
            id: 27,
            title: "6670-У. ЭС об экономических мерах"
        }
    ]
};

// ToDo доплнять при необходимости
const docStatuses: DocType = {
    created: [{ id: 0, name: "CREATED", title: "Создан" }],
    deleted: [{ id: 8, name: "DELETED", title: "Удален" }],
    fmSend: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    fmArchive: [
      { id: 2, name: "SENDED", title: "Отправлен" },
      { id: 3, name: "ACCEPTED", title: "Принят" },
      { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
      { id: 9, name: "FROM_EXTERNAL", title: "Принят из ВС" },
      { id: 5, name: "REJECTED", title: "Отвергнут" },
      { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    strategSend: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    strateg655pArchive: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 9, name: "FROM_EXTERNAL", title: "Принят из ВС" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    strateg5392uArchive: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 9, name: "FROM_EXTERNAL", title: "Принят из ВС" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    elMsgFmArchive: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 99, name: "ACCEPTEDTU", title: "Принят ТУ" },
        { id: 98, name: "REJECTEDTU", title: "Отвергнут ТУ" },
        { id: 4, name: "ACCEPTEDPART", title: "Принят частично" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
    svetoforDeleted: [{ id: 8, name: "DELETED", title: "Удален" }],
    control6670Archive: [
        { id: 2, name: "SENDED", title: "Отправлен" },
        { id: 3, name: "ACCEPTED", title: "Принят" },
        { id: 9, name: "FROM_EXTERNAL", title: "Принят из ВС" },
        { id: 5, name: "REJECTED", title: "Отвергнут" },
        { id: 6, name: "UPLOADEDSHIPPING", title: "Выгружен для отправки" },
    ],
};

const filterDescription: FilterDescription = {
    strateg655pArchive: {
        documentType: "Документ",
        documentStatus: "Статус",
        dateFrom: "Дата сообщения с",
        dateTo: "Дата сообщения по",
        creatorId: "Владелец документа",
        containerName: 'Наименование транспортного контейнера',
    },
    strateg5392uArchive: {
      documentType: "Документ",
      documentStatus: "Статус",
      dateFrom: "Дата сообщения с",
      dateTo: "Дата сообщения по",
      creatorId: "Владелец документа",
    },
    fmArchive: {
      documentType: "Документ",
      documentStatus: "Статус",
      dateFrom: "Дата сообщения с",
      dateTo: "Дата сообщения по",
      creatorId: "Владелец документа",
      identify: "Идентификатор",
      containerName: "Контейнер",
    },
    elMsgFmArchive: {
      documentType: "Документ",
      documentStatus: "Статус",
      dateFrom: "Дата сообщения с",
      dateTo: "Дата сообщения по",
      creatorId: "Владелец документа",
    },
    control6670Archive: {
        documentType: "Документ",
        documentStatus: "Статус",
        dateFrom: "Дата сообщения с",
        dateTo: "Дата сообщения по",
        creatorId: "Владелец документа",
        identify: "Идентификатор",
        containerName: "Контейнер",
    }
};

const importArchiveModule = {
    strateg5392u: {
        strategistArchiveLimited: 'strategist-archive-limited',
        strategistArchiveFull: 'strategist-archive-full',
    },
    strateg655p: {
        strategistArchiveLimited: 'strategist-archive-limited',
        strategistArchiveFull: 'strategist-archive-full',
    },
    fm: {
        controlArchiveLimited: 'control-archive-limited',
        controlArchiveFull: 'control-archive-full',
    },
};

export const getDocTypes = (type: string) => docTypesConstants[type] || [];
export const getDocStatuses = (type: string) => docStatuses[type] || [];
export const getFilterDesc = (type: string) => filterDescription[type] || [];
export const getImportArchiveModule = (type: string) => importArchiveModule[type] || {};
