import {Component} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ITableItem} from "@amlCore/components";
import {DocumentBaseService, DocumentComponentBaseSub} from "../../../../../../../documentForm";

@Component({
  selector: 'app-armfm-v-sved-reject-v1',
  templateUrl: './view-tr-sved-reject.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewTrSvedReject extends DocumentComponentBaseSub implements ITableItem {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Сведения о случаях отказа`;
  priznValOperatsii = [{name: '', code: null}, {name: 'VO', code: 'VO'}];
  constructor(private readonly docSrv: DocumentBaseService) {
      super();
  }
  currentGetForm = (data) => this.docSrv.getSvedOtkaz(data, null, this.documentType);

  isValidItem(): boolean {
    return this.isFormGroupValidExcludingControls(['uchastnik', 'osnovanieOp', 'svedIuL', 'svedFLIP', 'svedINBOIuL']);
  }

  genIdentify(data) {
    this.field('nomerZapisi').setValue(data);
    this.field('nomerZapisi').markAsDirty();
  }
}
