/**
 * Типы возможных валидаций
 */
export enum ValidatorEnum {
  DATEPATTERN = 'datePattern',
  DATEPATTERNORZERO = 'datePatternOrZero',
  DATERANGE = 'dateRange',
  TIMEPATTERN = 'timepattern',
  INN = 'inn',
  OKPO = 'okpo',
  OGRN = 'ogrn',
  SNILS = 'snils',
  BIKKO = 'BIKKO',
  REGION = 'region',
  OKTMO = 'oktmo',
  PolicyOMC = 'PolicyOMC',
  PolicyOMC9or16 = 'PolicyOMC9or16',
  IdentifikatorPD28or30 = 'IdentifikatorPD28or30',
  InnKio5or10 = 'InnKio5or10',
  IDLK = 'isIdLk',
  Swift2or8or11 = 'Swift2or8or11',
  KPP = 'KPP',
  OGRN13or15or50 = 'OGRN13or15or50',
  VptelFaksPattern = 'VptelFaksPattern',
  kodOtkaza07or08or80 = 'kodOtkaza07or08or80',
  idenGosKontrakt = 'idenGosKontrakt'
}
