import { BaseFilterModel } from "./baseFilter.model";

export class CommonArchiveFilter extends BaseFilterModel {
    documentType = null;
    documentStatus = null;
    dateFrom? = null;
    dateTo? = null;
    signDateFrom? = null;
    signDateTo? = null;
    createDateFrom? = null;
    createDateTo? = null;
    sendDateFrom? = null;
    sendDateTo? = null;
    requestDateFrom? = null;
    requestDateTo? = null;
    ticketDateFrom? = null;
    ticketDateTo? = null;
    containerName? = null;
    creatorId? = null;
    inn? = null;
    identify? = null;
}
