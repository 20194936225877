import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {ITableItem} from "@amlCore/components";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";

@Component({
  selector: 'app-view-trans-info-v1',
  templateUrl: './view-trans-info-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewTransInfoV1Component extends DocumentComponentBaseSub implements OnInit, ITableItem, OnDestroy {
  formGroupApp: FormGroup;
  documentData: any;
  clientType = new FormControl(1);
  titlePanel = `Сведения в отношении трастов и иных ИНБОЮЛ`;
  private destroy$ = new Subject();
  constructor(private readonly docSrv: DocumentFmV1Service,
              private change: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.clientType.patchValue(this.formGroupApp.value?.naimUchreditel?.iurLitso ? 1 : 2);
    if (this.isReadOnly)
      this.clientType.disable();

    this.handleIurLitsoValidation();
    this.clientType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.handleIurLitsoValidation());
  }

  currentGetForm = (param) => this.docSrv.getUchreditelOper(param, null, this.documentType);

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  handleIurLitsoValidation() {
    const iurLitsoControl = this.field('naimUchreditel.iurLitso');
    this.clientType.value === 1 ?
        iurLitsoControl.setValidators([Validators.required, Validators.maxLength(1000)]) :
        (iurLitsoControl.clearValidators(), iurLitsoControl.setErrors(null));
  }

  changeType(type: number) {
    this.clientType.patchValue(type);
    this.change.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
