import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {CertificateUsers} from "@amlCore/interfaces";
import { MchdService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpParams } from '@angular/common/http';
import { IconsService, ApiService } from '@amlCore/services';


@Component({
  selector: 'app-cert-table',
  templateUrl: './certificateTable.component.html'
})
export class CertificateTableComponent implements OnInit, OnDestroy {
  @Input()
  certs: CertificateUsers[] = [];
  loading = false;
  accept = '.zip';
  file: File;
  errorMsg: string;
  modal: NgbModalRef;
  successMsg: string;
  @ViewChild('modal') private modalRef: TemplateRef<any>;
  @ViewChild('resultModal') private resultModal: TemplateRef<any>;
  @Output() needRefreshCerts: any = new EventEmitter<void>();
  icons = this._iconsService.getIcons();
  destroy$ = new Subject();


  constructor(private mchdService: MchdService, private modalDialog: NgbModal,  private apiService: ApiService,  private _iconsService: IconsService) {}

  ngOnInit(): void { }
  
  getUploadMCHDUrl(certThumb: string) {
    return `/api/v1/authad/mchd/${certThumb}`;
  }

  getFile(file) {
    this.file = file[0];
  }

  uploadMCHD(data) {
    this.errorMsg = null;
    this.successMsg = null;
    this.successMsg = 'МЧД была успешно добавлена';
    this.needRefreshCerts.emit();  
    this.modalDialog.open(this.resultModal, {
      centered: true
    });
  }

  callBackError(error, certThumb: string) {

    this.successMsg = null;
    this.errorMsg = null;

    if (error.errorCode !== 48) {
      this.errorMsg = error.error;

      this.modalDialog.open(this.resultModal, {
        centered: true
      });
    }

    if (error.errorCode === 48) {
      this.modal = this.modalDialog.open(this.modalRef, {
        centered: true,
        backdrop: "static",
      });

      this.modal.result.then(() => {
        const params = new HttpParams().set('force', 'true');
        const formData: FormData = new FormData();
        formData.append('uploadFile', this.file);
        this.apiService.sendFormData(this.getUploadMCHDUrl(certThumb), formData, params).pipe(takeUntil(this.destroy$)).subscribe({
          next: (data) => {
            this.successMsg =  'МЧД была успешно добавлена';
            this.needRefreshCerts.emit();
            this.modalDialog.open(this.resultModal, {
              centered: true
            });
          },
          error: (err) => {
            this.errorMsg = err.error;

            this.modalDialog.open(this.resultModal, {
              centered: true
            });
          },
        });
      }, () => { });
    }
  }

  downloadMCHD(certThumb: string) {
    this.loading = true;
    this.successMsg = null;
    this.errorMsg = null;
    
    this.mchdService.downloadMCHD(certThumb).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        this.loading = false;
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          this.loading = false;
        }, 0);
      },
      error: (err) => {
        this.loading = false;

        if(err.error instanceof Blob) {
          err.error.text().then(text => {
            this.errorMsg = (JSON.parse(text).message);
          });
        } else {
          this.errorMsg = err.error;
        }


        this.modalDialog.open(this.resultModal, {
          centered: true
        });
      }
    });
  }

  deleteMCHD(certThumb: string) {
    this.loading = true;
    this.successMsg = null;
    this.errorMsg = null;

    this.mchdService.deleteMCHD(certThumb).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        this.loading = false;
        this.successMsg = 'МЧД была успешно удалена';
        this.needRefreshCerts.emit();

        this.modalDialog.open(this.resultModal, {
          centered: true
        });

      },
      error: (err) => {
        this.loading = false;
        this.errorMsg = err.error;

        this.modalDialog.open(this.resultModal, {
          centered: true
        });

      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

