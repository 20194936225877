import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService, DocumentBaseService} from "@amlDocument/services"
import {DocumentBase, DocumentTypeEnum} from "../../../../../documentForm";

@Component({
  selector: 'app-armfm-obstruction-fact-5861u',
  templateUrl: './obstruction-fact5861U.component.html',
  styleUrls: ['../../../../../documentForm/base/documentForm.component.scss']
})
export class ObstructionFact5861UComponent extends DocumentBase implements OnInit {
  readonly documentType = DocumentTypeEnum.OBSTRUCTION_FACTS;
  form: FormGroup;

  constructor(protected documentBaseService: DocumentBaseService,
              protected iconsService: IconsService,
              protected commonSrv: CommonService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router,
              protected cdr: ChangeDetectorRef) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initForm(): void {
    const obstructionFacts = this.documentBaseService.getObstructionFacts(this.d.informChast?.faktVosprepiat);
    this.fGroup('informChast').addControl('faktVosprepiat', obstructionFacts);
    this.cdr.detectChanges()
  }

  confirmWithoutChanges(data: {isConfirmBack: boolean | undefined, documentData: any[]}) {
    this.documentData.document.informChast.faktVosprepiat = data.documentData;
    const obstructionFacts = this.documentBaseService.getObstructionFacts(this.d.informChast?.faktVosprepiat);
    const informChastGroup = this.fGroup('informChast');
    informChastGroup.removeControl('faktVosprepiat');
    informChastGroup.addControl('faktVosprepiat', obstructionFacts);
  }
}
