import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentSubForm, DocumentTypeEnum} from "../../../../../../../../documentForm";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {ViewSvedOperV1Component} from "../../view/view-sved-oper-v1/view-sved-oper-v1.component";

@Component({
  selector: 'app-table-sved-oper-v1',
  templateUrl: './table-sved-oper-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableSvedOperV1Component extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() documentType = DocumentTypeEnum.OPERATIONS_1_0; // todo сделать универсальным
  viewForm = ViewSvedOperV1Component;

  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentFmV1Service) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getOperatsiiaFmV1.call(this.docSrv, data, null, this.documentType);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }
}
