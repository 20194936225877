import {Component} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {ITableItem} from "@amlCore/components";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {DocumentTypeEnum} from "../../../../../../../../documentForm";

@Component({
  selector: 'app-view-tr-sved-reject-v1',
  templateUrl: './view-tr-sved-reject-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewTrSvedRejectV1Component extends DocumentComponentBaseSub implements ITableItem {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Сведения о случаях отказа`;
  constructor(private readonly docSrv: DocumentFmV1Service) {
    super();
  }
  currentGetForm = (data) => this.docSrv.getSvedOtkazV1(data, null, this.documentType);

  isValidItem(): boolean {
    return this.isFormGroupValidExcludingControls(['uchastnik', 'osnovanieOp', 'svedIuL', 'svedFLIP', 'svedINBOIuL']);
  }

  genIdentify(data) {
    this.field('nomerZapisi').setValue(data);
    this.field('nomerZapisi').markAsDirty();
  }

  get isRefuseOperation1_2() {
    return this.documentType === DocumentTypeEnum.REFUSE_OPERATION_1_2;
  }
}
