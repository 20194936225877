import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as directives from "./directives";
import * as components from "./components";
import {UserComponent} from "./components";
import * as services from "./services";
import * as pipes from "./pipes";
import * as liteComponent from "./liteComponent";
import * as filterComponent from "./filterComponents";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {AlertModalComponent, ConfirmComponent} from "./modals";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {NgxMaskModule} from "ngx-mask";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FileSaverModule} from "ngx-filesaver";
import {UiSwitchModule} from "ngx-ui-switch";
import {CryptographyModule} from "../cryptography";
import { MoveToSendComponent } from './components/move-to-send/move-to-send.component';
import {SaveFileDirective} from "./directives";
import {DateFormatPipe} from "./pipes";
import {CommonControl6670FilterComponent, UserSessionFilterComponent} from "./filterComponents";

@NgModule({
  imports: [
  CryptographyModule,
    FontAwesomeModule,
    CommonModule,
    FileSaverModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    NgxMaskModule.forRoot(),
    UiSwitchModule.forRoot({
      size: 'medium',
      color: '#ae0000',
    }),
    NgbModule
  ],
  providers: [
    services.AuthGuard,
    services.JwtService,
    services.ResourceService,
    services.ApiService,
    services.AccessRouteGuard,
    services.ValidatorService,
    services.DictionaryService,
    services.IconsService,
    services.UXService,
    liteComponent.FiasService,
    components.AlertService,
    components.AlertPanelService,
    services.VisitedService,
    services.StringDateAdapterService,
    services.GlobalDataProviderService,
  ],
  declarations: [
    components.DatepickerComponent,
    components.DatetimepickerComponent,
    components.AlertPanelComponent,
    components.MultipleFiledComponent,
    components.MultipleFiledDescriptionComponent,
    components.CustomSelectComponent,
    components.CustomSourceSelectComponent,
    components.CustomCheckboxComponent,
    components.JoinedMultiCheckboxComponent,
    components.TableCompComponent,
    components.TableEditorComponent,
    components.AlertComponent,
    components.PaginatorComponent,
    components.ScrollBtnComponent,
    components.BigSelectComponent,
    components.BigSelectViewComponent,
    components.GoBackComponent,
    components.SvgIconComponent,
    components.FlkErrorPanelComponent,
    components.SignComponent,
    components.SignSvetoforComponent,
    components.SignElMsgComponent,
    components.DeleteComponent,
    components.UserComponent,
    components.SaveErrorModalComponent,
    components.SkeletonComponent,
    directives.ShowAuthDirective,
    directives.CryptCheckStateDirective,
    directives.SortTableDirective,
    directives.AutoFocusDirective,
    directives.AutoFocusFlkDirective,
    directives.ShowElByAccessDirective,
    directives.ConfirmActionDirective,
    directives.InputFormatDirective,
    directives.HighlightDirective,
    directives.MoneyDirective,
    pipes.GetFirstWord,
    pipes.TruncatePipe,
    pipes.CurrencyRubPipe,
    liteComponent.FiasComponent,
    liteComponent.LoadingComponent,
    liteComponent.RqComponent,
    liteComponent.SortComponent,
    liteComponent.ValidComponent,
    liteComponent.AccordionCaretComponent,
    liteComponent.InputFileComponent,
    ConfirmComponent,
    AlertModalComponent,
    filterComponent.FromuInfoFilterComponent,
    filterComponent.TerroristFilterComponent,
    filterComponent.FromuListFilterComponent,
    filterComponent.FmDraftListFilterComponent,
    filterComponent.RequestsgaUnstrustFilterComponent,
    filterComponent.FmWaitListFilterComponent,
    filterComponent.FmSendListFilterComponent,
    filterComponent.SvetoforDraftListFilterComponent,
    filterComponent.SvetoforWaitListFilterComponent,
    filterComponent.SvetoforSendListFilterComponent,
    filterComponent.SvetoforIncomeListFilterComponent,
    filterComponent.RejectionIncomeListFilterComponent,
    filterComponent.RejectionWaitListFilterComponent,
    filterComponent.RejectionSendListFilterComponent,
    filterComponent.RejectionDeletedListFilterComponent,
    filterComponent.MvkInfoFilterComponent,
    filterComponent.MvkListFilterComponent,
    filterComponent.BounceRegisterAcceptedFilterComponent,
    filterComponent.BounceRegisterListFilterComponent,
    filterComponent.InternalListFilterComponent,
    filterComponent.AuditFilterComponent,
    filterComponent.SearchInputFilterComponent,
    filterComponent.SubjectComponent,
    filterComponent.RegisterSubjectComponent,
    filterComponent.PassportInfoFilterComponent,
    filterComponent.ElMsgFmDraftListFilterComponent,
    filterComponent.ElMsgFmIncomeListFilterComponent,
    filterComponent.ElMsgFmSendsReadyListFilterComponent,
    filterComponent.ElMsgFmSendedListFilterComponent,
    filterComponent.Arm4937DraftListFilterComponent,
    filterComponent.AmlStrategSendedListFilterComponent,
    filterComponent.AmlStrategDraftListFilterComponent,
    filterComponent.AmlStrateg5392uDraftListFilterComponent,
    filterComponent.CommonArchiveFilterComponent,
    filterComponent.CommonDeletedFilterComponent,
    filterComponent.CommonControl6670FilterComponent,
    MoveToSendComponent,
    directives.SaveFileDirective,
    MoveToSendComponent,
    pipes.DateFormatPipe,
    filterComponent.UserSessionFilterComponent,
    components.UserFormComponent,
    components.ImportDictionaryComponent,
    components.CopyNotificationComponent,
    components.GenIdentifierBtnComponent,
    components.DownloadRequestFormatComponent
  ],
  entryComponents: [
    ConfirmComponent,
    components.TableEditorComponent,
    components.BigSelectViewComponent,
    components.SignComponent,
    components.SignSvetoforComponent,
    components.SignElMsgComponent,
    components.DeleteComponent,
  ],
  exports: [
    FontAwesomeModule,
    components.AlertComponent,
    components.SvgIconComponent,
    components.PaginatorComponent,
    components.SkeletonComponent,
    pipes.GetFirstWord,
    pipes.TruncatePipe,
    pipes.CurrencyRubPipe,
    CommonModule,
    FormsModule,
    liteComponent.SortComponent,
    liteComponent.FiasComponent,
    liteComponent.LoadingComponent,
    liteComponent.RqComponent,
    liteComponent.ValidComponent,
    liteComponent.AccordionCaretComponent,
    liteComponent.InputFileComponent,
    ReactiveFormsModule,
    RouterModule,
    directives.SortTableDirective,
    directives.ShowElByAccessDirective,
    directives.ConfirmActionDirective,
    directives.AutoFocusDirective,
    directives.AutoFocusFlkDirective,
    directives.ShowAuthDirective,
    directives.CryptCheckStateDirective,
    directives.InputFormatDirective,
    directives.HighlightDirective,
    directives.MoneyDirective,
    components.DatepickerComponent,
    components.DatetimepickerComponent,
    components.AlertPanelComponent,
    components.MultipleFiledComponent,
    components.MultipleFiledDescriptionComponent,
    components.CustomSelectComponent,
    components.CustomCheckboxComponent,
    components.JoinedMultiCheckboxComponent,
    components.CustomSourceSelectComponent,
    components.TableCompComponent,
    components.ScrollBtnComponent,
    components.BigSelectComponent,
    components.GoBackComponent,
    filterComponent.FromuInfoFilterComponent,
    filterComponent.FmDraftListFilterComponent,
    filterComponent.RequestsgaUnstrustFilterComponent,
    filterComponent.FmWaitListFilterComponent,
    filterComponent.FmSendListFilterComponent,
    filterComponent.SvetoforDraftListFilterComponent,
    filterComponent.SvetoforWaitListFilterComponent,
    filterComponent.SvetoforSendListFilterComponent,
    filterComponent.SvetoforIncomeListFilterComponent,
    filterComponent.RejectionIncomeListFilterComponent,
    filterComponent.RejectionWaitListFilterComponent,
    filterComponent.RejectionSendListFilterComponent,
    filterComponent.RejectionDeletedListFilterComponent,
    filterComponent.TerroristFilterComponent,
    filterComponent.FromuListFilterComponent,
    filterComponent.MvkInfoFilterComponent,
    filterComponent.MvkListFilterComponent,
    filterComponent.BounceRegisterAcceptedFilterComponent,
    filterComponent.BounceRegisterListFilterComponent,
    filterComponent.InternalListFilterComponent,
    filterComponent.AuditFilterComponent,
    filterComponent.SearchInputFilterComponent,
    filterComponent.SubjectComponent,
    filterComponent.RegisterSubjectComponent,
    filterComponent.PassportInfoFilterComponent,
    filterComponent.ElMsgFmDraftListFilterComponent,
    filterComponent.ElMsgFmIncomeListFilterComponent,
    filterComponent.ElMsgFmSendsReadyListFilterComponent,
    filterComponent.ElMsgFmSendedListFilterComponent,
    filterComponent.Arm4937DraftListFilterComponent,
    filterComponent.AmlStrategSendedListFilterComponent,
    filterComponent.AmlStrategDraftListFilterComponent,
    filterComponent.AmlStrateg5392uDraftListFilterComponent,
    filterComponent.CommonArchiveFilterComponent,
    filterComponent.CommonDeletedFilterComponent,
    SaveFileDirective,
    components.SaveErrorModalComponent,
    UserComponent,
    DateFormatPipe,
    UserSessionFilterComponent,
    components.UserFormComponent,
    components.ImportDictionaryComponent,
    components.CopyNotificationComponent,
    components.GenIdentifierBtnComponent,
    CommonControl6670FilterComponent,
    components.DownloadRequestFormatComponent
  ]
})
export class CoreModule {
  /**
   * Для lazy-module создается новый экземпляр сервиса. Данная конструкция необходима что сервис был один на все модули.
   * https://angular.io/guide/singleton-services
   */
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: services.UserService },
        { provide: services.SharedMenuService },
        { provide: services.TableService }
      ],
    };
  }
}

