import {Component, OnInit} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewSvedRefuse} from "../view";
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";
import { ITable } from "src/app/core/components/tableComp/ITable";

@Component({
    selector: "app-armfm-table-sved-refuse-v2",
    templateUrl: "./table-sved-refuse.html",
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableSvedRefuse extends DocumentSubForm implements OnInit, ITable {
    documentType = DocumentTypeEnum.REFUSE_CONTRACT;
    viewForm = ViewSvedRefuse;
    pageSize = 5;
    page = 0;

    constructor(
        protected readonly modalService: NgbModal,
        private readonly docSrv: DocumentBaseService
    ) {
        super(modalService);
    }

    currentGetFormGroup = (data) => this.docSrv.getSvedOtkaz.apply(this.docSrv, [data, null, this.documentType]);

    ngOnInit() {
        super.ngOnInit();
    }

    onPageChange(e) { }
}
