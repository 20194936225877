import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";
import {DocumentTypeEnum} from "@amlCore/enums";
import {ViewTransInfoV1Component} from "../../view";

@Component({
  selector: 'app-table-trast-info-v1',
  templateUrl: './table-trast-info-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableTrastInfoV1Component extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() readonly formGroupApp: FormArray;
  @Input() readonly documentData: any;
  @Input() readonly documentType: DocumentTypeEnum;
  viewForm = ViewTransInfoV1Component;

  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentFmV1Service) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getUchreditelOper.call(this.docSrv, data, null, this.documentType);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }
}
