import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {AccessJson, FmDraftFilter} from "@amlCore/models";
import {RoleService, TableService, VisitedService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {FmFilterService, FmService} from "../../service";
import {FmList} from "../../model/FmList.model";
import {IDataResponse} from "@amlCore/interfaces";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {CommonService} from "@amlDocument/services";

@Component({
  selector: 'app-armfm-fm-income-list',
  templateUrl: './fm-income-list.component.html'
})
export class FmIncomeListComponent extends TableReactiveUrlController implements OnInit {

  private readonly _MODAL_CONFIG = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;

  isShowVisited = true;

  constructor(protected fmSrv: FmService,
              private fmFilterSrv: FmFilterService,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected modalDialog: NgbModal,
              protected tableService: TableService,
              protected router: Router,
              protected commonService: CommonService,
              protected roleService: RoleService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'messageDate', dir: 'asc'},
        null, null, null, null, null, null, null, commonService, roleService);
  }

  dataList: Array<FmList> = [];
  isAll = true;
  id: string;
  access: AccessJson;
  goBack = Utils.goBack;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.activatedRoute.queryParams.subscribe(
        data => {
          this.isAll = !this.id;
          const paramsData = this.syncParams(data);
          paramsData.id = this.id;
          this.loadList(paramsData);
          // закоменчено в рамках исправления ошибки с пагинацией AML-3247
          //this.total = [] // убрать после ...
        });
    });
    this.fmFilterSrv.loadUserAccessList();
    super.ngOnInit()
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data: FmDraftFilter) {
    const filter = this.tableService.getFilterSubject(data) as FmDraftFilter;
    return this.getFilterPlanObject(filter);
  }

  public onPageChange(params) {
    const filter = this.getParams({...params, ...this.filter}) as FmDraftFilter;
    filter['isAll'] = this.isAll;
    this.navigate(filter);
  }

  public onSearch() {
    const params = this.getSearchPage(this.filter) as FmDraftFilter;
    this.onPageChange(params);
  }

  private loadList(params?: any) {
    this.fmSrv.getFMIncomeList(this.getParams(params)).subscribe((data: IDataResponse<FmList>) => {
      this.dataList = data.content;
      this.total = data.totalElements;
      this.resetSelectedAll();
    });

  }

}


