import {Component, Input} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum, FM_DOCS_1_X, REFUSE_CONTRACT_1_X, REFUSE_OPERATION_1_X} from '../../../enums';

@Component({
    selector: 'app-arm-doc-view-info-ul',
    templateUrl: './view-info-ul.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewInfoUlComponent extends DocumentComponentBaseSub {
    @Input() readonly formGroupApp: FormGroup;
    @Input() readonly documentData: any;
    @Input() readonly documentType: any;
    @Input() readonly isVersiiaFormata1x = false;
    @Input() readonly isNeedOnlyCountry = false;

    documentTypeEnum = DocumentTypeEnum;

    constructor(readonly docSrv: DocumentBaseService) {
        super();
    }

    get prizn() {
      return this.priznKlient ?? this.priznUchastnika;
    }

    get priznKlient() {
      return this.isRefuseOperation1x ?
      this.formGroupApp.parent?.get('priznakKlienta')?.value :
      this.formGroupApp.parent?.parent?.get('priznKlient')?.value
    }

    get priznUchastnika() {
      return this.formGroupApp.parent?.parent?.get('priznUchastnika')?.value;
    }

    get isPriznakFlandReg() {
        return ![DocumentTypeEnum.REFUSE_OPERATION_1_1, DocumentTypeEnum.REFUSE_OPERATION_1_2,
            DocumentTypeEnum.REFUSE_CONTRACT_1_1].includes(this.documentType);
    }

    get isDataAkkreditatsii() {
        return !REFUSE_OPERATION_1_X.includes(this.documentType) && !REFUSE_CONTRACT_1_X.includes(this.documentType);
    }

    get isRefuseOperation1x() {
        return REFUSE_OPERATION_1_X.includes(this.documentType);
    }

    get isRefuseContract1x() {
      return REFUSE_CONTRACT_1_X.includes(this.documentType);
  }
}
