/**
 * Типы документов
 */
export enum DocumentTypeEnum {
  VERIFICATION_RESULT = "VERIFICATION_RESULT", // Сведения о результатах проверки
  FREEZING_FUNDS = "FREEZING_FUNDS", // Сведения о принятых мерах по замораживанию (блокированию)
  REFUSE_OPERATION = "REFUSE_OPERATION", //  Отказ в совершении операции
  OPERATIONS = "OPERATIONS", // Сведения об операциях
  OBSTRUCTION_FACTS = "OBSTRUCTION_FACTS", // ФЭС о фактах воспрепятствования
  SUSPICIOUS_ACTIVITY = "SUSPICIOUS_ACTIVITY", // Сведения о подозрительной деятельности
  REFUSE_CONTRACT = "REFUSE_CONTRACT", // Отказ от заключения договора (расторжение)
  REGISTRY_MEASURES = "REGISTRY_MEASURES", // Реестр принятых мер
  REGISTRY_CLIENTS = "REGISTRY_CLIENTS", // Реестр клиентов кредитной организации
  RISK_REPORT = "RISK_REPORT", // Реестр клиентов кредитной организации
  REGISTRY_REJECTION = "REGISTRY_REJECTION", // Реестр отказов(Реестр 764-П)
  KVIT_REJECTION = "KVIT_REJECTION", // Реестр отказов(Реестр 764-П)
  KVIT_ARJ_REJECTION = "KVIT_ARJ_REJECTION", // Реестр отказов(Реестр 764-П)
  P407_REQUESTS = "P407_REQUESTS", // Запрос 407 (Квитанции)
  P407_ANSWER = "P407_ANSWER", // Ответ 407
  REQUEST_171 = "REQUEST_171", // Запрос
  NFO_OPERATIONS = "NFO_OPERATIONS", // ФЭС об операциях, подлежащих обязательному контролю
  NFO_VERIFICATION_RESULT = "NFO_VERIFICATION_RESULT", // 4937-У. ФЭС о результатах проверки
  NFO_OBSTRUCTION_FACTS = "NFO_OBSTRUCTION_FACTS", // 4937_05 ФЭС о фактах воспрепятствования
  NFO_FREEZING_FUNDS = "NFO_FREEZING_FUNDS", // ФЭС о принятых мерах по замораживанию (блокированию)
  NFO_REFUSE_OPERATION = "NFO_REFUSE_OPERATION", // ФЭС о принятых мерах по замораживанию (блокированию)
  NFO_SUSPICIOUS_ACTIVITY = "NFO_SUSPICIOUS_ACTIVITY", // 4937-У. ФЭС о подозрительной деятельности
  OES_OTKAZ_GOZ = "OES_OTKAZ_GOZ", // 5392-У. ОЭС об отказе ГОЗ
  SD_KO_OTKR_ZAKR_CHET_DEPO = "SD_KO_OTKR_ZAKR_CHET_DEPO",  // 3732. ЭС об открытии(закрытии) счета депо
  SD_KO_SECURITIES = "SD_KO_SECURITIES",  //  3732. ЭС о приобретении(отчуждении) ценных бумаг
  SD_KO_IZM_CHET_DEPO = "SD_KO_IZM_CHET_DEPO",  // 3732. ЭС об изменении реквизитов счета депо
  SD_KO_OTKR_ZAKR_AKKR = "SD_KO_OTKR_ZAKR_AKKR",  // 3732. ЭС об открытии(закрытии) покрытого (депонированного) аккредитива
  SD_KO_IZM_AKKRED = 'SD_KO_IZM_AKKRED', // 3732. ЭС об изменении реквизитов аккредитивов
  SD_KO_IZM_CHET = 'SD_KO_IZM_CHET', // 2446-Y. ЭС об изменении реквизитов счета
  SD_KO_IZM_CHET_GOZ = 'SD_KO_IZM_CHET_GOZ', // ЭС об изменении реквизитов отдельного счета ГОЗ
  SD_KO_OTKR_ZAKR_CHET = 'SD_KO_OTKR_ZAKR_CHET', // 2446-У. Сведение банка об открытии (закрытии) счета
  SD_KO_OTKR_ZAKR_CHET_GOZ = 'SD_KO_OTKR_ZAKR_CHET_GOZ', // ЭС об открытии, закрытии отдельного счета ГОЗ
  SD_KONFO_ECONOMIC_MEASURE = 'SD_KONFO_ECONOMIC_MEASURE', // 6670-У ЭС об экономических мерах
}

export enum CheckDocumentType {
  P407_REQUESTS = "p407",
  P407_ANSWER = "p407",
  STRATEG = 'strateg',
  SD_KO_OTKR_ZAKR_CHET_DEPO = "strateg",
  SD_KO_SECURITIES = "strateg",
  SD_KO_IZM_CHET_DEPO = "strateg",
  SD_KO_OTKR_ZAKR_AKKR = "strateg",
  SD_KO_IZM_AKKRED = 'strateg',
  SD_KO_IZM_CHET = 'strateg',
  SD_KO_IZM_CHET_GOZ = 'strateg',
  SD_KO_OTKR_ZAKR_CHET = 'strateg',
  SD_KO_OTKR_ZAKR_CHET_GOZ = 'strateg',
  OES_OTKAZ_GOZ = 'otkazgoz',
  SD_KONFO_ECONOMIC_MEASURE = 'spo281fz',
}

export const getCheckConfig = (type: CheckDocumentType): string => {
  return CheckDocumentType[type] || ''
}
