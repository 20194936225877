import {Component, forwardRef, Input} from '@angular/core';
import {QrefOkcmModel} from "../../../models";
import {DictionariesService} from "../../../service";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {ViewDictionaryBase} from "../view-dictionary-base";
import {PageOkcm} from "../../../../../../api/build";
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-dict-qref-okcm',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QrefOkcmViewComponent),
    multi: true
  }],
  template: `
    <div [class]="ngClassProps">
      <label class="form-label">{{title}}
        <app-rq *ngIf="isFieldReq(formControl)" [isReadOnly]="isDisabled"></app-rq>
      </label>
      <app-custom-select *ngIf="dictionary | async as dict; else loading"
                         [code]="isNeedOnlyNcm ? 'ncm' : 'kokcm3'"
                         name="ncm"
                         [items]="dict"
                         [returnOnlyId]="true"
                         [formControl]="formControl"
                         [fieldsFromSearch]="isNeedOnlyNcm ? ['ncm'] : ['ncm','kokcm3']"
                         [isInvalid]="submitted && formControl.errors">
        <ng-template let-item #labelTemplate>
          <ng-container *ngIf="!isNeedOnlyNcm">
            <b> {{ item.kokcm3 }} </b>
          </ng-container>
          {{ item.ncm }}
        </ng-template>
        <ng-template let-item let-search="search" #optionTemplate>
          <div style="white-space: normal">
            <ng-container *ngIf="!isNeedOnlyNcm">
              <b> {{ item.kokcm3 }} </b>
            </ng-container>
            {{ item.ncm }}
          </div>
        </ng-template>
      </app-custom-select>
      <ng-template #loading>
        <app-skeleton></app-skeleton>
      </ng-template>
      <app-valid [logic]="formControl" [submitted]="submitted"></app-valid>
    </div>
  `
})
export class QrefOkcmViewComponent extends ViewDictionaryBase<PageOkcm> {
  @Input() ngClassProps = 'mb-3 col-6 pl-0 pr-0';
  @Input() needStateless;
  @Input() isNeedOnlyNcm = false;
  statelessObject: QrefOkcmModel = {
    id: 0,
    kokcm: "00000",
    alfa2: "",
    alfa3: "",
    ncmShort: "",
    ncm: "Без гражданства",
    kokcm3: "000",
  };
  getDictionary = this.dictSrv.getQrefOkcmList().pipe(
    map((data) => {
        if (this.needStateless) {
            data.content = [...data.content, this.statelessObject];
            return data;
        }
        return data;
    })
  );

  constructor(private dictSrv: DictionariesService) {
    super();
  }
}
