import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {ITableItem, Utils} from 'src/app/core';
import {DocumentBaseService, DocumentComponentBaseSub} from "../../../../../../../documentForm";

@Component({
  selector: 'app-armfm-v-sved-freeze-v1',
  templateUrl: './view-sved-freeze.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewSvedFreeze extends DocumentComponentBaseSub implements ITableItem, OnInit {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Сведения о случаях блокировки (замораживания)`;
  isReadOnly: boolean;
  submitted: boolean;
  //для директивы смены валидации
  validators = [Validators.required, Validators.pattern(Utils.floatPattern)];
  secondValidators = [Validators.pattern(Utils.floatPattern)];
  icons = {
    info: ['fas', 'info-circle']
  };

  constructor(private readonly docSrv: DocumentBaseService) {
        super();
  }

  ngOnInit(): void {
  }

  currentGetForm = (param) => this.docSrv.getMeryData(param);

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  get kodVal() {
    return this.formGroup('vidSredstv').value;
  }

  genIdentify(data) {
    this.field('nomerZapisi').setValue(data);
    this.field('nomerZapisi').markAsDirty();
  }

  get versiiaFormataFiledValue() {
    return this.docSrv.versiiaFormataValue;
  }
}
