import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IconsService} from "@amlCore/services";
import {FmService} from "../../../../../service";
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {DocumentBaseService, DocumentConfigService, DocumentTypeEnum, getDocumentConfig} from "../../../../../../documentForm";


@Component({
  selector: 'app-armfm-service-information-v1',
  templateUrl: './service-information-v1.html',
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class ServiceInformationV1 implements OnInit, OnDestroy {

  @Input() data: any = {};
  @Input() docType: DocumentTypeEnum = null;
  @Input() id: string = null;
  @Input() receiptUrl: string = '/api/v1/documents/sended/receipt/download/';
  @Input() receiptSignUrl: string = '/api/v1/documents/sended/receipt/download/';


  configuration: DocumentConfigService = null;
  listSign = [];
  linkDocFileUrl = ''; // урл для получения xml документа
  linkSignFileUrl = ''; // урл для получения подписи документа
  linkDocContainerUrl = ''; // урл для получения контейнера документа
  linkDocReceiptUrl = ''; // урл для получения квитанции документа
  linkDocReceiptSignUrl = ''; // урл для получения подписи квитанции документа
  nameSign = ''; // урл для получения xml документа
  showAttach = true;
  showSign = true;
  showTicket = true;
  showAttributes = true;
  sRouteParams: Subscription;
  constructor(private _iconsSrv: IconsService,
              private fmSrv: FmService,
              private activateRoute: ActivatedRoute,
              public docSrv: DocumentBaseService) {
  }

  v = this.docSrv.v;
  svgIcons = this._iconsSrv.getSvgIcons();

  ngOnInit(): void {
    this.configuration = getDocumentConfig(this.docType)
    this.linkDocFileUrl = this.configuration.api + 'downloadDoc/' + this.id;
    if (this.data.container) {
      this.linkDocContainerUrl = this.docType === 'OES_OTKAZ_GOZ'
      ? '/api/v1/documents/oes/otkaz/goz/container/download/' + this.data.container.id
      : '/api/v1/documents/container/download/' + this.data.container.id;
    }
    if (this.data.signId) {
      this.linkSignFileUrl = this.configuration.api + 'download/' + this.data.signId;
      this.nameSign = this.data.documentName + '.sign'
    }

    if(this.data.receipt){
      this.linkDocReceiptUrl = this.receiptUrl + this.id;
    }

    if(this.data.receiptSign){
      this.linkDocReceiptSignUrl = this.receiptSignUrl + this.id;
    }
  }

  ngOnDestroy(): void {
    if(this.sRouteParams) {
      this.sRouteParams.unsubscribe();
    }
  }

  download() {
    setTimeout(() => {
      this.sRouteParams = this.activateRoute.queryParams.subscribe(params => {
        if (params.page === 'sendssended') {
          this.fmSrv.getDocInfoById(this.id, this.docType).subscribe(data => {
            this.data.downloadDate = data.downloadDate
          })
        }
      });
    }, 2000);
  }
}
