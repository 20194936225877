import {Component, OnInit, Input} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewSvedRefuseV1} from "../view";
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";
import { ITable } from "src/app/core/components/tableComp/ITable";
import { DocumentFmV1Service } from "../../../../../../service/document-fm-v1.service";

@Component({
    selector: "app-armfm-table-sved-refuse-v1",
    templateUrl: "./table-sved-refuse.html",
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableSvedRefuseV1 extends DocumentSubForm implements OnInit, ITable {
    @Input() documentType = DocumentTypeEnum.REFUSE_CONTRACT;
    viewForm = ViewSvedRefuseV1;
    pageSize = 5;
    page = 0;

    constructor(
        protected readonly modalService: NgbModal,
        private readonly docSrv: DocumentBaseService,
        private readonly docSrvV1: DocumentFmV1Service
    ) {
        super(modalService);
    }

    currentGetFormGroup = (data) => this.docSrvV1.getSvedOtkazV1.apply(this.docSrv, [data, null, this.documentType]);

    ngOnInit() {
        super.ngOnInit();
    }

    onPageChange(e) { }
}
