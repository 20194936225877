import {ChangeDetectorRef, Component, DoCheck, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {QrefOkatoByOblViewComponent} from "../../../../../dictionaries/components/views/common";
import {AccordionFormGroupComponent} from "../accordion-form-group.component";

@Component({
  selector: 'app-place-birth-operations',
  templateUrl: './place-birth-operations.component.html',
  styleUrls: ['../../../base/documentForm.component.scss']
})
export class PlaceBirthOperationsComponent extends DocumentComponentBaseSub implements OnInit, OnDestroy {
  @ViewChild("okatoSelect") okatoSelect: QrefOkatoByOblViewComponent;
  @Input() formGroupApp: FormGroup;
  @Input() documentData: any;
  @Input() title: string;
  @Input() isRequiredBlock: boolean;
  @Input() needStateless = false;

  @ViewChild('accordionFormGroupComponent', { static: true, read: AccordionFormGroupComponent })
  accordionFormGroupComponent: AccordionFormGroupComponent;

  constructor(readonly docSrv: DocumentBaseService) {
    super();
  }

  ngOnInit(): void {
  }
}
