import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesService {
  url = '/api/v1/documents';
  constructor(private api: ApiService) { }

  downloadFiles(params: any, groupType): Observable<any> {
    return this.api.post(`${this.url}/${groupType}/archive/download/start`, params);
  }

  downloadList(groupType, params: any ): Observable<any> {
    return this.api.post(`${this.url}/${groupType}/archive/download/list`, params);
  }

  removeDownloadArchive(groupType: string, id: number): Observable<any> {
    return this.api.post(`${this.url}/${groupType}/archive/download/remove/${id}`);
  }

  downloadItem(groupType: string, id: number): Observable<any> {
    return this.api.byteGet(`${this.url}/${groupType}/archive/download/file/${id}`);
  }

  rerunItem(groupType: string, id: number): Observable<any> {
    return this.api.get(`${this.url}/${groupType}/archive/download/rerun/${id}`);
  }
}
