import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {ViewLegalRequirementsObstructionFactComponent} from "./view-legal-requirements-obstruction-fact.component";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../enums";
import {FormArray, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-arm-doc-table-legal-req-obstruction-fact',
  templateUrl: './table-legal-requirements-obstruction-fact.component.html',
  styleUrls: ['../../../base/documentForm.component.scss'],
})
export class TableLegalRequirementsObstructionFactComponent extends DocumentSubForm  implements OnInit, ITable {
  documentType = DocumentTypeEnum.OBSTRUCTION_FACTS; // todo
  viewForm = ViewLegalRequirementsObstructionFactComponent;
  pageSize = 10;
  page = 0;

  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentBaseService) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getZakonodatelstvoRf.call(this.docSrv, data);

  ngOnInit() {
    super.ngOnInit();
    const documentData = {zakonodatelstvoRF: this.documentData};
    const formFaktVosprepiat = this.docSrv.getFaktVosprepiat(documentData);
    const zakonodatelstvoRF = formFaktVosprepiat.get('zakonodatelstvoRF') as FormArray;
    this.controlFormGroupApp.setValue(this.documentData);
    const parent = this.formGroupApp.parent as FormGroup;
    parent.removeControl('zakonodatelstvoRF');
    parent.addControl('zakonodatelstvoRF', zakonodatelstvoRF);
  }
}
