import {Component, OnInit} from '@angular/core';
import {DocumentBase} from "@amlDocument/base";
import {CommonService, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {FmService} from "../../../../../service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {DocumentFmV1Service} from "../../../../../service/document-fm-v1.service";

@Component({
  selector: 'app-operation-details4936-u',
  templateUrl: './operation-details4936-u.component.html',
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class OperationDetails4936UComponent extends DocumentBase implements OnInit {
  documentType = DocumentTypeEnum.OPERATIONS_1_0;
  clientType: string;
  form: FormGroup;
  documentTable;

  indexSved: number;
  indexOsnov: number;
  indexMember: number;

  selectEIODopClientForm: FormArray;
  selectNovSvedEIODopClientForm: FormArray;
  selectBenefitsiarDopClientForm: FormArray;
  selectSvedDopClientForm: FormGroup;

  constructor(
      protected documentBaseService: DocumentBaseService,
      protected iconsService: IconsService,
      protected commonSrv: CommonService,
      protected fmSrv: FmService,
      protected activatedRoute: ActivatedRoute,
      protected router: Router,
      protected documentFmService: DocumentFmV1Service
  ) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router, documentFmService);
  }

  ngOnInit(): void {
    this.versiiaFormataValue.pipe(takeUntil(this.destroy$)).subscribe(data => this.defineVersionOperations(data));
    super.ngOnInit();
  }

  initForm(): void {
    this.fmSrv.getDocumentTable(this.documentId, this.documentType)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.initTableData(data);
          this.disableForm();
        });
  }

  initTableData(data){
    this.documentTable = data;
    const operationDetails = this.documentFmService.getOperationDetailsV1Form(data, null, this.documentType);
    this.fGroup('informChast').addControl('svedKO', operationDetails);
  }

  get getFormSvedKO(): FormArray {
    return this.fArray('informChast.svedKO');
  }

  get getFormSvedOper(): FormArray {
    return this.getFormSvedKO?.get([this.indexSved])?.get('operatsiia') as FormArray;
  }

  get getFormOsnov(): FormArray {
    return this.getFormSvedOper?.get([this.indexOsnov])?.get('osnovanieOp') as FormArray;
  }

  get getFormMembers(): FormArray {
    return this.getFormSvedOper?.get([this.indexOsnov])?.get('uchastnikOp') as FormArray;
  }

  onSelectedSvedRow(index) {
    this.clearDopSved();
    this.indexSved = index;
  }

  onSelectedOsnovRow(index) {
    this.clearDopSved();
    this.indexOsnov = index;
  }

  onSelectedDopClientInfoRow(index?: number) {
    this.clearDopSved();
    const svedKO = this.form.get(`informChast.svedKO`).value[this.indexSved] ?? this.documentTable[this.indexSved];
    const currentMember = svedKO?.operatsiia[this.indexOsnov]?.uchastnikOp[index];
    if (!currentMember) return;
    this.indexMember = index;
    if (!this.clientType) {
      this.getFormMembers.valueChanges
          .pipe(takeUntil(this.destroy$))
          .subscribe(item => {
            const currentClientType = item[this.indexMember]?.tipUchastnika;
            if (currentClientType && currentClientType !== this.clientType)
              this.clientType = currentClientType;
          });
    }
    this.clientType = currentMember?.tipUchastnika;
    this.initDopClientFormAtChangeClientType(index);
  }

  private initDopClientFormAtChangeClientType(selectedIndexMembers: number): void {
    if (!this.clientType) return console.log('тип участника не задан!');
    const getFormDopInfo = (groupName): FormArray => this.getFormMembers?.at(selectedIndexMembers)?.get(groupName) as FormArray;
    const getFormDopInfoSved = (groupName): FormGroup => this.getFormMembers?.at(selectedIndexMembers)?.get(groupName) as FormGroup;
    let novSved = '';
    if (this.clientType === '1') {
      this.selectEIODopClientForm = getFormDopInfo('uchastnikIuL.svedEIO');
      this.selectNovSvedEIODopClientForm = getFormDopInfo('uchastnikIuL.novSvedEIO');
      novSved = 'uchastnikIuL.novSvedIuL';
    }
    if (this.clientType === '2' || this.clientType === '3' || this.clientType === '4') {
      novSved = 'uchastnikFLIP.novSvedFLIP';
    }
    if (this.clientType === '5') {
      novSved = 'uchastnikINBOIuL.novSvedINBOIuL';
    }

    this.selectSvedDopClientForm = getFormDopInfoSved(novSved);
  }

  private clearDopSved() {
    this.selectEIODopClientForm = null;
    this.selectBenefitsiarDopClientForm = null;
  }
  get versiiaFormata() {
    return this.f('sluzhChast.versiiaFormata');
  }
  defineVersionOperations(versiia: string) {
    switch (versiia) {
      case '1.0':
        this.documentType = DocumentTypeEnum.OPERATIONS_1_0;
        break;
      case '1.1':
        this.documentType = DocumentTypeEnum.OPERATIONS_1_1;
        break;
      case '1.2':
        this.documentType = DocumentTypeEnum.OPERATIONS_1_2;
        break;
      default:
        this.documentType = DocumentTypeEnum.OPERATIONS_1_0;
    }
  }

  get isOperations10() {
    return this.documentType === DocumentTypeEnum.OPERATIONS_1_0
  }

  toggleFields1_1() {
    const svedKo = this.svedKo;
    svedKo?.controls?.forEach((svedKoControl: FormGroup) => {
      const operatsiia = svedKoControl.get('operatsiia') as FormArray;
      operatsiia?.controls?.forEach((operatsiiaControl: FormGroup) => {
        if (this.documentType === DocumentTypeEnum.OPERATIONS_1_2) {
          operatsiiaControl?.get('svedeniiaPerevodyDS')?.get('kodTerInGos')?.enable();
        }
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaKartaInBank', 'svedMestoOperatsiia', 'adrMestaPriemaVydacha', 'stranaNaimenovanie']);
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaPerevodyDS', 'svedPriemaNalDS', 'adrMestaPriemaVydacha', 'stranaNaimenovanie']);
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaPerevodyDS', 'svedVydachaNalDS', 'adrMestaPriemaVydacha', 'stranaNaimenovanie']);
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaVneseniePoluchenieNalDS', 'svedPriemVydacha', 'adrMestaPriemaVydacha', 'stranaNaimenovanie']);
        const uchastnikOp = operatsiiaControl.get('uchastnikOp') as FormArray;
        uchastnikOp?.controls?.forEach((uchastnikOpControl: FormGroup) => {
          const uchastnikIuL = uchastnikOpControl?.get('uchastnikIuL');
          uchastnikIuL?.get('novSvedEIO')?.disable();
          uchastnikIuL?.get('novSvedIuL')?.disable();
          const svedIuL = uchastnikIuL?.get('svedIuL') as FormGroup;
          this.disableAddressFields(svedIuL, ['adrIuL', 'stranaNaimenovanie']);
          this.disableAddressFields(svedIuL, ['adrRegIuL', 'stranaNaimenovanie']);
          svedIuL?.get('dataAkkreditatsii')?.disable();

          const uchastnikFLIP = uchastnikOpControl?.get('uchastnikFLIP');
          uchastnikFLIP?.get('novSvedFLIP')?.disable();
          const svedFLIP = uchastnikFLIP?.get('svedFLIP') as FormGroup;
          this.disableAddressFields(svedFLIP, ['adrReg', 'stranaNaimenovanie']);
          this.disableAddressFields(svedFLIP, ['adrRegIP', 'stranaNaimenovanie']);
          const svedDokPreb = svedFLIP?.get('svedDokPreb');
          const svedDokUdLichn = svedFLIP?.get('svedDokUdLichn');
          svedDokPreb?.get('vidDokNaimenovanie')?.disable();
          svedDokUdLichn?.get('vidDokNaimenovanie')?.disable();
          svedDokUdLichn?.get('nachPravPreb')?.disable();
          svedDokUdLichn?.get('okonchPravPreb')?.disable();

          const uchastnikINBOIuL = uchastnikOpControl?.get('uchastnikINBOIuL');
          uchastnikINBOIuL?.get('novSvedINBOIuL')?.disable();
          const svedINBOIuL = uchastnikINBOIuL?.get('svedINBOIuL') as FormGroup;
          svedINBOIuL?.get('naimINBOIuL')?.disable();
          this.disableAddressFields(svedINBOIuL, ['mestoDeiatelnostINBOIuL', 'stranaNaimenovanie']);
          const uchreditel = svedINBOIuL?.get('uchreditel') as FormArray;
          uchreditel?.controls?.forEach((uchreditelControl: FormGroup) => {
            this.disableAddressFields(uchreditelControl, ['adrUchreditel', 'stranaNaimenovanie']);
          });
        });
      });
    });
  }

  toggleFields1_0() {
    const svedKo = this.svedKo;
    svedKo?.controls?.forEach((svedKoControl: FormGroup) => {
      const operatsiia = svedKoControl.get('operatsiia') as FormArray;
      operatsiia?.controls?.forEach((operatsiiaControl: FormGroup) => {
        operatsiiaControl?.get('svedeniiaPerevodyDS')?.get('kodTerInGos')?.disable();
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaKartaInBank', 'svedMestoOperatsiia', 'adrMestaPriemaVydacha', 'adresStroka']);
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaPerevodyDS', 'svedPriemaNalDS', 'adrMestaPriemaVydacha', 'adresStroka']);
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaPerevodyDS', 'svedVydachaNalDS', 'adrMestaPriemaVydacha', 'adresStroka']);
        this.disableAdrMestaPriemaVydacha(operatsiiaControl,
            ['svedeniiaVneseniePoluchenieNalDS', 'svedPriemVydacha', 'adrMestaPriemaVydacha', 'adresStroka']);
        const uchastnikOp = operatsiiaControl.get('uchastnikOp') as FormArray;
        uchastnikOp?.controls?.forEach((uchastnikOpControl: FormGroup) => {
          const uchastnikIuL = uchastnikOpControl?.get('uchastnikIuL');
          uchastnikIuL?.get('novSvedEIO')?.enable();
          uchastnikIuL?.get('novSvedIuL')?.enable();
          const svedIuL = uchastnikIuL?.get('svedIuL') as FormGroup;
          this.disableAddressFields(svedIuL, ['adrIuL', 'adresStroka']);
          this.disableAddressFields(svedIuL, ['adrRegIuL', 'adresStroka']);
          this.enableAddressFields(svedIuL, ['adrIuL', 'stranaNaimenovanie']);
          this.enableAddressFields(svedIuL, ['adrRegIuL', 'stranaNaimenovanie']);
          svedIuL?.get('dataAkkreditatsii')?.enable();

          const uchastnikFLIP = uchastnikOpControl?.get('uchastnikFLIP');
          uchastnikFLIP?.get('novSvedFLIP')?.enable();
          const svedFLIP = uchastnikFLIP?.get('svedFLIP') as FormGroup;
          this.disableAddressFields(svedFLIP, ['adrReg', 'adresStroka']);
          this.disableAddressFields(svedFLIP, ['adrRegIP', 'adresStroka']);
          this.enableAddressFields(svedFLIP, ['adrReg', 'stranaNaimenovanie']);
          this.enableAddressFields(svedFLIP, ['adrRegIP', 'stranaNaimenovanie']);
          const svedDokPreb = svedFLIP?.get('svedDokPreb');
          const svedDokUdLichn = svedFLIP?.get('svedDokUdLichn');
          svedDokPreb?.get('vidDokNaimenovanie')?.enable();
          svedDokUdLichn?.get('vidDokNaimenovanie')?.enable();
          svedDokUdLichn?.get('nachPravPreb')?.enable();
          svedDokUdLichn?.get('okonchPravPreb')?.enable();

          const uchastnikINBOIuL = uchastnikOpControl?.get('uchastnikINBOIuL');
          uchastnikINBOIuL?.get('novSvedINBOIuL')?.enable();
          const svedINBOIuL = uchastnikINBOIuL?.get('svedINBOIuL') as FormGroup;
          svedINBOIuL?.get('naimINBOIuL')?.enable();
          this.disableAddressFields(svedINBOIuL, ['mestoDeiatelnostINBOIuL', 'adresStroka']);
          this.enableAddressFields(svedINBOIuL, ['mestoDeiatelnostINBOIuL', 'stranaNaimenovanie']);
          const uchreditel = svedINBOIuL?.get('uchreditel') as FormArray;
          uchreditel?.controls?.forEach((uchreditelControl: FormGroup) => {
            this.disableAddressFields(uchreditelControl, ['adrUchreditel', 'adresStroka']);
            this.enableAddressFields(uchreditelControl, ['adrUchreditel', 'stranaNaimenovanie']);
          });
        });
      });
    });
  }

  get svedKo() {
    return this.form?.get('informChast')?.get('svedKO') as FormArray;
  }

  disableAdrMestaPriemaVydacha(operatsiiaControl: FormGroup, path: string[]) {
    const [sved, svedMesto, adrMesta, stranaNaimenovanie] = path;
    operatsiiaControl?.get(sved)?.get(svedMesto)?.get(adrMesta)?.get(stranaNaimenovanie)?.disable();
  }

  disableAddressFields(parentControl: FormGroup,  path: string[]) {
    const [adress, filed] = path;
    parentControl?.get(adress)?.get(filed)?.disable();
  }

  enableAddressFields(parentControl: FormGroup,  path: string[]) {
    const [adress, filed] = path;
    parentControl?.get(adress)?.get(filed)?.enable();
  }
}
