import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MchdService {

  constructor(private http: HttpClient) { }

  downloadMCHD(certThumb: string) {
    return this.http.get(`/api/v1/authad/mchd/${certThumb}`, {
      observe: 'response',
      responseType: 'blob',
    }).pipe(
      map((response) => this.handleFileDownload(response))
    );;
  }

  deleteMCHD(certThumb: string) {
    return this.http.delete(`/api/v1/authad/mchd/${certThumb}`);
  }

  handleFileDownload(response) {
    let filename =  decodeURI(response.headers.get('content-disposition').split('filename="')[1].split('"')[0]);
    
    let dataURL = window.URL.createObjectURL(response.body);
    let link = document.createElement('a');
    link.download = filename;
    link.href = dataURL;
    link.click();
    return dataURL;
  }
}
