import {ChangeDetectorRef, Component, DoCheck, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {QrefOkatoByOblViewComponent} from "../../../../../dictionaries/components/views/common";
import {AccordionFormGroupComponent} from "../accordion-form-group.component";
import {filter, map, switchMap, takeUntil, tap} from "rxjs/operators";
import {DictionariesService} from "../../../../../dictionaries";
import {DocumentTypeEnum, REFUSE_CONTRACT_1_X, REFUSE_OPERATION_1_X} from "../../../enums";

@Component({
    selector: "app-arm-doc-view-address[formGroupApp][isReadOnly][submitted]",
    templateUrl: "./address.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class AddressComponent extends DocumentComponentBaseSub implements OnInit, OnDestroy, DoCheck {
    @ViewChild("okatoSelect") okatoSelect: QrefOkatoByOblViewComponent;
    @Input() formGroupApp: FormGroup;
    @Input() isAttachments = false;
    @Input() isBirthPlace = false;
    @Input() is5392 = false;
    @Input() isVersiiaFormata1x = false;
    @Input() documentData: any;
    @Input() title: string;
    @Input() isRequiredBlock: boolean;
    @Input() needStateless = false;
    @Input() hiddenFields = [];
    @Input() isNewDocument = false;
    @Input() documentType: DocumentTypeEnum;
    getDictionary = this.dictSrv.getQrefOkcmList().pipe(map(page => page.content));
    @ViewChild('accordionFormGroupComponent', { static: true, read: AccordionFormGroupComponent })
    accordionFormGroupComponent: AccordionFormGroupComponent;

    constructor(readonly docSrv: DocumentBaseService, private changeDetect: ChangeDetectorRef, private dictSrv: DictionariesService,) {
        super();
    }

  ngOnInit(): void {
    if (this.is5392) {
      this.formGroupApp.get('kodOKSM').setValidators([Validators.required]);
      this.formGroupApp.get('punkt').setValidators([Validators.required]);
    }
    if (this.isVersiiaFormata1x) {
        this.getDictionary?.pipe(
            switchMap(dictionaries => (this.field('kodOKSM') ?? this.field('kodOKSM'))?.valueChanges.pipe(
                map(dictionariesCode => dictionaries.find(dictionary => dictionary?.kokcm3 === dictionariesCode)),
                filter(currentDictionary => Boolean(currentDictionary?.ncm)),
                tap(currentDictionary => {
                    (this.field('stranaNaimenovanie') ?? this.field('stranaNaimenovanie')).patchValue(currentDictionary?.ncm.slice(0, 251))
                }),
                takeUntil(this.componentDestroyed$)
            )),
        ).subscribe();
    }
   }

    ngDoCheck(): void {
        this.changeDetect.detectChanges();
    }

  /**
     * Заполняем адрес из фиаса
     */
    fillAddress(data) {
        if (data) {
            this.formGroupApp.patchValue({
                indeks: data.postalcode,
                punkt: data.locality,
                ulitsa: data.street,
                dom: data.house,
                korp: data.building ?? '',
                of: ''
            });
            this.okatoSelect.fillByRegionCode(data.regionCode);
        }
    }

    get isNullFieldsAddress(): boolean {
      return !this.field('indeks').value &&
        !this.field('kodOKSM').value &&
        !this.field('kodSubieektaPoOKATO').value &&
        !this.field('rajon')?.value &&
        !this.field('punkt').value &&
        !this.field('ulitsa').value &&
        !this.field('dom').value &&
        !this.field('korp').value &&
        !this.field('of').value;
    }

    getCondition(fieldName: string): boolean {
        return !this.field('adresStroka')?.value ||
        (this.field('adresStroka')?.value && this.field(fieldName)?.value);
    }

    get isRefuseDocuments(): boolean {
        return [...REFUSE_OPERATION_1_X, ...REFUSE_CONTRACT_1_X].includes(this.documentType);
    }

}
