import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DictionaryService} from "@amlCore/services";
import {DocumentTypeEnum, FM_DOCS_1_X, REFUSE_CONTRACT_1_X} from "../../../enums";

@Component({
    selector: 'app-arm-doc-view-info-inboiul',
    templateUrl: './view-info-inboiul.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewInfoInboiulComponent extends DocumentComponentBaseSub implements OnInit{
    @Input() readonly formGroupApp: FormGroup;
    @Input() readonly documentData: any;
    @Input() readonly documentType: DocumentTypeEnum;
    @Input() isVersiiaFormata1x = false;

    constructor(readonly docSrv: DocumentBaseService,
                readonly dictStaticSrv: DictionaryService,
                private cdr: ChangeDetectorRef) {
        super();
    }
    
    ngOnInit(): void {
        this._naimUchreditelChangeValid()
    }

    get isRefuseContract1x() {
        return REFUSE_CONTRACT_1_X.includes(this.documentType)
    }

    /**
     * Метод для проверки названия учередителя в трасте.
     * Нужно, чтобы не мешало сохранять документ
     */
    private _naimUchreditelChangeValid() {
        this.cdr.detectChanges(); // без этого disable() работать отказывается 
        this.formArray('uchreditel').controls.forEach((control: FormGroup) => {
            const naimUchreditelValue = control.get('naimUchreditel').value;
            if (naimUchreditelValue.iurLitso !== null) {
                control.get('naimUchreditel.fizLitso').disable();
                control.get('naimUchreditel.fizLitso').updateValueAndValidity();
            } 
            else if (naimUchreditelValue.fizLitso.fam !== null && naimUchreditelValue.fizLitso.imia !== null) {
                control.get('naimUchreditel.iurLitso').disable();
                control.get('naimUchreditel.iurLitso').updateValueAndValidity();
            }
        })
    }
}
