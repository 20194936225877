import {Component, OnInit} from '@angular/core';
import {DocumentBase} from "@amlDocument/base";
import {CommonService, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {FmService} from "../../../../../service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {DocumentFmV1Service} from "../../../../../service/document-fm-v1.service";

@Component({
  selector: 'app-refuse-contract4936-u',
  templateUrl: './refuse-contract4936-u.component.html',
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class RefuseContract4936UComponent extends DocumentBase implements OnInit {
  documentType = DocumentTypeEnum.REFUSE_CONTRACT_1_1;
  clientType: string;
  form: FormGroup;
  documentTable;

  svedPredstavIndex: number;

  constructor(
      protected documentBaseService: DocumentBaseService,
      protected iconsService: IconsService,
      protected commonSrv: CommonService,
      protected fmSrv: FmService,
      protected activatedRoute: ActivatedRoute,
      protected router: Router,
      protected documentFmService: DocumentFmV1Service
  ) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router, documentFmService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initForm(): void {
    const refContract = this.documentFmService.getSvedPredstavV1Form(this.d.informChast?.svedPredstav, this.documentType);
    this.fGroup('informChast').addControl('svedPredstav', refContract);
  }

  onSelectedPredstavRow(index) {
    this.svedPredstavIndex = index;
  }

  get svedPredstavForm(): FormArray {
    return this.form.get("informChast.svedPredstav") as FormArray;
  }

  get svedOtkazForm(): FormArray {
    return this.svedPredstavForm
      ?.at(this.svedPredstavIndex)
      ?.get("svedOtkaz") as FormArray;
  }
}
